<script>
    export let settings;
    export let userdata;

    let global_menu_main_color = '0x4F46E5';
    let global_menu_main_color_hex = '#4F46E5';
    let global_menu_background_icon_color = "";
    let global_menu_icon_bg_border = "2.0 " + global_menu_main_color+ " 1.0";
    let global_menu_text_bg_color = "0xDDDCE4";
    let global_menu_text_css = "color:" + global_menu_main_color_hex + "; text-align:center;font-size:14px;"
    let global_menu_text_css_normal = "color:#3F3F46; text-align:center;font-size:14px;"
    
    let tmp = userdata;
    tmp = settings;
    let krpano;

    import { vr_mode, loaded_krpano, infopanel, infopanelData, videopanel, videopanelData } from "../store.js";
    let _vrMode, ath, atv, _infopanelData, _videopanelData;

    loaded_krpano.subscribe((value) => {
        if (
            value == true
        ) {
            krpano = document.getElementById("krpanoSWFObject");
            
        }
    });

    vr_mode.subscribe((value) => {
        _vrMode = value;
        switch (value) {
        case true:
            add_vr_menu();            
            break;
            
        case false:
            remove_vr_menu();
            remove_global_menu();
            remove_alert_exit();
            remove_global_menu_shortcuts();
            remove_global_menu_floorplan();
            remove_global_menu_attributes();
            remove_global_menu_shop();
            remove_global_menu_layers();
            remove_global_menu_model();
            remove_global_menu_info();
            remove_global_menu_settings();
            remove_settings_item_settings();
            remove_submenu_settings_items();
            remove_submenu_global_info();
            remove_submenu_shortcuts();
            removeInfoPanel();

            
            break;
        }
    });

    infopanel.subscribe((value) => {
        if (value == true) { 
            remove_global_menu();
            createInfoPanel();
            
        } else {
            removeInfoPanel();
        }
    });

    videopanel.subscribe((value) => {
        if (value == true) {
            remove_global_menu();
            createVideoPanel();
            
        } else {
            removeVideoPanel();
        }
    });

    infopanelData.subscribe((value) => {
        _infopanelData = value;
    });

    videopanelData.subscribe((value) => {
        _videopanelData = value;
        ////console.log(value);
    }); 

    /* FUNKCIE VR */

    // Infopanely
    function createInfoPanel() {
        //console.log("pridávam infopanel");

        setTimeout(() => {
                    
        if (krpano) {
            get_ath_atv();
            //console.log(_infopanelData);
            krpano.call("addhotspot(infopanel-bg);");
            krpano.set("hotspot[infopanel-bg].type", "text");
            krpano.set("hotspot[infopanel-bg].ath", ath);
            krpano.set("hotspot[infopanel-bg].atv", 0);
            krpano.set("hotspot[infopanel-bg].width", "600px");
            krpano.set("hotspot[infopanel-bg].height", "373px");
            krpano.set("hotspot[infopanel-bg].distorted", "true");
            krpano.set("hotspot[infopanel-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[infopanel-bg].alpha", "1.0");
            krpano.set("hotspot[infopanel-bg].bgroundedge", "12");
            krpano.set("hotspot[infopanel-bg].cursor", "default");
            krpano.set("hotspot[infopanel-bg].visible", "true");
            krpano.set("hotspot[infopanel-bg].enabled", false);
            krpano.set("hotspot[infopanel-bg].zorder", 9);

            ////////console.log(_infopanelData.type);

            switch (_infopanelData.type) {
                case "infopanel-v1":
                    ////////console.log("infopanel v1");
                    createInfopanelTitle();
                    createInfopanelHeadline();
                    
                    createInfopanelContent();

                    switch (_infopanelData.data.img) {
                        case "img/pozvanka.jpg":
                        case "img/sofer.jpg":
                        case "img/To_je_on_plagat.jpeg":
                            createInfopanelImage("a4");
                            break;
                    
                        default:
                            createInfopanelImage("left");
                            break;
                    }
                    break;
            
                case "infopanel-v3":
                    createInfopanelTitle();
                    createInfopanelImage("center");
                    break;
                default:
                    break;
            }

            add_infopanel_close_button();
            //krpano.call("hotspot[menu].onclick()");
              
            
        }            
        }, 50);


    }

    function createInfopanelTitle() {
        krpano.call("addhotspot(infopanel-title);");
        krpano.set("hotspot[infopanel-title].type", "text");
        krpano.set("hotspot[infopanel-title].ath", ath);
        krpano.set("hotspot[infopanel-title].atv", "0");
        krpano.set("hotspot[infopanel-title].html", _infopanelData.title);
        krpano.set("hotspot[infopanel-title].ox", "0px");
        krpano.set("hotspot[infopanel-title].oy", "-168px");
        krpano.set("hotspot[infopanel-title].alpha", "1.0");
        krpano.set("hotspot[infopanel-title].visible", "true");
        krpano.set("hotspot[infopanel-title].keep", "true");
        krpano.set("hotspot[infopanel-title].zorder", "10");
        krpano.set("hotspot[infopanel-title].distorted", "true");
        krpano.set("hotspot[infopanel-title].width", "500px");
        krpano.set("hotspot[infopanel-title].height", "40px");
        krpano.set("hotspot[infopanel-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[infopanel-title].bgalpha", "0");
        krpano.set("hotspot[infopanel-title].vcenter", "true");
        krpano.set("hotspot[infopanel-title].cursor", "default");
        krpano.set("hotspot[infopanel-title].scale", "0.66");
        krpano.set("hotspot[infopanel-title].enabled", false);
        krpano.set("hotspot[infopanel-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    }

    function createInfopanelHeadline() {
        krpano.call("addhotspot(infopanel-headline);");
        krpano.set("hotspot[infopanel-headline].type", "text");
        krpano.set("hotspot[infopanel-headline].ath", ath);
        krpano.set("hotspot[infopanel-headline].atv", "0");
        krpano.set("hotspot[infopanel-headline].html", _infopanelData.data.headline);
        krpano.set("hotspot[infopanel-headline].ox", "0px");
        krpano.set("hotspot[infopanel-headline].oy", "-140px");
        krpano.set("hotspot[infopanel-headline].alpha", "1.0");
        krpano.set("hotspot[infopanel-headline].visible", "true");
        krpano.set("hotspot[infopanel-headline].keep", "true");
        krpano.set("hotspot[infopanel-headline].zorder", "10");
        krpano.set("hotspot[infopanel-headline].distorted", "true");
        krpano.set("hotspot[infopanel-headline].width", "750px");
        
        krpano.set("hotspot[infopanel-headline].height", "60px");
        krpano.set("hotspot[infopanel-headline].css", "color:#FAFAFA; text-align:left;font-size:12px;");
        krpano.set("hotspot[infopanel-headline].bgalpha", "0");
        krpano.set("hotspot[infopanel-headline].vcenter", "true");
        krpano.set("hotspot[infopanel-headline].cursor", "default");
        krpano.set("hotspot[infopanel-headline].scale", "0.66");
        krpano.set("hotspot[infopanel-headline].title", false);
        krpano.set("hotspot[infopanel-headline].css", "color:#3F3F46; text-align:center;font-size:16px;color:#3f3f46;font-weight:500;");
    }

    function createInfopanelImage($type) {
        krpano.call("addhotspot(infopanel-image);");
        krpano.set("hotspot[infopanel-image].type", "image");
        krpano.set("hotspot[infopanel-image].ath", ath);
        krpano.set("hotspot[infopanel-image].atv", "0");
        
        krpano.set("hotspot[infopanel-image].height", "prop");
        krpano.set("hotspot[infopanel-image].zorder", 10);
        krpano.set("hotspot[infopanel-image].distorted", true);
        krpano.set("hotspot[infopanel-image].enabled", false);
        krpano.set("hotspot[infopanel-image].url", "assets/" + _infopanelData.data.img);

        ////////console.log($type);
        switch ($type) {
            case "left":
                krpano.set("hotspot[infopanel-image].ox", "-280px");
                krpano.set("hotspot[infopanel-image].oy", "-100");
                krpano.set("hotspot[infopanel-image].width", "250px");
                krpano.set("hotspot[infopanel-image].edge", "lefttop");
                break;

            case "center":
                krpano.set("hotspot[infopanel-image].ox", "0px");
                krpano.set("hotspot[infopanel-image].oy", "10px");
                krpano.set("hotspot[infopanel-image].height", "320px");
                krpano.set("hotspot[infopanel-image].width", "prop");
                break;

            case "a4":
                krpano.set("hotspot[infopanel-image].ox", "-250px");
                krpano.set("hotspot[infopanel-image].oy", "-100px");
                krpano.set("hotspot[infopanel-image].height", "prop");
                krpano.set("hotspot[infopanel-image].width", "180px");
                krpano.set("hotspot[infopanel-image].edge", "lefttop");
                break;                
        
            default:
                break;
        }
    }

    function createInfopanelContent() {


        krpano.call("addhotspot(infopanel-content);");
        krpano.set("hotspot[infopanel-content].type", "text");
        krpano.set("hotspot[infopanel-content].ath", ath);
        krpano.set("hotspot[infopanel-content].atv", "0");

        if (_infopanelData.data.content.includes("V tejto knihe sa viedli záznamy o vlastníkoch")) {
            //krpano.set("hotspot[infopanel-content].css", "color:#FAFAFA; text-align:left;font-size:10px;");
            krpano.set("hotspot[infopanel-content].css", "text-align:left;font-size:12px;color:#3f3f46;font-weight:100;");
        } else {
            //krpano.set("hotspot[infopanel-content].css", "color:#FAFAFA; text-align:left;font-size:12px;");    
            krpano.set("hotspot[infopanel-content].css", "text-align:left;font-size:14px;color:#3f3f46;font-weight:100;");
        }
        krpano.set("hotspot[infopanel-content].html", _infopanelData.data.content);
        krpano.set("hotspot[infopanel-content].ox", "-10px");
        krpano.set("hotspot[infopanel-content].oy", "-100px");
        krpano.set("hotspot[infopanel-content].alpha", "1.0");
        krpano.set("hotspot[infopanel-content].visible", "true");
        krpano.set("hotspot[infopanel-content].keep", "true");
        krpano.set("hotspot[infopanel-content].zorder", "10");
        krpano.set("hotspot[infopanel-content].distorted", "true");
        krpano.set("hotspot[infopanel-content].width", "440px");
        krpano.set("hotspot[infopanel-content].height", "410px");
        
        krpano.set("hotspot[infopanel-content].bgalpha", "0");
        krpano.set("hotspot[infopanel-content].vcenter", false);
        krpano.set("hotspot[infopanel-content].enabled", false);
        krpano.set("hotspot[infopanel-content].cursor", "default");
        krpano.set("hotspot[infopanel-content].scale", "0.66");
        krpano.set("hotspot[infopanel-content].edge", "lefttop");
       // krpano.set("hotspot[infopanel-content].css", "color:#3F3F46; text-align:left;font-size:14px;color:#3f3f46;font-weight:100;");
    }

    function removeInfoPanel() {
        if (krpano) {
            ////////console.log("odstranujem infopanel");
            krpano.call("removehotspot(infopanel-bg)");
            krpano.call("removehotspot(infopanel-title)");
            krpano.call("removehotspot(infopanel_close_button)");
            krpano.call("removehotspot(infopanel-headline)");
            krpano.call("removehotspot(infopanel-image)");
            krpano.call("removehotspot(infopanel-content)");
            

            infopanel.update(n=> false);
            //infopanelData.update(n=> {});
        }
        

    }

    function add_infopanel_close_button() {
        if (krpano) {
            krpano.call("addhotspot(infopanel_close_button);");
            krpano.set("hotspot[infopanel_close_button].type", "image");
            krpano.set("hotspot[infopanel_close_button].ath", ath);
            krpano.set("hotspot[infopanel_close_button].atv", 0);
            krpano.set("hotspot[infopanel_close_button].width", "21px");
            krpano.set("hotspot[infopanel_close_button].height", "21px");
            krpano.set("hotspot[infopanel_close_button].url", "img/close.svg");
            krpano.set("hotspot[infopanel_close_button].ox", "281px");
            krpano.set("hotspot[infopanel_close_button].oy", "-168px");
            krpano.set("hotspot[infopanel_close_button].alpha", "1.0");
            krpano.set("hotspot[infopanel_close_button].visible", "true");
            krpano.set("hotspot[infopanel_close_button].keep", "true");
            krpano.set("hotspot[infopanel_close_button].zorder", 10);
            krpano.set("hotspot[infopanel_close_button].distorted", "true");

            var hs = krpano.get("hotspot[infopanel_close_button]");
			hs.onclick = function() {
                removeInfoPanel();
            }

            
        }
    }

    // Videopanely
    function createVideopanelTitle() {
        ////////console.log("pridávam title : " + _videopanelData.title);
        krpano.call("addhotspot(videopanel-title);");
        krpano.set("hotspot[videopanel-title].type", "text");
        krpano.set("hotspot[videopanel-title].ath", ath);
        krpano.set("hotspot[videopanel-title].atv", "0");
        krpano.set("hotspot[videopanel-title].html", _videopanelData.title);
        krpano.set("hotspot[videopanel-title].ox", "0px");
        krpano.set("hotspot[videopanel-title].oy", "-168px");
        krpano.set("hotspot[videopanel-title].alpha", "1.0");
        krpano.set("hotspot[videopanel-title].visible", "true");
        krpano.set("hotspot[videopanel-title].keep", "true");
        krpano.set("hotspot[videopanel-title].zorder", "10");
        krpano.set("hotspot[videopanel-title].distorted", "true");
        krpano.set("hotspot[videopanel-title].width", "500px");
        krpano.set("hotspot[videopanel-title].height", "40px");
        krpano.set("hotspot[videopanel-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[videopanel-title].bgalpha", "0");
        krpano.set("hotspot[videopanel-title].vcenter", "true");
        krpano.set("hotspot[videopanel-title].cursor", "default");
        krpano.set("hotspot[videopanel-title].scale", "0.66");
        krpano.set("hotspot[videopanel-title].enabled", false);
        krpano.set("hotspot[videopanel-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
    }

    function createVideoHts() {
        
        if (krpano != "undefined") {
            get_ath_atv();
            ////console.log("pridávam video do videopanelu");
            krpano.call("addhotspot(video);");
            krpano.set("hotspot[video].url", "assets/krpano/plugins/videoplayer.js");
            krpano.set("hotspot[video].videourl", "assets/" + _videopanelData.data.local_video_url);
            krpano.set("hotspot[video].ath", ath);
            krpano.set("hotspot[video].atv", "0");
            krpano.set("hotspot[video].width", "prop");
            krpano.set("hotspot[video].height", "320px");
            krpano.set("hotspot[video].pausedonstart", "false");
            krpano.set("hotspot[video].visible", true);
            krpano.set("hotspot[video].posterurl", "scenes/synagoga_12_u.jpg");
            krpano.set("hotspot[video].distorted", true);
            krpano.set("hotspot[video].scale", 1.0);
            krpano.set("hotspot[video].rx", "0.0");
            krpano.set("hotspot[video].ry", "0.0");
            krpano.set("hotspot[video].rz", "0.0");
            krpano.set("hotspot[video].ox", "0");
            krpano.set("hotspot[video].oy", "10");
            krpano.set("hotspot[video].loop", "true");
            krpano.set("hotspot[video].volume", "1.0");
            krpano.set("hotspot[video].directionalsound", true);
            krpano.set("hotspot[video].range", "90");
            krpano.set("hotspot[video].rangefalloff", "1.0");
            krpano.set("hotspot[video].outofrangevolume", "0.0");
            krpano.set("hotspot[video].zorder", 12);
        }
    }

    function createVideoPanel() {
        ////////console.log("pridávam videoPanel");

        setTimeout(() => {
                 if (krpano) {
            get_ath_atv();
            ////////console.log(_videopanelData);
            krpano.call("addhotspot(videopanel-bg);");
            krpano.set("hotspot[videopanel-bg].type", "text");
            krpano.set("hotspot[videopanel-bg].ath", ath);
            krpano.set("hotspot[videopanel-bg].atv", 0);
            krpano.set("hotspot[videopanel-bg].width", "600px");
            krpano.set("hotspot[videopanel-bg].height", "373px");
            krpano.set("hotspot[videopanel-bg].distorted", "true");
            krpano.set("hotspot[videopanel-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[videopanel-bg].alpha", "1.0");
            krpano.set("hotspot[videopanel-bg].bgroundedge", "12");
            krpano.set("hotspot[videopanel-bg].cursor", "default");
            krpano.set("hotspot[videopanel-bg].visible", "true");
            krpano.set("hotspot[videopanel-bg].enabled", false);
            krpano.set("hotspot[videopanel-bg].zorder", 9);

            ////////console.log(_videopanelData.type);

            switch (_videopanelData.type) {
                case "videopanel-v2":
                    ////////console.log("infopanel v1");
                    createVideopanelTitle();
                    createVideoHts();
                    break;
            
                default:
                    createVideoHts();
                    break;
            }

            add_videopanel_close_button();
        }
        }, 50);
        
   

    }

    function removeVideoPanel() {
        if (krpano) {
            ////////console.log("odstranujem videopanel");
            krpano.call("removehotspot(videopanel-bg)");
            krpano.call("removehotspot(videopanel-title)");
            krpano.call("removehotspot(videopanel_close_button)");
            krpano.call("removehotspot(video)");
            videopanel.update(n=> false);
            videopanelData.update(n=> {});
        }
    }

    function add_videopanel_close_button() {
        if (krpano) {
            krpano.call("addhotspot(videopanel_close_button);");
            krpano.set("hotspot[videopanel_close_button].type", "image");
            krpano.set("hotspot[videopanel_close_button].ath", ath);
            krpano.set("hotspot[videopanel_close_button].atv", 0);
            krpano.set("hotspot[videopanel_close_button].width", "21px");
            krpano.set("hotspot[videopanel_close_button].height", "21px");
            krpano.set("hotspot[videopanel_close_button].url", "img/close.svg");
            krpano.set("hotspot[videopanel_close_button].ox", "281px");
            krpano.set("hotspot[videopanel_close_button].oy", "-168px");
            krpano.set("hotspot[videopanel_close_button].alpha", "1.0");
            krpano.set("hotspot[videopanel_close_button].visible", "true");
            krpano.set("hotspot[videopanel_close_button].keep", "true");
            krpano.set("hotspot[videopanel_close_button].zorder", 10);
            krpano.set("hotspot[videopanel_close_button].distorted", "true");

            var hs = krpano.get("hotspot[videopanel_close_button]");
			hs.onclick = function() {
                removeVideoPanel();
            }

            
        }
    }

    // Zistenie aktuálnej polohy
    function get_ath_atv() {
        if (krpano) {
            ath = krpano.get("view.hlookat");
            atv = krpano.get("view.vlookat")
        }
    }

    // FUNCKIE PRE JEDNOTLIVÉ KOMPONENTY VR

    /* VR menu - footer */
    function add_vr_menu() {
        if (krpano) {
            krpano.call("addhotspot(menu);");
            krpano.set("hotspot[menu].ath", 0);
            krpano.set("hotspot[menu].atv", 50);
            krpano.set("hotspot[menu].url", "img/menu.svg");
            krpano.set("hotspot[menu].zorder", 9);
            krpano.set("hotspot[menu].keep", "true");
            krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
            krpano.set("hotspot[menu].onhover", "js(menu_icon(true););");
            krpano.set("hotspot[menu].onout", "js(menu_icon(false););");
            krpano.set("hotspot[menu].visible", true);
            krpano.set("hotspot[menu].alpha", "1.0");

            var hs = krpano.get("hotspot[menu]");
			hs.onclick = function() {
                
                removeInfoPanel();
                removeVideoPanel();
                remove_settings_item_settings();
                remove_submenu_settings_items();
                add_global_menu();

                //krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
                //krpano.call("hotspot[menu].onclick()");
              
            }

            // krpano.set("hotspot[menu].onclick", "js(toggle_global_menu();)"); // js(toggle_menu(true););"
            // krpano.call("hotspot[menu].onclick()");
            //krpano.call("hotspot[global_menu_close_button].onclick()");
        }
        
    }
    function remove_vr_menu() {
        if (krpano) {
            // krpano.set("hotspot[menu].visible", false);
            // krpano.set("hotspot[menu].alpha", "0.0");
            krpano.call("removehotspot(menu)");

        }
    }

    /* GLOBAL VR MENU */
    function add_global_menu() {
        if (krpano) {
            get_ath_atv();
            ////////console.log("zapínam VR menu");
            krpano.call("addhotspot(global_menu-bg);");
            krpano.set("hotspot[global_menu-bg].type", "text");
            krpano.set("hotspot[global_menu-bg].ath", ath);
            krpano.set("hotspot[global_menu-bg].atv", 0);
            krpano.set("hotspot[global_menu-bg].width", "600");
            krpano.set("hotspot[global_menu-bg].height", "373px");
            krpano.set("hotspot[global_menu-bg].distorted", "true");
            krpano.set("hotspot[global_menu-bg].bgcolor", "0xfafafa");
            krpano.set("hotspot[global_menu-bg].alpha", "0.6");
            krpano.set("hotspot[global_menu-bg].bgroundedge", "12");
            krpano.set("hotspot[global_menu-bg].cursor", "default");
            krpano.set("hotspot[global_menu-bg].visible", true);
            krpano.set("hotspot[global_menu-bg].keep", "true");
            krpano.set("hotspot[global_menu-bg].zorder", 9);
            krpano.set("hotspot[global_menu-bg].enabled", false);

            krpano.call("addhotspot(global_menu-title);");
            krpano.set("hotspot[global_menu-title].type", "text");
            krpano.set("hotspot[global_menu-title].ath", ath);
            krpano.set("hotspot[global_menu-title].atv", "0");
            krpano.set("hotspot[global_menu-title].html", "Nastavenie");
            krpano.set("hotspot[global_menu-title].ox", "0px");
            krpano.set("hotspot[global_menu-title].oy", "-168px");
            krpano.set("hotspot[global_menu-title].alpha", "1.0");
            krpano.set("hotspot[global_menu-title].visible", true);
            krpano.set("hotspot[global_menu-title].keep", "true");
            krpano.set("hotspot[global_menu-title].zorder", "10");
            krpano.set("hotspot[global_menu-title].distorted", "true");
            krpano.set("hotspot[global_menu-title].width", "500px");
            krpano.set("hotspot[global_menu-title].height", "40px");
            krpano.set("hotspot[global_menu-title].css", "color:#FAFAFA; text-align:left;font-size:16px;");
            krpano.set("hotspot[global_menu-title].bgalpha", "0");
            krpano.set("hotspot[global_menu-title].vcenter", "true");
            krpano.set("hotspot[global_menu-title].cursor", "default");
            krpano.set("hotspot[global_menu-title].scale", "0.66");
            krpano.set("hotspot[global_menu-title].css", "color:#3F3F46; text-align:center;font-size:20px;color:#3f3f46;font-weight:900;");
            krpano.set("hotspot[global_menu-title].enabled", false);

            add_global_menu_close_button();
            add_global_menu_exit_vr_button();
            add_global_menu_floorplan();
            add_global_menu_shop();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_info();
            add_global_menu_settings();
            add_global_menu_attributes();
            add_global_menu_shortcuts();
        }

       
        //add_alert_exit();
    }
    function remove_global_menu() {
        ////console.log("odoberám VR menu");
        if (krpano) {

            let elements = ['global_menu-bg', 'global_menu-title', 'exit-vr-button', 'exit-vr-text', 'global_menu_close_button']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }

            remove_global_menu_all_items();
            remove_settings_item_settings();
            remove_submenu_settings_items();
            //remove_submenu_global_info();
            //remove_submenu_shortcuts();
        }
    }

    /* GLOBAL VR MENU ITEMS */
    function add_global_menu_close_button() {
        if (krpano) {
            krpano.call("addhotspot(global_menu_close_button);");
            krpano.set("hotspot[global_menu_close_button].type", "image");
            krpano.set("hotspot[global_menu_close_button].ath", ath);
            krpano.set("hotspot[global_menu_close_button].atv", 0);
            krpano.set("hotspot[global_menu_close_button].width", "21px");
            krpano.set("hotspot[global_menu_close_button].height", "21px");
            krpano.set("hotspot[global_menu_close_button].url", "img/close.svg");
            krpano.set("hotspot[global_menu_close_button].ox", "281px");
            krpano.set("hotspot[global_menu_close_button].oy", "-168px");
            krpano.set("hotspot[global_menu_close_button].alpha", "1.0");
            krpano.set("hotspot[global_menu_close_button].visible", true);
            krpano.set("hotspot[global_menu_close_button].keep", "true");
            //krpano.set("hotspot[global_menu_close_button].onclick", "js(toggle_global_menu(););");
            krpano.set("hotspot[global_menu_close_button].zorder", 10);
            krpano.set("hotspot[global_menu_close_button].distorted", "true");

            var hs = krpano.get("hotspot[global_menu_close_button]");
			hs.onclick = function() {
                remove_global_menu();
            }
        }
    }

    /* GLOBAL MENU EXIT VR BUTTON */
    function add_global_menu_exit_vr_button() {
        // Exit VR button - image
        krpano.call("addhotspot(exit-vr-button);");
        krpano.set("hotspot[exit-vr-button].type", "image");
        krpano.set("hotspot[exit-vr-button].ath", ath);
        krpano.set("hotspot[exit-vr-button].atv", 0);
        krpano.set("hotspot[exit-vr-button].url", "img/exit-vr.svg");
        krpano.set("hotspot[exit-vr-button].ox", "-255px");
        krpano.set("hotspot[exit-vr-button].oy", "-168px");
        krpano.set("hotspot[exit-vr-button].alpha", "1.0");
        krpano.set("hotspot[exit-vr-button].visible", "true");
        krpano.set("hotspot[exit-vr-button].keep", "true");
        //krpano.set("hotspot[exit-vr-button].onclick", "js(leave_vr(););");
        krpano.set("hotspot[exit-vr-button].zorder", 10);
        krpano.set("hotspot[exit-vr-button].scale", 0.66);
        krpano.set("hotspot[exit-vr-button].distorted", "true");

        let hs_exit = krpano.get("hotspot[exit-vr-button]");
        hs_exit.onclick = function() {
            ////////console.log("v1");
            //krpano.call("webvr.exitVR()");
            add_alert_exit();
        }

        // Exit VR button - text
        krpano.call("addhotspot(exit-vr-text);");
        krpano.set("hotspot[exit-vr-text].type", "text");
        krpano.set("hotspot[exit-vr-text].ath", ath);
        krpano.set("hotspot[exit-vr-text].atv", 0);
        krpano.set("hotspot[exit-vr-text].html", "Zavrieť");
        krpano.set("hotspot[exit-vr-text].width", "70px");
        krpano.set("hotspot[exit-vr-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[exit-vr-text].ox", "-241px");
        krpano.set("hotspot[exit-vr-text].oy", "-175px");
        krpano.set("hotspot[exit-vr-text].alpha", "1.0");
        krpano.set("hotspot[exit-vr-text].bgalpha", "0.0");
        krpano.set("hotspot[exit-vr-text].edge", "top");
        krpano.set("hotspot[exit-vr-text].visible", "true");
        krpano.set("hotspot[exit-vr-text].keep", "true");
        //krpano.set("hotspot[exit-vr-text].onclick", "js(leave_vr(););");
        krpano.set("hotspot[exit-vr-text].zorder", 10);
        krpano.set("hotspot[exit-vr-text].scale", "0.66");
        krpano.set("hotspot[exit-vr-text].distorted", "true");

        let hs_exit_text = krpano.get("hotspot[exit-vr-text]");
        hs_exit_text.onclick = function() {
            ////////console.log("v2");
            //krpano.call("webvr.exitVR()");
            add_alert_exit();
        }
    }

    /*  CONFIRM LEAVE VR MODE */
    function add_alert_exit() {
        remove_global_menu();
        get_ath_atv();
        krpano.call("addhotspot(alert-exit-bg);");
        krpano.set("hotspot[alert-exit-bg].type", "text");
        krpano.set("hotspot[alert-exit-bg].ath", ath);
        krpano.set("hotspot[alert-exit-bg].atv", "0");
        krpano.set("hotspot[alert-exit-bg].alpha", "1.0");
        krpano.set("hotspot[alert-exit-bg].visible", "true");
        krpano.set("hotspot[alert-exit-bg].keep", "true");
        krpano.set("hotspot[alert-exit-bg].enabled", false);
        krpano.set("hotspot[alert-exit-bg].zorder", "10");
        krpano.set("hotspot[alert-exit-bg].width", "300px");
        krpano.set("hotspot[alert-exit-bg].height", "113px");
        krpano.set("hotspot[alert-exit-bg].distorted", "true");
        krpano.set("hotspot[alert-exit-bg].bgcolor", "0xfafafa");
        krpano.set("hotspot[alert-exit-bg].bgroundedge", "12");
        krpano.set("hotspot[alert-exit-bg].cursor", "default");
        krpano.set("hotspot[alert-exit-bg].scale", "0.66");
        krpano.set("hotspot[alert-exit-bg].bgshadow", "0 0 20 0x000000 0.4");


        krpano.call("addhotspot(alert-exit-button1);");
        krpano.set("hotspot[alert-exit-button1].type", "text");
        krpano.set("hotspot[alert-exit-button1].ath", ath);
        krpano.set("hotspot[alert-exit-button1].atv", "0");
        krpano.set("hotspot[alert-exit-button1].html", "Ukončiť");
        krpano.set("hotspot[alert-exit-button1].ox", "-45px");
        krpano.set("hotspot[alert-exit-button1].oy", "13px");
        krpano.set("hotspot[alert-exit-button1].visible", "true");
        //krpano.set("hotspot[alert-exit-button1].onclick", "js(confirm_leave_vr(););");
        krpano.set("hotspot[alert-exit-button1].keep", "true");
        krpano.set("hotspot[alert-exit-button1].zorder", 11);
        krpano.set("hotspot[alert-exit-button1].width", "120px");
        krpano.set("hotspot[alert-exit-button1].height", "40px");
        krpano.set("hotspot[alert-exit-button1].distorted", "true");
        krpano.set("hotspot[alert-exit-button1].bgcolor", "0x3F3F46");
        krpano.set("hotspot[alert-exit-button1].alpha", "1");
        krpano.set("hotspot[alert-exit-button1].bgroundedge", "6");
        krpano.set("hotspot[alert-exit-button1].vcenter", "true");
        krpano.set("hotspot[alert-exit-button1].scale", "0.66");
        krpano.set("hotspot[alert-exit-button1].css", "color:#FAFAFA; text-align:center; font-size:14px; font-weight:900;");

        let hs_exit_b1 = krpano.get("hotspot[alert-exit-button1]");
        hs_exit_b1.onclick = function() {
            //////console.log("v1");
            krpano.call("webvr.exitVR()");
            //remove_alert_exit();
        }

        krpano.call("addhotspot(alert-exit-button2);");
        krpano.set("hotspot[alert-exit-button2].type", "text");
        krpano.set("hotspot[alert-exit-button2].ath", ath);
        krpano.set("hotspot[alert-exit-button2].atv", "0");
        krpano.set("hotspot[alert-exit-button2].ox", "45px");
        krpano.set("hotspot[alert-exit-button2].oy", "13px");
        krpano.set("hotspot[alert-exit-button2].html", "Nie");
        krpano.set("hotspot[alert-exit-button2].visible", "true");
        //krpano.set("hotspot[alert-exit-button2].onclick", "js(cancel_leave_vr(););");
        krpano.set("hotspot[alert-exit-button2].keep", "true");
        krpano.set("hotspot[alert-exit-button2].zorder", 11);
        krpano.set("hotspot[alert-exit-button2].width", "120px");
        krpano.set("hotspot[alert-exit-button2].height", "40px");
        krpano.set("hotspot[alert-exit-button2].distorted", "true");
        krpano.set("hotspot[alert-exit-button2].bgcolor", "0x4F46E5");
        krpano.set("hotspot[alert-exit-button2].alpha", "1");
        krpano.set("hotspot[alert-exit-button2].bgroundedge", "6");
        krpano.set("hotspot[alert-exit-button2].vcenter", "true");
        krpano.set("hotspot[alert-exit-button2].scale", "0.66");
        krpano.set("hotspot[alert-exit-button2].css", "color:#FAFAFA; text-align:center; font-size:14px; font-weight:900;");

        let hs_exit_b2 = krpano.get("hotspot[alert-exit-button2]");
        hs_exit_b2.onclick = function() {
            //////console.log("v2");
            //krpano.call("webvr.exitVR()");
            remove_alert_exit();
        }

        

        krpano.call("addhotspot(alert-exit-question);");
        krpano.set("hotspot[alert-exit-question].type", "text");
        krpano.set("hotspot[alert-exit-question].ath", ath);
        krpano.set("hotspot[alert-exit-question].atv", "0");
        krpano.set("hotspot[alert-exit-question].enabled", false);
        krpano.set("hotspot[alert-exit-question].html", "Zavřít");
        krpano.set("hotspot[alert-exit-question].ox", "0px");
        krpano.set("hotspot[alert-exit-question].oy", "-20px");
        krpano.set("hotspot[alert-exit-question].html", "Chcete ukončit VR?");
        krpano.set("hotspot[alert-exit-question].visible", "true");
        krpano.set("hotspot[alert-exit-question].keep", "true");
        krpano.set("hotspot[alert-exit-question].zorder", "10");
        krpano.set("hotspot[alert-exit-question].width", "268px");
        krpano.set("hotspot[alert-exit-question].height", "21px");
        krpano.set("hotspot[alert-exit-question].distorted", "true");
        krpano.set("hotspot[alert-exit-question].alpha", "1");
        krpano.set("hotspot[alert-exit-question].bgalpha", "0");
        krpano.set("hotspot[alert-exit-question].vcenter", "true");
        krpano.set("hotspot[alert-exit-question].scale", "0.66");
        krpano.set("hotspot[alert-exit-question].css", "color:#3F3F46; text-align:center; font-size:14px;");
    }

    function remove_alert_exit () {
        if (krpano) {
            let elements = ['alert-exit-bg', 'alert-exit-button1', 'alert-exit-button2', 'alert-exit-question']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA SHORTCUTS  */
    function add_global_menu_shortcuts() {
        // Shortcuts
        krpano.call("addhotspot(shortcuts-bg);");
        krpano.set("hotspot[shortcuts-bg].type", "text");
        krpano.set("hotspot[shortcuts-bg].ath", ath);
        krpano.set("hotspot[shortcuts-bg].atv", 0);
        krpano.set("hotspot[shortcuts-bg].alpha", "1.0");
        krpano.set("hotspot[shortcuts-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[shortcuts-bg].ox", "-184px");
        krpano.set("hotspot[shortcuts-bg].oy", "-68px");
        krpano.set("hotspot[shortcuts-bg].visible", "true");
        krpano.set("hotspot[shortcuts-bg].enabled", "false");
        krpano.set("hotspot[shortcuts-bg].keep", "true");
        krpano.set("hotspot[shortcuts-bg].zorder", 10);
        krpano.set("hotspot[shortcuts-bg].width", "106px");
        krpano.set("hotspot[shortcuts-bg].height", "120px");
        krpano.set("hotspot[shortcuts-bg].distorted", "true");
        krpano.set("hotspot[shortcuts-bg].bgroundedge", "12");
        krpano.set("hotspot[shortcuts-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(shortcuts);");
        krpano.set("hotspot[shortcuts].type", "image");
        krpano.set("hotspot[shortcuts].ath", ath);
        krpano.set("hotspot[shortcuts].atv", 0);
        krpano.set("hotspot[shortcuts].url", "img/shortcuts.svg");
        krpano.set("hotspot[shortcuts].ox", "-184px");
        krpano.set("hotspot[shortcuts].oy", "-81px");
        krpano.set("hotspot[shortcuts].alpha", "0.4");
        krpano.set("hotspot[shortcuts].visible", "true");
        krpano.set("hotspot[shortcuts].enabled", "false");
        krpano.set("hotspot[shortcuts].keep", "true");
        krpano.set("hotspot[shortcuts].width", "30px");
        krpano.set("hotspot[shortcuts].height", "30px");
        krpano.set("hotspot[shortcuts].zorder", "10");					

        // text
        krpano.call("addhotspot(shortcuts-text);");
        krpano.set("hotspot[shortcuts-text].type", "text");
        krpano.set("hotspot[shortcuts-text].html", "Skratky");
        krpano.set("hotspot[shortcuts-text].ath", ath);
        krpano.set("hotspot[shortcuts-text].atv", 0);
        krpano.set("hotspot[shortcuts-text].ox", "-184px");
        krpano.set("hotspot[shortcuts-text].oy", "-50px");
        krpano.set("hotspot[shortcuts-text].alpha", "0.4");
        krpano.set("hotspot[shortcuts-text].visible", "true");
        krpano.set("hotspot[shortcuts-text].enabled", "false");
        krpano.set("hotspot[shortcuts-text].keep", "true");
        krpano.set("hotspot[shortcuts-text].zorder", "10");	
        krpano.set("hotspot[shortcuts-text].width", "158px");	
        krpano.set("hotspot[shortcuts-text].distorted", "true");	
        krpano.set("hotspot[shortcuts-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[shortcuts-text].bgalpha", "0");	
        krpano.set("hotspot[shortcuts-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(shortcuts-bg-empty);");
        // krpano.set("hotspot[shortcuts-bg-empty].type", "text");
        // krpano.set("hotspot[shortcuts-bg-empty].ath", ath);
        // krpano.set("hotspot[shortcuts-bg-empty].atv", 0);
        // krpano.set("hotspot[shortcuts-bg-empty].width", "106px");
        // krpano.set("hotspot[shortcuts-bg-empty].height", "120px");
        // krpano.set("hotspot[shortcuts-bg-empty].ox", "-184px");
        // krpano.set("hotspot[shortcuts-bg-empty].oy", "-68px");
        // krpano.set("hotspot[shortcuts-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[shortcuts-bg-empty].visible", "false");
        // krpano.set("hotspot[shortcuts-bg-empty].keep", "true");
        // krpano.set("hotspot[shortcuts-bg-empty].zorder", "11");	
        // krpano.set("hotspot[shortcuts-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[shortcuts-bg-empty].distorted", "true");
        // krpano.set("hotspot[shortcuts-bg-empty].onhover", "js(global_menu_add_hover(shortcuts););");
        // krpano.set("hotspot[shortcuts-bg-empty].onout", "js(global_menu_remove_hover(shortcuts););");
        // krpano.set("hotspot[shortcuts-bg-empty].onclick", "js(toggle_global_menu(submenu,shortcuts););");	

    }

    function remove_global_menu_shortcuts() {
        if (krpano) {
            let elements = ['shortcuts-bg', 'shortcuts', 'shortcuts-text','shortcuts-bg-empty']
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

     /*  GLOBAL MENU IKONA FLOORPLAN  */
    function add_global_menu_floorplan() {
        // Floorplan
        krpano.call("addhotspot(floorplan-bg);");
        krpano.set("hotspot[floorplan-bg].type", "text");
        krpano.set("hotspot[floorplan-bg].ath", ath);
        krpano.set("hotspot[floorplan-bg].atv", 0);
        krpano.set("hotspot[floorplan-bg].alpha", "1.0");
        krpano.set("hotspot[floorplan-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[floorplan-bg].ox", "-61px"); 
        krpano.set("hotspot[floorplan-bg].oy", "-68px");
        krpano.set("hotspot[floorplan-bg].visible", "true");
        krpano.set("hotspot[floorplan-bg].keep", "true");
        krpano.set("hotspot[floorplan-bg].zorder", 10);
        krpano.set("hotspot[floorplan-bg].width", "106px");
        krpano.set("hotspot[floorplan-bg].height", "120px");
        krpano.set("hotspot[floorplan-bg].distorted", "true");
        krpano.set("hotspot[floorplan-bg].enabled", false);
        krpano.set("hotspot[floorplan-bg].bgroundedge", "12");
        krpano.set("hotspot[floorplan-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(floorplan);");
        krpano.set("hotspot[floorplan].type", "image");
        krpano.set("hotspot[floorplan].ath", ath);
        krpano.set("hotspot[floorplan].atv", 0);
        krpano.set("hotspot[floorplan].url", "img/floorplan.svg");
        krpano.set("hotspot[floorplan].ox", "-61px");
        krpano.set("hotspot[floorplan].oy", "-81px");
        krpano.set("hotspot[floorplan].alpha", "0.4");
        krpano.set("hotspot[floorplan].visible", "true");
        krpano.set("hotspot[floorplan].enabled", false);
        krpano.set("hotspot[floorplan].keep", "true");
        krpano.set("hotspot[floorplan].width", "30px");
        krpano.set("hotspot[floorplan].height", "30px");
        krpano.set("hotspot[floorplan].zorder", "10");					

        // text
        krpano.call("addhotspot(floorplan-text);");
        krpano.set("hotspot[floorplan-text].type", "text");
        krpano.set("hotspot[floorplan-text].html", "Pôdorys");
        krpano.set("hotspot[floorplan-text].ath", ath);
        krpano.set("hotspot[floorplan-text].atv", 0);
        krpano.set("hotspot[floorplan-text].ox", "-61px");
        krpano.set("hotspot[floorplan-text].oy", "-50px");
        krpano.set("hotspot[floorplan-text].alpha", "0.4");
        krpano.set("hotspot[floorplan-text].visible", true);
        krpano.set("hotspot[floorplan-text].enabled", false);
        krpano.set("hotspot[floorplan-text].keep", "true");
        krpano.set("hotspot[floorplan-text].zorder", "10");	
        krpano.set("hotspot[floorplan-text].width", "105px");	
        krpano.set("hotspot[floorplan-text].distorted", "true");	
        krpano.set("hotspot[floorplan-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[floorplan-text].bgalpha", "0");	
        krpano.set("hotspot[floorplan-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(floorplan-bg-empty);");
        // krpano.set("hotspot[floorplan-bg-empty].type", "text");
        // krpano.set("hotspot[floorplan-bg-empty].ath", ath);
        // krpano.set("hotspot[floorplan-bg-empty].atv", 0);
        // krpano.set("hotspot[floorplan-bg-empty].width", "106px");
        // krpano.set("hotspot[floorplan-bg-empty].height", "120px");
        // krpano.set("hotspot[floorplan-bg-empty].ox", "-61px");
        // krpano.set("hotspot[floorplan-bg-empty].oy", "-68px");
        // krpano.set("hotspot[floorplan-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[floorplan-bg-empty].visible", "false");
        // krpano.set("hotspot[floorplan-bg-empty].keep", "true");
        // krpano.set("hotspot[floorplan-bg-empty].zorder", "11");	
        // krpano.set("hotspot[floorplan-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[floorplan-bg-empty].distorted", "true");
    }

    function remove_global_menu_floorplan() {
        if (krpano) {
            let elements = ['floorplan-bg', 'floorplan', 'floorplan-text','floorplan-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IOKNA ATTRIBUTES  */
    function add_global_menu_attributes() {
        // Attributes
        krpano.call("addhotspot(attributes-bg);");
        krpano.set("hotspot[attributes-bg].type", "text");
        krpano.set("hotspot[attributes-bg].ath", ath);
        krpano.set("hotspot[attributes-bg].atv", 0);
        krpano.set("hotspot[attributes-bg].alpha", "1.0");
        krpano.set("hotspot[attributes-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[attributes-bg].ox", "61px"); 
        krpano.set("hotspot[attributes-bg].oy", "-68px");
        krpano.set("hotspot[attributes-bg].visible", "true");
        krpano.set("hotspot[attributes-bg].enabled", "false");
        krpano.set("hotspot[attributes-bg].keep", "true");
        krpano.set("hotspot[attributes-bg].zorder", 10);
        krpano.set("hotspot[attributes-bg].width", "106px");
        krpano.set("hotspot[attributes-bg].height", "120px");
        krpano.set("hotspot[attributes-bg].distorted", "true");
        krpano.set("hotspot[attributes-bg].bgroundedge", "12");
        krpano.set("hotspot[attributes-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(attributes);");
        krpano.set("hotspot[attributes].type", "image");
        krpano.set("hotspot[attributes].ath", ath);
        krpano.set("hotspot[attributes].atv", 0);
        krpano.set("hotspot[attributes].url", "img/attributes.svg");
        krpano.set("hotspot[attributes].ox", "61px");
        krpano.set("hotspot[attributes].oy", "-81px");
        krpano.set("hotspot[attributes].alpha", "0.4");
        krpano.set("hotspot[attributes].visible", "true");
        krpano.set("hotspot[attributes].enabled", "false");
        krpano.set("hotspot[attributes].keep", "true");
        krpano.set("hotspot[attributes].width", "30px");
        krpano.set("hotspot[attributes].height", "30px");
        krpano.set("hotspot[attributes].zorder", "10");					

        // text
        krpano.call("addhotspot(attributes-text);");
        krpano.set("hotspot[attributes-text].type", "text");
        krpano.set("hotspot[attributes-text].html", "Atribúty");
        krpano.set("hotspot[attributes-text].ath", ath);
        krpano.set("hotspot[attributes-text].atv", 0);
        krpano.set("hotspot[attributes-text].ox", "61px");
        krpano.set("hotspot[attributes-text].oy", "-50px");
        krpano.set("hotspot[attributes-text].alpha", "0.4");
        krpano.set("hotspot[attributes-text].visible", "true");
        krpano.set("hotspot[attributes-text].enabled", "false");
        krpano.set("hotspot[attributes-text].keep", "true");
        krpano.set("hotspot[attributes-text].zorder", "10");	
        krpano.set("hotspot[attributes-text].width", "105px");	
        krpano.set("hotspot[attributes-text].distorted", "true");	
        krpano.set("hotspot[attributes-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[attributes-text].bgalpha", "0");	
        krpano.set("hotspot[attributes-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(attributes-bg-empty);");
        // krpano.set("hotspot[attributes-bg-empty].type", "text");
        // krpano.set("hotspot[attributes-bg-empty].ath", ath);
        // krpano.set("hotspot[attributes-bg-empty].atv", 0);
        // krpano.set("hotspot[attributes-bg-empty].width", "106px");
        // krpano.set("hotspot[attributes-bg-empty].height", "120px");
        // krpano.set("hotspot[attributes-bg-empty].ox", "61px");
        // krpano.set("hotspot[attributes-bg-empty].oy", "-68px");
        // krpano.set("hotspot[attributes-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[attributes-bg-empty].visible", "false");
        // krpano.set("hotspot[attributes-bg-empty].keep", "true");
        // krpano.set("hotspot[attributes-bg-empty].zorder", "11");	
        // krpano.set("hotspot[attributes-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[attributes-bg-empty].distorted", "true");
    }

    function remove_global_menu_attributes() {
        if (krpano) {
            let elements = ['attributes-bg', 'attributes', 'attributes-text','attributes-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA SHOP  */
    function add_global_menu_shop() {
        // Shop
        krpano.call("addhotspot(shop-bg);");
        krpano.set("hotspot[shop-bg].type", "text");
        krpano.set("hotspot[shop-bg].ath", ath); 
        krpano.set("hotspot[shop-bg].atv", 0);
        krpano.set("hotspot[shop-bg].alpha", "1.0");
        krpano.set("hotspot[shop-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[shop-bg].ox", "184px"); 
        krpano.set("hotspot[shop-bg].oy", "-68px");
        krpano.set("hotspot[shop-bg].visible", true);
        krpano.set("hotspot[shop-bg].enabled", false);
        krpano.set("hotspot[shop-bg].keep", "true");
        krpano.set("hotspot[shop-bg].zorder", 10);
        krpano.set("hotspot[shop-bg].width", "106px");
        krpano.set("hotspot[shop-bg].height", "120px");
        krpano.set("hotspot[shop-bg].distorted", "true");
        krpano.set("hotspot[shop-bg].bgroundedge", "12");
        krpano.set("hotspot[shop-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(shop);");
        krpano.set("hotspot[shop].type", "image");
        krpano.set("hotspot[shop].ath", ath);
        krpano.set("hotspot[shop].atv", 0);
        krpano.set("hotspot[shop].url", "img/shop.svg");
        krpano.set("hotspot[shop].ox", "184px");  
        krpano.set("hotspot[shop].oy", "-81px");
        krpano.set("hotspot[shop].alpha", "0.4");
        krpano.set("hotspot[shop].visible", true);
        krpano.set("hotspot[shop].enabled", false);
        krpano.set("hotspot[shop].keep", "true");
        krpano.set("hotspot[shop].width", "30px");
        krpano.set("hotspot[shop].height", "30px");
        krpano.set("hotspot[shop].zorder", 11);					

        // text
        krpano.call("addhotspot(shop-text);");
        krpano.set("hotspot[shop-text].type", "text");
        krpano.set("hotspot[shop-text].html", "Obchod");
        krpano.set("hotspot[shop-text].ath", ath);
        krpano.set("hotspot[shop-text].atv", 0);
        krpano.set("hotspot[shop-text].ox", "184px");
        krpano.set("hotspot[shop-text].oy", "-50px"); 
        krpano.set("hotspot[shop-text].alpha", "0.4");
        krpano.set("hotspot[shop-text].visible", true);
        krpano.set("hotspot[shop-text].enabled", false);
        krpano.set("hotspot[shop-text].keep", "true");
        krpano.set("hotspot[shop-text].zorder", 11);	
        krpano.set("hotspot[shop-text].width", "105px");	
        krpano.set("hotspot[shop-text].distorted", "true");	
        krpano.set("hotspot[shop-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[shop-text].bgalpha", "0");	
        krpano.set("hotspot[shop-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(shop-bg-empty);");
        // krpano.set("hotspot[shop-bg-empty].type", "text");
        // krpano.set("hotspot[shop-bg-empty].ath", atv);
        // krpano.set("hotspot[shop-bg-empty].atv", 0);
        // krpano.set("hotspot[shop-bg-empty].width", "106px");
        // krpano.set("hotspot[shop-bg-empty].height", "120px");
        // krpano.set("hotspot[shop-bg-empty].ox", "184px");
        // krpano.set("hotspot[shop-bg-empty].oy", "-68px");
        // krpano.set("hotspot[shop-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[shop-bg-empty].visible", "false");
        // krpano.set("hotspot[shop-bg-empty].keep", "true");  
        // krpano.set("hotspot[shop-bg-empty].zorder", "11");	
        // krpano.set("hotspot[shop-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[shop-bg-empty].distorted", "true");
    }

    function remove_global_menu_shop() {
        if (krpano) {
            let elements = ['shop-bg', 'shop', 'shop-text','shop-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA LAYERS  */
    function add_global_menu_layers() {
        // Layers
        krpano.call("addhotspot(layers-bg);");
        krpano.set("hotspot[layers-bg].type", "text");
        krpano.set("hotspot[layers-bg].ath", ath); 
        krpano.set("hotspot[layers-bg].atv", 0);
        krpano.set("hotspot[layers-bg].alpha", "1.0");
        krpano.set("hotspot[layers-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[layers-bg].ox", "-184px");  
        krpano.set("hotspot[layers-bg].oy", "68px");
        krpano.set("hotspot[layers-bg].visible", "true");
        krpano.set("hotspot[layers-bg].enabled", "false");
        krpano.set("hotspot[layers-bg].keep", "true");
        krpano.set("hotspot[layers-bg].zorder", 10);
        krpano.set("hotspot[layers-bg].width", "106px");
        krpano.set("hotspot[layers-bg].height", "120px");
        krpano.set("hotspot[layers-bg].distorted", "true");
        krpano.set("hotspot[layers-bg].bgroundedge", "12");
        krpano.set("hotspot[layers-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(layers);");
        krpano.set("hotspot[layers].type", "image");
        krpano.set("hotspot[layers].ath", ath);
        krpano.set("hotspot[layers].atv", 0);
        krpano.set("hotspot[layers].url", "img/layers.svg");
        krpano.set("hotspot[layers].ox", "-184px");   
        krpano.set("hotspot[layers].oy", "50px");
        krpano.set("hotspot[layers].alpha", "0.4");
        krpano.set("hotspot[layers].visible", "true");
        krpano.set("hotspot[layers].enabled", "false");
        krpano.set("hotspot[layers].keep", "true");
        krpano.set("hotspot[layers].width", "30px");
        krpano.set("hotspot[layers].height", "30px");
        krpano.set("hotspot[layers].zorder", "11");					

        // text
        krpano.call("addhotspot(layers-text);");
        krpano.set("hotspot[layers-text].type", "text");
        krpano.set("hotspot[layers-text].html", "Vrstvy");
        krpano.set("hotspot[layers-text].ath", ath);
        krpano.set("hotspot[layers-text].atv", 0);
        krpano.set("hotspot[layers-text].ox", "-184px");
        krpano.set("hotspot[layers-text].oy", "81px"); 
        krpano.set("hotspot[layers-text].alpha", "0.4");
        krpano.set("hotspot[layers-text].visible", "true");
        krpano.set("hotspot[layers-text].enabled", "false");
        krpano.set("hotspot[layers-text].keep", "true");
        krpano.set("hotspot[layers-text].zorder", "11");	
        krpano.set("hotspot[layers-text].width", "105px");	
        krpano.set("hotspot[layers-text].distorted", "true");	
        krpano.set("hotspot[layers-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[layers-text].bgalpha", "0");	
        krpano.set("hotspot[layers-text].edge", "true");	

        // Emtpy layer
        krpano.call("addhotspot(layers-bg-empty);");
        krpano.set("hotspot[layers-bg-empty].type", "text");
        krpano.set("hotspot[layers-bg-empty].ath", ath);
        krpano.set("hotspot[layers-bg-empty].atv", 0);
        krpano.set("hotspot[layers-bg-empty].width", "106px");
        krpano.set("hotspot[layers-bg-empty].height", "120px");
        krpano.set("hotspot[layers-bg-empty].ox", "-184px");
        krpano.set("hotspot[layers-bg-empty].oy", "68px");  
        krpano.set("hotspot[layers-bg-empty].alpha", "0.0");
        krpano.set("hotspot[layers-bg-empty].visible", "false");
        krpano.set("hotspot[layers-bg-empty].keep", "true");  
        krpano.set("hotspot[layers-bg-empty].zorder", "11");	
        krpano.set("hotspot[layers-bg-empty].bgroundedge", "12");	
        krpano.set("hotspot[layers-bg-empty].distorted", "true");
    }

    function remove_global_menu_layers() {
        if (krpano) {
            let elements = ['layers-bg', 'layers', 'layers-text','layers-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA MODEL  */
    function add_global_menu_model() {
        // Model
        krpano.call("addhotspot(model-bg);");
        krpano.set("hotspot[model-bg].type", "text");
        krpano.set("hotspot[model-bg].ath", ath); 
        krpano.set("hotspot[model-bg].atv", 0);
        krpano.set("hotspot[model-bg].alpha", "1.0");
        krpano.set("hotspot[model-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[model-bg].ox", "-61x");    
        krpano.set("hotspot[model-bg].oy", "68px");
        krpano.set("hotspot[model-bg].visible", "true");
        krpano.set("hotspot[model-bg].enabled", "false");
        krpano.set("hotspot[model-bg].keep", "true");
        krpano.set("hotspot[model-bg].zorder", 10);
        krpano.set("hotspot[model-bg].width", "106px");
        krpano.set("hotspot[model-bg].height", "120px");
        krpano.set("hotspot[model-bg].distorted", "true");
        krpano.set("hotspot[model-bg].bgroundedge", "12");
        krpano.set("hotspot[model-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(model);");
        krpano.set("hotspot[model].type", "image");
        krpano.set("hotspot[model].ath", ath);
        krpano.set("hotspot[model].atv", 0);
        krpano.set("hotspot[model].url", "img/model.svg");
        krpano.set("hotspot[model].ox", "-61px");  
        krpano.set("hotspot[model].oy", "50px");
        krpano.set("hotspot[model].alpha", "0.4");
        krpano.set("hotspot[model].visible", "true");
        krpano.set("hotspot[model].enabled", "false");
        krpano.set("hotspot[model].keep", "true");
        krpano.set("hotspot[model].width", "30px");
        krpano.set("hotspot[model].height", "30px");
        krpano.set("hotspot[model].zorder", "10");					

        // text
        krpano.call("addhotspot(model-text);");
        krpano.set("hotspot[model-text].type", "text");
        krpano.set("hotspot[model-text].html", "3D Model");
        krpano.set("hotspot[model-text].ath", ath);
        krpano.set("hotspot[model-text].atv", 0);
        krpano.set("hotspot[model-text].ox", "-61px");
        krpano.set("hotspot[model-text].oy", "81px"); 
        krpano.set("hotspot[model-text].alpha", "0.4");
        krpano.set("hotspot[model-text].visible", "true");
        krpano.set("hotspot[model-text].enabled", "false");
        krpano.set("hotspot[model-text].keep", "true");
        krpano.set("hotspot[model-text].zorder", "10");	
        krpano.set("hotspot[model-text].width", "105px");	
        krpano.set("hotspot[model-text].distorted", "true");	
        krpano.set("hotspot[model-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[model-text].bgalpha", "0");	
        krpano.set("hotspot[model-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(model-bg-empty);");
        // krpano.set("hotspot[model-bg-empty].type", "text");
        // krpano.set("hotspot[model-bg-empty].ath", ath);
        // krpano.set("hotspot[model-bg-empty].atv", 0);
        // krpano.set("hotspot[model-bg-empty].width", "106px");
        // krpano.set("hotspot[model-bg-empty].height", "120px");
        // krpano.set("hotspot[model-bg-empty].ox", "-61x");
        // krpano.set("hotspot[model-bg-empty].oy", "81px");
        // krpano.set("hotspot[model-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[model-bg-empty].visible", "false");
        // krpano.set("hotspot[model-bg-empty].keep", "true");  
        // krpano.set("hotspot[model-bg-empty].zorder", "11");	
        // krpano.set("hotspot[model-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[model-bg-empty].distorted", "true");
    }

    function remove_global_menu_model() {
        if (krpano) {
            let elements = ['model-bg', 'model', 'model-text','model-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA INFO  */
    function add_global_menu_info() {
        // Info
        krpano.call("addhotspot(info-bg);");
        krpano.set("hotspot[info-bg].type", "text");
        krpano.set("hotspot[info-bg].ath", ath); 
        krpano.set("hotspot[info-bg].atv", 0);
        krpano.set("hotspot[info-bg].alpha", "1.0");
        krpano.set("hotspot[info-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[info-bg].ox", "61x");    
        krpano.set("hotspot[info-bg].oy", "68px");
        krpano.set("hotspot[info-bg].visible", "true");
        krpano.set("hotspot[info-bg].enabled", "false");
        krpano.set("hotspot[info-bg].keep", "true");
        krpano.set("hotspot[info-bg].zorder", 10);
        krpano.set("hotspot[info-bg].width", "106px");
        krpano.set("hotspot[info-bg].height", "120px");
        krpano.set("hotspot[info-bg].distorted", "true");
        krpano.set("hotspot[info-bg].bgroundedge", "12");
        krpano.set("hotspot[info-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(info);");
        krpano.set("hotspot[info].type", "image");
        krpano.set("hotspot[info].ath", ath);
        krpano.set("hotspot[info].atv", 0);
        krpano.set("hotspot[info].url", "img/info.svg");
        krpano.set("hotspot[info].ox", "61px");  
        krpano.set("hotspot[info].oy", "50px");
        krpano.set("hotspot[info].alpha", "0.4");
        krpano.set("hotspot[info].visible", "true");
        krpano.set("hotspot[info].enabled", "false");
        krpano.set("hotspot[info].keep", "true");
        krpano.set("hotspot[info].width", "30px");
        krpano.set("hotspot[info].height", "30px");
        krpano.set("hotspot[info].zorder", "10");					

        // text
        krpano.call("addhotspot(info-text);");
        krpano.set("hotspot[info-text].type", "text");
        krpano.set("hotspot[info-text].html", "Tour info");
        krpano.set("hotspot[info-text].ath", ath);
        krpano.set("hotspot[info-text].atv", 0);
        krpano.set("hotspot[info-text].ox", "61px");
        krpano.set("hotspot[info-text].oy", "81px"); 
        krpano.set("hotspot[info-text].alpha", "0.4");
        krpano.set("hotspot[info-text].visible", "true");
        krpano.set("hotspot[info-text].enabled", "false");
        krpano.set("hotspot[info-text].keep", "true");
        krpano.set("hotspot[info-text].zorder", "10");	
        krpano.set("hotspot[info-text].width", "105px");	
        krpano.set("hotspot[info-text].distorted", "true");	
        krpano.set("hotspot[info-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[info-text].bgalpha", "0");	
        krpano.set("hotspot[info-text].edge", "true");	

        // Emtpy layer
        // krpano.call("addhotspot(info-bg-empty);");
        // krpano.set("hotspot[info-bg-empty].type", "text");
        // krpano.set("hotspot[info-bg-empty].ath", ath);
        // krpano.set("hotspot[info-bg-empty].atv", 0);
        // krpano.set("hotspot[info-bg-empty].width", "106px");
        // krpano.set("hotspot[info-bg-empty].height", "120px");
        // krpano.set("hotspot[info-bg-empty].ox", "61x");
        // krpano.set("hotspot[info-bg-empty].oy", "68px");
        // krpano.set("hotspot[info-bg-empty].alpha", "0.0");
        // krpano.set("hotspot[info-bg-empty].visible", "false");
        // krpano.set("hotspot[info-bg-empty].keep", "true");  
        // krpano.set("hotspot[info-bg-empty].zorder", "11");	
        // krpano.set("hotspot[info-bg-empty].bgroundedge", "12");	
        // krpano.set("hotspot[info-bg-empty].distorted", "true");
        // krpano.set("hotspot[info-bg-empty].onhover", "js(global_menu_add_hover(info););");
        // krpano.set("hotspot[info-bg-empty].onout", "js(global_menu_remove_hover(info););");
        // krpano.set("hotspot[info-bg-empty].onclick",  "js(toggle_global_menu(submenu,info, " + settings.components[8].data.title + "););");	
    }

    function remove_global_menu_info() {
        if (krpano) {
            let elements = ['info-bg', 'info', 'info-text','info-bg-empty'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    /*  GLOBAL MENU IKONA SETTINGS  */
    function add_global_menu_settings() {
        // Settings
        krpano.call("addhotspot(settings-bg);");
        krpano.set("hotspot[settings-bg].type", "text");
        krpano.set("hotspot[settings-bg].ath", ath); 
        krpano.set("hotspot[settings-bg].atv", 0);
        krpano.set("hotspot[settings-bg].alpha", "1.0");
        krpano.set("hotspot[settings-bg].css", "color:#FAFAFA; text-align:left;font-size:14px;");
        krpano.set("hotspot[settings-bg].ox", "184"); 
        krpano.set("hotspot[settings-bg].oy", "68px");
        krpano.set("hotspot[settings-bg].visible", "true");
        krpano.set("hotspot[settings-bg].keep", "true");
        krpano.set("hotspot[settings-bg].zorder", 10);
        krpano.set("hotspot[settings-bg].width", "106px");
        krpano.set("hotspot[settings-bg].height", "120px");
        krpano.set("hotspot[settings-bg].distorted", "true");
        krpano.set("hotspot[settings-bg].bgroundedge", "12");
        krpano.set("hotspot[settings-bg].bgborder", "2.0 0x3F3F46 0.2");

        // ikona
        krpano.call("addhotspot(settings);");
        krpano.set("hotspot[settings].type", "image");
        krpano.set("hotspot[settings].ath", ath);
        krpano.set("hotspot[settings].atv", 0);
        krpano.set("hotspot[settings].url", "img/settings.svg");
        krpano.set("hotspot[settings].ox", "184px");  
        krpano.set("hotspot[settings].oy", "50px");
        krpano.set("hotspot[settings].alpha", "1.0");
        krpano.set("hotspot[settings].visible", "true");
        krpano.set("hotspot[settings].enabled", "false");
        krpano.set("hotspot[settings].keep", "true");
        krpano.set("hotspot[settings].width", "30px");
        krpano.set("hotspot[settings].height", "30px");
        krpano.set("hotspot[settings].zorder", "10");		
        
        // text
        krpano.call("addhotspot(settings-text);");
        krpano.set("hotspot[settings-text].type", "text");
        krpano.set("hotspot[settings-text].html", "Nastavenie");
        krpano.set("hotspot[settings-text].ath", ath);
        krpano.set("hotspot[settings-text].atv", 0);
        krpano.set("hotspot[settings-text].ox", "184px");
        krpano.set("hotspot[settings-text].oy", "81px"); 
        krpano.set("hotspot[settings-text].alpha", "1.0");
        krpano.set("hotspot[settings-text].visible", "true");
        krpano.set("hotspot[settings-text].keep", "true");
        krpano.set("hotspot[settings-text].zorder", "10");	
        krpano.set("hotspot[settings-text].width", "105px");	
        krpano.set("hotspot[settings-text].distorted", "true");	
        krpano.set("hotspot[settings-text].css", "color:#3F3F46; text-align:center;font-size:14px;");	
        krpano.set("hotspot[settings-text].bgalpha", "0");	
        krpano.set("hotspot[settings-text].edge", "true");	
        
        // Emtpy layer
        krpano.call("addhotspot(settings-bg-empty);");
        krpano.set("hotspot[settings-bg-empty].type", "text");
        krpano.set("hotspot[settings-bg-empty].ath", ath);
        krpano.set("hotspot[settings-bg-empty].atv", 0);
        krpano.set("hotspot[settings-bg-empty].width", "106px");
        krpano.set("hotspot[settings-bg-empty].height", "120px");
        krpano.set("hotspot[settings-bg-empty].ox", "184x");
        krpano.set("hotspot[settings-bg-empty].oy", "68px");
        krpano.set("hotspot[settings-bg-empty].alpha", "0.0");
        krpano.set("hotspot[settings-bg-empty].visible", "true");
        krpano.set("hotspot[settings-bg-empty].keep", "true");  
        krpano.set("hotspot[settings-bg-empty].zorder", "11");	
        krpano.set("hotspot[settings-bg-empty].bgroundedge", "12");	
        krpano.set("hotspot[settings-bg-empty].distorted", "true");

        var hs_s = krpano.get("hotspot[settings-bg-empty]");
        hs_s.onover = function() {
           global_menu_add_hover("settings");
        }

        hs_s.onout = function() {
           global_menu_remove_hover("settings");
        }

        hs_s.onclick = function() {
            remove_global_menu_all_items();
            add_settings_item_settings();
            add_submenu_settings_items();
        }

    }

    function remove_global_menu_settings() {
        if (krpano) {
            let elementiky = ['settings-bg','settings','settings-text','settings-bg-empty'];
            for (let index = 0; index < elementiky.length; index++) {
                krpano.call("removehotspot(" + elementiky[index] + ")");
                //krpano.set("hotspot[" + elements[index] + "].alpha", "0.2");
            }
            
        }
    }

    /*  NASTAVENIA - položka SETTINGS  */
    function add_settings_item_settings() {
        //get_ath_atv();
        krpano.call("addhotspot(back-settings);");
        krpano.set("hotspot[back-settings].type", "image");
        krpano.set("hotspot[back-settings].ath", ath);
        krpano.set("hotspot[back-settings].atv", "0");
        krpano.set("hotspot[back-settings].url", "img/back.svg");
        krpano.set("hotspot[back-settings].ox", "-255px");
        krpano.set("hotspot[back-settings].oy", "-168px");
        krpano.set("hotspot[back-settings].alpha", "1.0");
        krpano.set("hotspot[back-settings].visible", "true");
        krpano.set("hotspot[back-settings].keep", "true");
        krpano.set("hotspot[back-settings].zorder", "13");
        krpano.set("hotspot[back-settings].distorted", "true");
        krpano.set("hotspot[back-settings].scale", "0.66");

        var hs_back = krpano.get("hotspot[back-settings]");
        hs_back.onclick = function() {
            //add_global_menu();
            remove_settings_item_settings();
            remove_submenu_settings_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_settings();
            add_global_menu_info();
            
        }

        krpano.call("addhotspot(back-settings-text);");
        krpano.set("hotspot[back-settings-text].type", "text");
        krpano.set("hotspot[back-settings-text].ath", ath);
        krpano.set("hotspot[back-settings-text].atv", "0");
        krpano.set("hotspot[back-settings-text].ox", "-242px");
        krpano.set("hotspot[back-settings-text].html", "Späť");
        krpano.set("hotspot[back-settings-text].oy", "-175px");
        krpano.set("hotspot[back-settings-text].alpha", "1.0");
        krpano.set("hotspot[back-settings-text].visible", "true");
        krpano.set("hotspot[back-settings-text].keep", "true");
        krpano.set("hotspot[back-settings-text].zorder", "13");
        krpano.set("hotspot[back-settings-text].distorted", "true");
        krpano.set("hotspot[back-settings-text].width", "70px");
        krpano.set("hotspot[back-settings-text].css", "color:#FAFAFA; text-align:left;font-size:16px;");
        krpano.set("hotspot[back-settings-text].bgalpha", "0");
        krpano.set("hotspot[back-settings-text].scale", "0.66");
        krpano.set("hotspot[back-settings-text].edge", "top");

        var hs_back_text = krpano.get("hotspot[back-settings-text]");
        hs_back_text.onclick = function() {
            //add_global_menu();
            remove_settings_item_settings();
            remove_submenu_settings_items();

            add_global_menu_attributes();
            add_global_menu_floorplan();
            add_global_menu_layers();
            add_global_menu_model();
            add_global_menu_shop();
            add_global_menu_shortcuts();
            add_global_menu_info();
            add_global_menu_settings();	
        }
    }

    function remove_settings_item_settings() {
        if (krpano) {
            let elements = ['back-settings', 'back-settings-text'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    // Pridanie položiek submenu pre Settings
    function add_submenu_settings_items() {
        // Advertisement
        krpano.call("addhotspot(advertisement-text);");
        krpano.set("hotspot[advertisement-text].type", "text");
        krpano.set("hotspot[advertisement-text].ath", ath);
        krpano.set("hotspot[advertisement-text].atv", "0");
        krpano.set("hotspot[advertisement-text].html", "Reklamy");
        krpano.set("hotspot[advertisement-text].ox", "0px");
        krpano.set("hotspot[advertisement-text].oy", "0px");
        krpano.set("hotspot[advertisement-text].alpha", "1.0");
        krpano.set("hotspot[advertisement-text].visible", "true");
        krpano.set("hotspot[advertisement-text].keep", "true");
        krpano.set("hotspot[advertisement-text].zorder", "10");
        krpano.set("hotspot[advertisement-text].distorted", "true");
        krpano.set("hotspot[advertisement-text].width", "500px");
        krpano.set("hotspot[advertisement-text].height", "64px");
        krpano.set("hotspot[advertisement-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[advertisement-text].bgroundedge", "12");
        krpano.set("hotspot[advertisement-text].vcenter", "true");
        krpano.set("hotspot[advertisement-text].scale", "0.66");
        krpano.set("hotspot[advertisement-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        // Hotspots
        krpano.call("addhotspot(hotspots-text);");
        krpano.set("hotspot[hotspots-text].type", "text");
        krpano.set("hotspot[hotspots-text].ath", ath);
        krpano.set("hotspot[hotspots-text].atv", "0");
        krpano.set("hotspot[hotspots-text].html", "Hotspoty");
        krpano.set("hotspot[hotspots-text].ox", "0px");
        krpano.set("hotspot[hotspots-text].oy", "-60px");
        krpano.set("hotspot[hotspots-text].alpha", "1.0");
        krpano.set("hotspot[hotspots-text].visible", "true");
        krpano.set("hotspot[hotspots-text].keep", "true");
        krpano.set("hotspot[hotspots-text].zorder", "10");
        krpano.set("hotspot[hotspots-text].distorted", "true");
        krpano.set("hotspot[hotspots-text].width", "500px");
        krpano.set("hotspot[hotspots-text].height", "64px");
        krpano.set("hotspot[hotspots-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[hotspots-text].bgroundedge", "12");
        krpano.set("hotspot[hotspots-text].vcenter", "true");
        krpano.set("hotspot[hotspots-text].scale", "0.66");
        krpano.set("hotspot[hotspots-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        var hs_hts_text = krpano.get("hotspot[hotspots-text]");
        hs_hts_text.onclick = function() {
            global_menu_toggle_checkbox("hotspots");
        }

        // Sounds
        krpano.call("addhotspot(sound-text);");
        krpano.set("hotspot[sound-text].type", "text");
        krpano.set("hotspot[sound-text].ath", ath);
        krpano.set("hotspot[sound-text].atv", "0");
        krpano.set("hotspot[sound-text].html", "Zvuky");
        krpano.set("hotspot[sound-text].ox", "0px");
        krpano.set("hotspot[sound-text].oy", "60px");
        krpano.set("hotspot[sound-text].alpha", "1.0");
        krpano.set("hotspot[sound-text].visible", "true");
        krpano.set("hotspot[sound-text].keep", "true");
        krpano.set("hotspot[sound-text].zorder", "10");
        krpano.set("hotspot[sound-text].distorted", "true");
        krpano.set("hotspot[sound-text].width", "500px");
        krpano.set("hotspot[sound-text].height", "64px");
        krpano.set("hotspot[sound-text].bgcolor", "0xfafafa");
        krpano.set("hotspot[sound-text].bgroundedge", "12");
        krpano.set("hotspot[sound-text].vcenter", "true");
        krpano.set("hotspot[sound-text].scale", "0.66");
        krpano.set("hotspot[sound-text].css", "color:#3F3F46; text-align:left;font-size:14px;margin-left:12px; color:#3f3f46;");

        var hs_sound_text = krpano.get("hotspot[sound-text]");
        hs_sound_text.onclick = function() {
            global_menu_toggle_checkbox("sound");
        }

        //checkboxes
        krpano.call("addhotspot(advertisement-check);");
        krpano.set("hotspot[advertisement-check].type", "image");
        krpano.set("hotspot[advertisement-check].ath", ath);
        krpano.set("hotspot[advertisement-check].atv", "0");
        krpano.set("hotspot[advertisement-check].url", "img/checkbox-off.svg");
        krpano.set("hotspot[advertisement-check].ox", "142px");
        krpano.set("hotspot[advertisement-check].oy", "0px");
        krpano.set("hotspot[advertisement-check].alpha", "1.0");
        krpano.set("hotspot[advertisement-check].visible", "true");
        krpano.set("hotspot[advertisement-check].keep", "true");
        krpano.set("hotspot[advertisement-check].zorder", "10");
        krpano.set("hotspot[advertisement-check].distorted", "true");
        krpano.set("hotspot[advertisement-check].scale", "0.66");

        krpano.call("addhotspot(hotspots-check);");
        krpano.set("hotspot[hotspots-check].type", "image");
        krpano.set("hotspot[hotspots-check].ath", ath);
        krpano.set("hotspot[hotspots-check].atv", "0");
        krpano.set("hotspot[hotspots-check].url", "img/checkbox-on.svg");
        krpano.set("hotspot[hotspots-check].ox", "142px");
        krpano.set("hotspot[hotspots-check].oy", "-60px");
        krpano.set("hotspot[hotspots-check].alpha", "1.0");
        krpano.set("hotspot[hotspots-check].visible", "true");
        krpano.set("hotspot[hotspots-check].keep", "true");
        krpano.set("hotspot[hotspots-check].zorder", "10");
        krpano.set("hotspot[hotspots-check].distorted", "true");
        krpano.set("hotspot[hotspots-check].scale", "0.66");

        var hs_hts_check = krpano.get("hotspot[hotspots-check]");
        hs_hts_check.onclick = function() {
            global_menu_toggle_checkbox("hotspots");
        }

        krpano.call("addhotspot(sound-check);");
        krpano.set("hotspot[sound-check].type", "image");
        krpano.set("hotspot[sound-check].ath", ath);
        krpano.set("hotspot[sound-check].atv", "0");
        krpano.set("hotspot[sound-check].url", "img/checkbox-on.svg");
        krpano.set("hotspot[sound-check].ox", "142px");
        krpano.set("hotspot[sound-check].oy", "60px");
        krpano.set("hotspot[sound-check].alpha", "1.0");
        krpano.set("hotspot[sound-check].visible", "true");
        krpano.set("hotspot[sound-check].keep", "true");
        krpano.set("hotspot[sound-check].zorder", "10");
        krpano.set("hotspot[sound-check].distorted", "true");
        krpano.set("hotspot[sound-check].scale", "0.66");

        var hs_sound_check = krpano.get("hotspot[sound-check]");
        hs_sound_check.onclick = function() {
            global_menu_toggle_checkbox("sound");
        }
    }

    function remove_submenu_settings_items() {
        if (krpano) {
            let elements = ['advertisement-text', 'hotspots-text', 'sound-text', 'advertisement-check', 'hotspots-check', 'sound-check'];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    // Obsah pre Global info
    function add_submenu_global_info() {
        krpano.call("addhotspot(info-image-left);");
        krpano.set("hotspot[info-image-left].type", "image");
        krpano.set("hotspot[info-image-left].ath", "0.0");
        krpano.set("hotspot[info-image-left].atv", "0.0");
        krpano.set("hotspot[info-image-left].url", 'assets/'+settings.components[8].data.img);
        krpano.set("hotspot[info-image-left].width", "270");
        krpano.set("hotspot[info-image-left].height", "prop");
        krpano.set("hotspot[info-image-left].edge", "righttop");
        krpano.set("hotspot[info-image-left].ox", "0");
        krpano.set("hotspot[info-image-left].oy", "-120px");
        krpano.set("hotspot[info-image-left].alpha", "0.0");
        krpano.set("hotspot[info-image-left].visible", "false");
        krpano.set("hotspot[info-image-left].keep", "true");
        krpano.set("hotspot[info-image-left].zorder", "10");
        krpano.set("hotspot[info-image-left].distorted", "true");;
        krpano.set("hotspot[info-image-left].scale", "0.66");
        krpano.set("hotspot[info-image-left].bgroundedge", "12");

        krpano.call("addhotspot(info-content);");
        krpano.set("hotspot[info-content].type", "text");
        krpano.set("hotspot[info-content].ath", "0.0");
        krpano.set("hotspot[info-content].atv", "0.0");
        krpano.set("hotspot[info-content].html", settings.components[8].data.content);
        krpano.set("hotspot[info-content].ox", "20");
        krpano.set("hotspot[info-content].oy", "-120px");
        krpano.set("hotspot[info-content].alpha", "0.0");
        krpano.set("hotspot[info-content].visible", "false");
        krpano.set("hotspot[info-content].keep", "true");
        krpano.set("hotspot[info-content].zorder", "10");
        krpano.set("hotspot[info-content].distorted", "true");
        krpano.set("hotspot[info-content].width", "350px");
        krpano.set("hotspot[info-content].height", "");
        krpano.set("hotspot[info-content].css", "color:#3F3F46; text-align:left;font-size:14px;color:#3f3f46;");
        krpano.set("hotspot[info-content].bgalpha", "0");
        krpano.set("hotspot[info-content].scale", "0.66");
        krpano.set("hotspot[info-content].edge", "lefttop");
        krpano.set("hotspot[info-content].vcenter", "true");
    }

    function remove_submenu_global_info() {
        if (krpano) {
            let elements = ['info-image-left','info-content' ];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    // Pridanie obsahu pre submenu Shortcuts
    function add_submenu_shortcuts() {
        krpano.call("addhotspot(sc-controls-bg);");
        krpano.set("hotspot[sc-controls-bg].type", "text");
        krpano.set("hotspot[sc-controls-bg].ath", ath);
        krpano.set("hotspot[sc-controls-bg].atv", "0");
        krpano.set("hotspot[sc-controls-bg].ox", "0px");
        krpano.set("hotspot[sc-controls-bg].oy", "170px");
        krpano.set("hotspot[sc-controls-bg].alpha", "1.0");
        krpano.set("hotspot[sc-controls-bg].visible", "true");
        krpano.set("hotspot[sc-controls-bg].enabled", "false");
        krpano.set("hotspot[sc-controls-bg].keep", "true");
        krpano.set("hotspot[sc-controls-bg].zorder", "10");
        krpano.set("hotspot[sc-controls-bg].distorted", "true");
        krpano.set("hotspot[sc-controls-bg].width", "900px");
        krpano.set("hotspot[sc-controls-bg].height", "48px");
        krpano.set("hotspot[sc-controls-bg].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[sc-controls-bg].bgalpha", "0");
        krpano.set("hotspot[sc-controls-bg].bgcolor", "0x3f3f46");
        krpano.set("hotspot[sc-controls-bg].scale", "0.66");
        krpano.set("hotspot[sc-controls-bg].cursor", "default");

        krpano.call("addhotspot(automatic-tour);");
        krpano.set("hotspot[automatic-tour].type", "text");
        krpano.set("hotspot[automatic-tour].html", "Automatická tour");
        krpano.set("hotspot[automatic-tour].ath", ath);
        krpano.set("hotspot[automatic-tour].atv", "0");
        krpano.set("hotspot[automatic-tour].ox", "-276px");
        krpano.set("hotspot[automatic-tour].oy", "170px");
        krpano.set("hotspot[automatic-tour].alpha", "0.0");
        krpano.set("hotspot[automatic-tour].visible", "false");
        krpano.set("hotspot[automatic-tour].keep", "true");
        krpano.set("hotspot[automatic-tour].zorder", "10");
        krpano.set("hotspot[automatic-tour].scale", "0.66");
        krpano.set("hotspot[automatic-tour].distorted", "true");
        krpano.set("hotspot[automatic-tour].width", "150px");
        krpano.set("hotspot[automatic-tour].height", "48px");
        krpano.set("hotspot[automatic-tour].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[automatic-tour].bgalpha", "0");
        krpano.set("hotspot[automatic-tour].bgcolor", "0x3f3f46");
        krpano.set("hotspot[automatic-tour].edge", "left");
        krpano.set("hotspot[automatic-tour].vcenter", "true");
        krpano.set("hotspot[automatic-tour].css", "color:#3F3F46; text-align:left;font-size:16px;font-weight:500;");


        krpano.call("addhotspot(at-pagination);");
        krpano.set("hotspot[at-pagination].type", "text");
        // krpano.set("hotspot[at-pagination].html", "[b]06 [/b]  / 12");
        krpano.set("hotspot[at-pagination].ath", ath);
        krpano.set("hotspot[at-pagination].atv", "0");
        krpano.set("hotspot[at-pagination].ox", "276px");
        krpano.set("hotspot[at-pagination].oy", "170px");
        krpano.set("hotspot[at-pagination].alpha", "0.0");
        krpano.set("hotspot[at-pagination].visible", "false");
        krpano.set("hotspot[at-pagination].keep", "true");
        krpano.set("hotspot[at-pagination].zorder", "10");
        krpano.set("hotspot[at-pagination].distorted", "true");
        krpano.set("hotspot[at-pagination].scale", "0.66");
        krpano.set("hotspot[at-pagination].width", "150px");
        krpano.set("hotspot[at-pagination].height", "48px");
        krpano.set("hotspot[at-pagination].bgroundedge", "0 0 12 12");
        krpano.set("hotspot[at-pagination].bgalpha", "0");
        krpano.set("hotspot[at-pagination].bgcolor", "0x3f3f46");
        krpano.set("hotspot[at-pagination].edge", "right");
        krpano.set("hotspot[at-pagination].vcenter", "true");
        krpano.set("hotspot[at-pagination].css", "color:#3F3F46; text-align:right;font-size:16px;font-weight:500;");

        krpano.call("addhotspot(at-play);");
        krpano.set("hotspot[at-play].type", "image");
        krpano.set("hotspot[at-play].url", "img/play.svg");
        krpano.set("hotspot[at-play].ath", ath);
        krpano.set("hotspot[at-play].atv", "0");
        krpano.set("hotspot[at-play].ox", "0px");
        krpano.set("hotspot[at-play].oy", "170px");
        krpano.set("hotspot[at-play].alpha", "0.0");
        krpano.set("hotspot[at-play].visible", "false");
        krpano.set("hotspot[at-play].keep", "true");
        krpano.set("hotspot[at-play].zorder", "10");
        krpano.set("hotspot[at-play].distorted", "true");
        krpano.set("hotspot[at-play].scale", "0.66");
        // krpano.set("hotspot[at-play].onclick", "js(auto_tour(true););");

        krpano.call("addhotspot(at-stop);");
        krpano.set("hotspot[at-stop].type", "image");
        krpano.set("hotspot[at-stop].url", "img/stop.svg");
        krpano.set("hotspot[at-stop].ath", ath);
        krpano.set("hotspot[at-stop].atv", "0");
        krpano.set("hotspot[at-stop].ox", "0px");
        krpano.set("hotspot[at-stop].oy", "170px");
        krpano.set("hotspot[at-stop].alpha", "0.0");
        krpano.set("hotspot[at-stop].visible", "false");
        krpano.set("hotspot[at-stop].keep", "true");
        krpano.set("hotspot[at-stop].zorder", "11");
        krpano.set("hotspot[at-stop].distorted", "true");
        krpano.set("hotspot[at-stop].scale", "0.66");
        // krpano.set("hotspot[at-stop].onclick", "js(auto_tour(false););");


        krpano.call("addhotspot(at-previous);");
        krpano.set("hotspot[at-previous].type", "image");
        krpano.set("hotspot[at-previous].url", "img/previous.svg");
        krpano.set("hotspot[at-previous].ath", ath);
        krpano.set("hotspot[at-previous].atv", "0");
        krpano.set("hotspot[at-previous].ox", "-46px");
        krpano.set("hotspot[at-previous].oy", "170px");
        krpano.set("hotspot[at-previous].alpha", "0.0");
        krpano.set("hotspot[at-previous].visible", "false");
        krpano.set("hotspot[at-previous].keep", "true");
        krpano.set("hotspot[at-previous].zorder", "10");
        krpano.set("hotspot[at-previous].distorted", "true");
        krpano.set("hotspot[at-previous].scale", "0.66");
        // krpano.set("hotspot[at-previous].onclick", "js(auto_tour(false););");
        // krpano.set("hotspot[at-previous].onclick", "js(switch_per_node('prev', 'per_three'););");

        krpano.call("addhotspot(at-next);");
        krpano.set("hotspot[at-next].type", "image");
        krpano.set("hotspot[at-next].url", "img/next.svg");
        krpano.set("hotspot[at-next].ath", ath);
        krpano.set("hotspot[at-next].atv", "0");
        krpano.set("hotspot[at-next].ox", "46px");
        krpano.set("hotspot[at-next].oy", "170px");
        krpano.set("hotspot[at-next].alpha", "0.0");
        krpano.set("hotspot[at-next].visible", "false");
        krpano.set("hotspot[at-next].keep", "true");
        krpano.set("hotspot[at-next].zorder", "10");
        krpano.set("hotspot[at-next].distorted", "true");
        krpano.set("hotspot[at-next].scale", "0.66");
        // krpano.set("hotspot[at-next].onclick", "js(switch_per_node('next', 'per_three'););");
        
        krpano.call("addhotspot(sc-image-center);");
        krpano.set("hotspot[sc-image-center].type", "image");
        // krpano.set("hotspot[sc-image-center].url", "assets/krpano/scenes/praha_3_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-center].ath", ath);
        krpano.set("hotspot[sc-image-center].atv", "0");
        krpano.set("hotspot[sc-image-center].width", "244px");
        krpano.set("hotspot[sc-image-center].ox", "0px");
        krpano.set("hotspot[sc-image-center].oy", "0px");
        krpano.set("hotspot[sc-image-center].alpha", "0.0");
        krpano.set("hotspot[sc-image-center].visible", "false");
        krpano.set("hotspot[sc-image-center].keep", "true");
        krpano.set("hotspot[sc-image-center].zorder", "10");
        krpano.set("hotspot[sc-image-center].distorted", "true");
        krpano.set("hotspot[sc-image-center].scale", "0.66");
        // krpano.set("hotspot[sc-image-center].onclick", "js(open_scene('praha_3'););");


        krpano.call("addhotspot(sc-image-left);");
        krpano.set("hotspot[sc-image-left].type", "image");
        // krpano.set("hotspot[sc-image-left].url", "assets/krpano/scenes/praha_0_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-left].ath", ath);
        krpano.set("hotspot[sc-image-left].atv", "0");
        krpano.set("hotspot[sc-image-left].width", "244px");
        krpano.set("hotspot[sc-image-left].ox", "-198px");
        krpano.set("hotspot[sc-image-left].oy", "0px");
        krpano.set("hotspot[sc-image-left].alpha", "0.0");
        krpano.set("hotspot[sc-image-left].visible", "false");
        krpano.set("hotspot[sc-image-left].keep", "true");
        krpano.set("hotspot[sc-image-left].zorder", "10");
        krpano.set("hotspot[sc-image-left].distorted", "true");
        krpano.set("hotspot[sc-image-left].scale", "0.66");
        // krpano.set("hotspot[sc-image-left].onclick", "js(open_scene('praha_0'););");

        krpano.call("addhotspot(sc-image-right);");
        krpano.set("hotspot[sc-image-right].type", "image");
        // krpano.set("hotspot[sc-image-right].url", "assets/krpano/scenes/praha_9_thumb_vr.jpg");
        krpano.set("hotspot[sc-image-right].ath", ath);
        krpano.set("hotspot[sc-image-right].atv", "0");
        krpano.set("hotspot[sc-image-right].width", "244px");
        krpano.set("hotspot[sc-image-right].ox", "198px");
        krpano.set("hotspot[sc-image-right].oy", "0px");
        krpano.set("hotspot[sc-image-right].alpha", "0.0");
        krpano.set("hotspot[sc-image-right].visible", "false");
        krpano.set("hotspot[sc-image-right].keep", "true");
        krpano.set("hotspot[sc-image-right].zorder", "10");
        krpano.set("hotspot[sc-image-right].distorted", "true");
        krpano.set("hotspot[sc-image-right].scale", "0.66");
        // krpano.set("hotspot[sc-image-right].onclick", "js(open_scene('praha_9'););");

        krpano.call("addhotspot(thumb-title-center);");
        krpano.set("hotspot[thumb-title-center].type", "text");
        krpano.set("hotspot[thumb-title-center].ath", ath);
        krpano.set("hotspot[thumb-title-center].atv", "0");
        krpano.set("hotspot[thumb-title-center].distorted", "true");
        krpano.set("hotspot[thumb-title-center].html", "Img #2");
        krpano.set("hotspot[thumb-title-center].ox", "0");
        krpano.set("hotspot[thumb-title-center].oy", "138px");
        krpano.set("hotspot[thumb-title-center].alpha", "0.0");
        krpano.set("hotspot[thumb-title-center].visible", "false");
        krpano.set("hotspot[thumb-title-center].keep", "true");
        krpano.set("hotspot[thumb-title-center].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-center].width", "244px");
        krpano.set("hotspot[thumb-title-center].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-center].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-center].zorder", "10");
        krpano.set("hotspot[thumb-title-center].scale", "0.66");

        krpano.call("addhotspot(thumb-title-left);");
        krpano.set("hotspot[thumb-title-left].type", "text");
        krpano.set("hotspot[thumb-title-left].ath", ath);
        krpano.set("hotspot[thumb-title-left].atv", "0");
        krpano.set("hotspot[thumb-title-left].distorted", "true");
        krpano.set("hotspot[thumb-title-left].html", "Img #1");
        krpano.set("hotspot[thumb-title-left].ox", "-198px");
        krpano.set("hotspot[thumb-title-left].oy", "138px");
        krpano.set("hotspot[thumb-title-left].alpha", "0.0");
        krpano.set("hotspot[thumb-title-left].visible", "false");
        krpano.set("hotspot[thumb-title-left].keep", "true");
        krpano.set("hotspot[thumb-title-left].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-left].width", "244px");
        krpano.set("hotspot[thumb-title-left].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-left].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-left].zorder", "10");
        krpano.set("hotspot[thumb-title-left].scale", "0.66");

        krpano.call("addhotspot(thumb-title-right);");
        krpano.set("hotspot[thumb-title-right].type", "text");
        krpano.set("hotspot[thumb-title-right].ath", ath);
        krpano.set("hotspot[thumb-title-right].atv", "0");
        krpano.set("hotspot[thumb-title-right].distorted", "true");
        krpano.set("hotspot[thumb-title-right].html", "Img #3");
        krpano.set("hotspot[thumb-title-right].ox", "198px");
        krpano.set("hotspot[thumb-title-right].oy", "138px");
        krpano.set("hotspot[thumb-title-right].alpha", "0.0");
        krpano.set("hotspot[thumb-title-right].visible", "false");
        krpano.set("hotspot[thumb-title-right].keep", "true");
        krpano.set("hotspot[thumb-title-right].bgcolor", "0x3f3f46");
        krpano.set("hotspot[thumb-title-right].width", "244px");
        krpano.set("hotspot[thumb-title-right].bgalpha", "0.6");
        krpano.set("hotspot[thumb-title-right].css", "color:#fafafa; font-size: 14px; text-align:center;");
        krpano.set("hotspot[thumb-title-right].zorder", "10");
        krpano.set("hotspot[thumb-title-right].scale", "0.66");

        krpano.call("addhotspot(image-center-empty);");
        krpano.set("hotspot[image-center-empty].type", "text");
        krpano.set("hotspot[image-center-empty].ath", ath);
        krpano.set("hotspot[image-center-empty].atv", "0");
        krpano.set("hotspot[image-center-empty].ox", "0px");
        krpano.set("hotspot[image-center-empty].oy", "0px");
        krpano.set("hotspot[image-center-empty].alpha", "0.0");
        krpano.set("hotspot[image-center-empty].visible", "false");
        krpano.set("hotspot[image-center-empty].keep", "true");
        krpano.set("hotspot[image-center-empty].bgalpha", "1");
        krpano.set("hotspot[image-center-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-center-empty].zorder", "10");
        krpano.set("hotspot[image-center-empty].distorted", "true");
        krpano.set("hotspot[image-center-empty].scale", "0.66");
        // krpano.set("hotspot[image-center-empty].onclick", "js(open_scene('praha_3'););");

        krpano.call("addhotspot(image-left-empty);");
        krpano.set("hotspot[image-left-empty].type", "text");
        krpano.set("hotspot[image-left-empty].ath", ath);
        krpano.set("hotspot[image-left-empty].atv", "0");
        krpano.set("hotspot[image-left-empty].ox", "-198px");
        krpano.set("hotspot[image-left-empty].oy", "0px");
        krpano.set("hotspot[image-left-empty].alpha", "0.0");
        krpano.set("hotspot[image-left-empty].visible", "false");
        krpano.set("hotspot[image-left-empty].keep", "true");
        krpano.set("hotspot[image-left-empty].bgalpha", "0");
        krpano.set("hotspot[image-left-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-left-empty].zorder", "10");
        krpano.set("hotspot[image-left-empty].distorted", "true");
        krpano.set("hotspot[image-left-empty].scale", "0.66");
        // krpano.set("hotspot[image-left-empty].onclick", "js(open_scene('praha_0'););");

        krpano.call("addhotspot(image-right-empty);");
        krpano.set("hotspot[image-right-empty].type", "text");
        krpano.set("hotspot[image-right-empty].ath", ath);
        krpano.set("hotspot[image-right-empty].atv", "0");
        krpano.set("hotspot[image-right-empty].ox", "198px");
        krpano.set("hotspot[image-right-empty].oy", "0px");
        krpano.set("hotspot[image-right-empty].alpha", "0.0");
        krpano.set("hotspot[image-right-empty].visible", "false");
        krpano.set("hotspot[image-right-empty].keep", "true");
        krpano.set("hotspot[image-right-empty].bgalpha", "0");
        krpano.set("hotspot[image-right-empty].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[image-right-empty].zorder", "10");
        krpano.set("hotspot[image-right-empty].distorted", "true");
        krpano.set("hotspot[image-right-empty].scale", "0.66");
        // krpano.set("hotspot[image-right-empty].onclick", "js(open_scene('praha_9'););");

        krpano.call("addhotspot(sc-previous);");
        krpano.set("hotspot[sc-previous].type", "image");
        krpano.set("hotspot[sc-previous].url", "img/sc-previous.svg");
        
        krpano.set("hotspot[sc-previous].ath", ath);
        krpano.set("hotspot[sc-previous].atv", "0");
        krpano.set("hotspot[sc-previous].ox", "-276px");
        krpano.set("hotspot[sc-previous].oy", "0px");
        krpano.set("hotspot[sc-previous].alpha", "0.0");
        krpano.set("hotspot[sc-previous].visible", "false");
        krpano.set("hotspot[sc-previous].keep", "true");
        krpano.set("hotspot[sc-previous].bgalpha", "0");
        krpano.set("hotspot[sc-previous].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[sc-previous].zorder", "10");
        krpano.set("hotspot[sc-previous].distorted", "true");
        krpano.set("hotspot[sc-previous].scale", "0.66");
        // krpano.set("hotspot[at-previous].onclick", "prevscene());");
        // krpano.set("hotspot[sc-previous].onclick", "js(switch_per_node('prev', 'per_one'););");


        krpano.call("addhotspot(sc-next);");
        krpano.set("hotspot[sc-next].type", "image");
        krpano.set("hotspot[sc-next].url", "img/sc-next.svg");
        
        krpano.set("hotspot[sc-next].ath", ath);
        krpano.set("hotspot[sc-next].atv", "0");
        krpano.set("hotspot[sc-next].ox", "276px");
        krpano.set("hotspot[sc-next].oy", "0px");
        krpano.set("hotspot[sc-next].alpha", "0.0");
        krpano.set("hotspot[sc-next].visible", "false");
        krpano.set("hotspot[sc-next].keep", "true");
        krpano.set("hotspot[sc-next].bgalpha", "1");
        krpano.set("hotspot[sc-next].bgborder", "2.0 0x3F3F46 1");
        krpano.set("hotspot[sc-next].zorder", "10");
        krpano.set("hotspot[sc-next].distorted", "true");
        krpano.set("hotspot[sc-next].scale", "0.66");
        // krpano.set("hotspot[at-next].onclick", "nextscene()");
        // krpano.set("hotspot[sc-next].onclick", "js(switch_per_node('next', 'per_one'););");
    }

    function remove_submenu_shortcuts() {
        if (krpano) {
            let elements = ['[sc-controls-bg','info-content', 'automatic-tour', 'at-pagination', 'at-play', 'at-stop', 'at-previous', 'at-next', 'sc-image-center', 'sc-image-left', 'sc-image-right', 'thumb-title-center', 'thumb-title-left', 'thumb-title-right', 'image-center-empty', 'image-left-empty', 'image-right-empty', 'sc-previous', 'sc-next', ];
            for (let index = 0; index < elements.length; index++) {
                krpano.call("removehotspot(" + elements[index] + ")");
            }
            
        }
    }

    function remove_global_menu_all_items() {
        remove_global_menu_attributes();
        remove_global_menu_floorplan();
        remove_global_menu_info();
        remove_global_menu_layers();
        remove_global_menu_model();
        remove_global_menu_settings();
        remove_global_menu_shop();
        remove_global_menu_shortcuts();
    }

    // vytvorenie hover akcie pre ikony VR menu
    function global_menu_add_hover($id) {
        if (krpano) {
            krpano.set("hotspot["+ $id + "].url", "img/" + $id + "_active.svg" );
            krpano.set("hotspot["+ $id + "-bg].bgborder", global_menu_icon_bg_border);
            krpano.set("hotspot["+ $id + "-bg].bgcolor", global_menu_text_bg_color);
            krpano.set("hotspot["+ $id + "-text].css", global_menu_text_css);
        }
    }

    // odstránenie hover akcie pre ikony VR menu
    function global_menu_remove_hover($id) {
        if (krpano) {
            //console.log(krpano.get("hotspot["+ $id + "]"));
            if (krpano.get("hotspot["+ $id + "]") != null) {
                krpano.set("hotspot["+ $id + "].url", "img/" + $id + ".svg" );
            }
            
            krpano.set("hotspot["+ $id + "-bg].bgborder", "2.0 0x3F3F46 0.2");
            krpano.set("hotspot["+ $id + "-bg].bgcolor", "0xFAFAFA");
            krpano.set("hotspot["+ $id + "-text].css", global_menu_text_css_normal);
        }
    }

    // akcia na prepínanie checkboxov
    function global_menu_toggle_checkbox($name) {
        ////console.log('togle');
        var krpano = document.getElementById("krpanoSWFObject");
        let url = krpano.get("hotspot[" + $name +"-check].url");
        switch (url) {
            case 'img/checkbox-on.svg' : 
                krpano.set("hotspot[" + $name +"-check].url", "img/checkbox-off.svg");  
            break;

            case 'img/checkbox-off.svg' : 
                krpano.set("hotspot[" + $name +"-check].url", "img/checkbox-on.svg");  
            break;
        }

        if ( 
            $name == 'sound' 
        ) {
            //krpano.call("sound[bgsnd].toggle()");
            let current_sound = krpano.get("sound[bgsnd].volume");
            let current_sound_int = krpano.get("sound[bgsnd_int].volume");
            //console.log(current_sound);
            switch (current_sound) {
                case 1:
                    krpano.set("sound[bgsnd].volume", "0.0");
                    break;
            
                default:
                    krpano.set("sound[bgsnd].volume", "0.5");
                    
                    break;
            }

            switch (current_sound_int) {
                case 1:
                    krpano.set("sound[bgsnd_int].volume", "0.0");
                    break;
            
                default:
                    krpano.set("sound[bgsnd_int].volume", "0.2");
                    break;
            }
        }

        if ( $name == 'advertisement' ) {
            switch (url) {
                case 'img/checkbox-on.svg' : 
                // krpano.call("autorotate.stop()");
                break;
        
                case 'img/checkbox-off.svg' : 
                // krpano.call("autorotate.start()");
                break;
            }
        }

        if ( 
            $name == 'hotspots' 
        ) {

            
            var hts_count = krpano.get("hotspot.count");
            for (let index = 0; index < hts_count; index++) {
                switch (url) {
                    case 'img/checkbox-on.svg' : 
                    krpano.set("hotspot[" + $name +"-check].url", "img/checkbox-off.svg");  
                            if (
                                krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                                krpano.get("hotspot[" + index +"].name").includes("info") ||
                                krpano.get("hotspot[" + index +"].name").includes("video")
                                
                                ) {
                                    var hts_name = krpano.get("hotspot[" + index +"].name");
                                    krpano.set("hotspot[" + index +"].alpha", "0.0");
                                    krpano.set("hotspot[" + index +"].visible", "false");
                                }
                    break;
            
                    case 'img/checkbox-off.svg' : 
                    if (
                        krpano.get("hotspot[" + index +"].name").includes("next-node") || 
                        krpano.get("hotspot[" + index +"].name").includes("info") ||
                        krpano.get("hotspot[" + index +"].name").includes("video")
                        
                        ) {
                            var hts_name = krpano.get("hotspot[" + index +"].name");
                            krpano.set("hotspot[" + index +"].alpha", "1.0");
                            krpano.set("hotspot[" + index +"].visible", "true");
                        }
                        krpano.set("hotspot[" + $name +"-check].url", "img/checkbox-on.svg");  
                    break;
                }
            }
        }
    }

let enabled_move_icon = true;

function menu_icon($value) {
    var krpano = document.getElementById("krpanoSWFObject");
    ////////console.log('hover');
    let hotspotID = "menu";
    switch ($value) {
        case 'true' : 
            enabled_move_icon = false;
            krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
        break;

        case 'false' : 
            enabled_move_icon = true;
        break;
    }
}
    
</script>

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";
</style>