<script>
    export let settings;
    export let userdata;
    let tmp = userdata;
    tmp = settings;

    import { loaded_components } from '../store.js';

    $loaded_components = [...$loaded_components, 'logo'];


    let blur_enabled = false;

    import { blur } from '../store.js';
    blur.subscribe(value => {
		  blur_enabled = value;
	});



</script>


{#each settings.components as {id, url, target, img}}
     {#if id === "Logo"}
        <div id="logo" class="logo" class:hide_blur="{blur_enabled === true}">
            {#if url != ''}
                <a href="{url}" target="{target}">
                    <img src="{userdata['base_path']}{img}" alt="logo">
                </a>
            {:else}
                <img src="{userdata['base_path']}{img}" alt="logo">
            {/if}
        </div>
     {/if}
{/each}

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";

    $logo-width: 140px;
    $logo-height: 40px;
    
    #logo {
        position: absolute;
        top: 16px;
        left: 50%;
        width: $logo-width;
        height: $logo-height;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        @include blur;
        cursor: pointer;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: auto;
            height: auto;
            max-width: calc(#{$logo-width} - 16px);
            max-height: calc(#{$logo-height} - 8px);
        }
    }
</style>