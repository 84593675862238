<script>
    import { t, locale, locales } from "../i18n.js";
    import { Link, navigate } from 'svelte-routing';

    

    function getProps({ href, isPartiallyCurrent, isCurrent }) {
        const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;

        // The object returned here is spread on the anchor element's attributes
        if (isActive) {
        return { class: "active" };
        }
        return {};
    }

</script>
<aside>
    <img src="../../assets/images/woow-logo-white.svg" alt="logo">
    <navigation>
        <h3>{$t("aside.dashboard")}</h3>
        <span>{$t("aside.dashboard.description")}</span>
        <Link class="link" to="/admin"><i class="fa-solid fa-desktop"></i>{$t("aside.dashboard")}</Link>
        <Link class="link" to="/statistic"><i class="fa-solid fa-chart-pie"></i>{$t("aside.statistic")}</Link>
        <Link class="link" to="/tour"><i class="fa-solid fa-arrow-up-right-from-square"></i>{$t("aside.show.tour")}</Link>
        <Link class="link" to="/clear-data"><i class="fa-solid fa-trash-can"></i>{$t("admin_components")["clear_data"]["admin_title"]}</Link>
        <Link class="link" to="/import-data"><i class="fa-solid fa-file-import"></i>{$t("admin_components")["import_data"]["admin_title"]}</Link>
    </navigation>
    <navigation>
        <h3>{$t("aside.scenes")}</h3>
        <span>{$t("aside.scenes.description")}</span>
        <Link class="link" to="/scenes"><i class="fa-solid fa-panorama"></i>{$t("aside.scene")}</Link>
        <Link class="link" to="/add-scene"><i class="fa-solid fa-screwdriver-wrench"></i>{$t("aside.scene.add")}</Link>
    </navigation>
    <navigation>
        <h3>{$t("aside.users")}</h3>
        <span>{$t("aside.users.description")}</span>
        <Link class="link" to="/users"><i class="fa-solid fa-users"></i>{$t("aside.user")}</Link>
        <Link class="link" getProps="{getProps}" to="/add-user"><i class="fa-solid fa-user-plus"></i>{$t("aside.user.add")}</Link>
    </navigation>
    <navigation>
        <h3>{$t("aside.components")}</h3>
        <span>{$t("aside.components.description")}</span>
        <Link class="link" to="/components"><i class="fa-solid fa-cubes"></i>{$t("components")["admin-title"]}</Link>
        <Link class="link" to="/components/logo"><i class="fa-solid fa-image"></i>{$t("components")["logo-title"]}</Link>
        <Link class="link" to="/components/shortcuts"><i class="fa-solid fa-images"></i>{$t("components")["shortcuts"]["admin-title"]}</Link>
        <Link class="link" to="/components/tour-settings"><i class="fa-solid fa-sliders"></i>{$t("components")["tour_settings"]["admin-title"]}</Link>
        <Link class="link" to="/components/share"><i class="fa-solid fa-share-nodes"></i>{$t("components")["share"]["admin-title"]}</Link>
        <Link class="link" to="/components/maps"><i class="fa-solid fa-location-dot"></i>{$t("components")["maps"]["admin-title"]}</Link>
        <Link class="link" to="/components/tour-info"><i class="fa-solid fa-circle-info"></i>{$t("components")["tourinfo"]["admin-title"]}</Link>
        <Link class="link" to="/components/floorplan"><i class="fa-solid fa-map-pin"></i>{$t("components")["floorplan"]["admin-title"]}</Link>
        <Link class="link" to="/components/merge-scenes"><i class="fa-solid fa-object-group"></i>{$t("components")["merge_scenes"]["admin-title"]}</Link>
        <Link class="link" to="/components/sounds"><i class="fa-solid fa-volume-high"></i>{$t("components")["sounds"]["admin-title"]}</Link>
    </navigation>
    <navigation>
        <h3>{$t("aside.settings")}</h3>
        <span>{$t("aside.settings.description")}</span>
        <Link class="link" to="/meta"><i class="fa-solid fa-users"></i>{$t("aside.meta")}</Link>
    </navigation>
</aside>

<style lang="scss">
    @import '../assets/scss/themes.scss';
    
    aside {
        grid-area: aside;
        background-color: $aside-background;
        color: $text-color;
        padding: 0px 16px;

        navigation {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: inherit;
        margin-top: 32px;
        position: relative;
            h3 {
                color: $primary-color;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 4px;
                padding-left: 16px;
            }
            span {
                color: inherit;
                font-weight: 300;
                padding-left: 16px;
                text-align: left;
                margin-bottom:16px;
                font-size: 12px;
            }
        }
    }

        img {
            margin: 16px auto;
            display: block;
        }


</style>