import { writable } from 'svelte/store';

if (
	localStorage.getItem('show_intro') == null
) {
	localStorage.setItem('show_intro', true);
}


export const show_intro = writable(localStorage.getItem('show_intro'));


import App from './App.svelte';




const app = new App({
	target: document.body,
	props: {
		//name
		//start_scene: 'scene_a3b_05'
	}
});
export default app;