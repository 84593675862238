<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
    import { panzoom } from "@douganderson444/panzoom-node";
    let zoomable, container;
    let style = ''; // show styling applied
    let scale = { value: 1 };
    let count = 10;
    let min = count;
    export let location;

    function handleZoom(e) {
      scale = e.detail.scale;
      style = zoomable.style.transform;
    }

    const grid = Array.from({ length: count }, (_, i) =>
      Array.from({ length: count }, (_, j) => ({ id: i * count + j }))
    );
    
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    export let userdata;
    let loading_data = true;
    let settings;
    let test = [];

    let filter_values = [];

    function update_filter_values() {
      
      filter_values = [];

      if (
        settings.components[9].data[settings.components[9]['active_floorplan']] != undefined || 
        settings.components[9].data[settings.components[9]['active_floorplan']] != null
      ) {
        let filter_by = settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'];
      
      for (let i = 0; i < settings.scenes.length; i++) {
        
        let value = settings.scenes[i][filter_by];
        
        filter_values.indexOf(value) === -1 && value != null ? filter_values.push(value) : console.log();
      }
      }
      
    }

    function update_filter_values_new_floorplan() {
    
      new_floorplan.id = settings.components[9].data.length;
      console.log('updatujem nový floorplan');
      filter_values = [];
      let filter_by = new_floorplan['filter_by'];
      new_floorplan.pins = [];
      for (let i = 0; i < settings.scenes.length; i++) {
        
        let value = settings.scenes[i][filter_by];
        
        if (
          value !== '' &&
          value !== null &&
          value === new_floorplan['filter_by_value']
          ) {
              console.log(value + ' | ' + new_floorplan['filter_by_value']);
              
              new_floorplan.pins = [...new_floorplan.pins, {
              id: new_floorplan.pins.length,
              x: 10,
              y : 10,
              target : settings.scenes[i].id
              }];
          }
        
        filter_values.indexOf(value) === -1 && value != null ? filter_values.push(value) : console.log();
      }
      console.log(new_floorplan);
    }

    function update_active_floorplan(e) {
      
      settings.components[9]['active_floorplan'] = e.target.value;
    }
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        update_filter_values();
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia tour boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia tour neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile(data) {
      let formData = new FormData();           
      formData.append("file", fileupload.files[0]);
      
      const res = await fetch(userdata['base_path'] + 'assets/php/saveLogo.php', {
      method: "POST", 
      body: formData
      });    

      if (res.ok) {
          const json = await res.json();
          let result = JSON.stringify(json);

          if (json.status == true) {
          addNotification({
                  text: "Obrázok bol úspešne uploadnutý!",
                  position: "top-center",
                  removeAfter: 4000,
                  type: "success",
              });  

              
              if (json.logo_url) {
                if (
                  data == 'new_floorplan'
                ) {
                  new_floorplan.image = 'assets/' + json.logo_url;  
                }

                else {
                  settings.components[9].data[settings.components[9]['active_floorplan']].image = 'assets/' + json.logo_url;
                }
                  save_settings();
              }
          }

          else {
          addNotification({
                  text: "Chyba : Obrázok nebol úspešne uploadnutý!",
                  position: "top-center",
                  removeAfter: 4000,
                  type: "danger",
              });  
          }
      }  
    }

    // upload loga na server
    async function importFile() {
      let formData = new FormData();           
      formData.append("file", fileimport.files[0]);
      
      const res = await fetch(userdata['base_path'] + 'assets/php/importData.php', {
      method: "POST", 
      body: formData
      });    

      

      if (res.ok) {
          const json = await res.json();
          let result = JSON.stringify(json);

          if (json.status == true) {
          addNotification({
                  text: "Logo bolo úspešne uploadnuté!",
                  position: "top-center",
                  removeAfter: 4000,
                  type: "success",
              });  

              
              if (json.logo_url) {
                
                fetchImportedData(userdata['base_path'] + 'assets/' + json.logo_url);
                
              }
          }

          else {
          addNotification({
                  text: "Chyba : Logo nebolo uploadnuté!",
                  position: "top-center",
                  removeAfter: 4000,
                  type: "danger",
              });  
          }
      } 
    }    

    async function fetchImportedData(url) {
      let importedData;
      const res = await fetch(
        url,
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        importedData = json;

        for (let index = 0; index < importedData.length; index++) {
          settings.components[9].data[index] = importedData[index];  
          if (
            index == 0  
          ) {
            settings.components[9]['active_floorplan'] = index;
            
          }  
        }

        
        
        //settings.components[9].data[settings.components[9]['active_floorplan']].pins = importedData.pins;

        
        
        save_settings();
        
        return json;
      } else {
        
      }
    }

    let elemt;

    function dragElement(elmnt) {
      
      
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        var cusid_ele = document.getElementsByClassName('pin');
        for (var i = 0; i < cusid_ele.length; ++i) {
            var item = cusid_ele[i];  
            item.classList.remove("active");
        }




        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
        
      }

      function elementDrag(e) {
        var cusid_ele = document.getElementsByClassName('pin');
        for (var i = 0; i < cusid_ele.length; ++i) {
          
            var item = cusid_ele[i];  
            if (i != active_pin ) {
              item.classList.remove("active");
            }
            
        }


        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

        elmnt.classList.add("active");
        
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
        save_pins();
      }

      

      
      
    }

    function save_pins() {
          if (settings != null){
          let pins = settings.components[9].data[settings.components[9]['active_floorplan']].pins;
          let pins_length = pins.length;
          for (let i = 0; i < pins_length; i++) {
            let current_pin = document.getElementById("pin_" + pins[i].id);
            if (
              current_pin != undefined &&
              current_pin != null
            ) {
              pins[i].x = current_pin.style.left.replace('px', '');
              pins[i].y = current_pin.style.top.replace('px', '');
            }
            
          }

          save_settings();
          
        }
      }

    let active_pin = -1;
    let showing = 0;

    function select_active_pin () {
      
      let pins = settings.components[9].data[settings.components[9]['active_floorplan']].pins;
      let pins_length = pins.length;
      for (let i = 0; i < pins_length; i++) {
          let current_pin = document.getElementById("pin_" + pins[i].id);
            if (
              pins[i].id == active_pin
            ) {
              current_pin.classList.add("active");
            }

            else {
              current_pin.classList.remove("active");
            }       
          }     
    }

    function remove_pin() {
      console.log(settings.components[9].data[settings.components[9]['active_floorplan']].pins[active_pin]);
      settings.components[9].data[settings.components[9]['active_floorplan']].pins = settings.components[9].data[settings.components[9]['active_floorplan']].pins.filter((m) => m.id !== active_pin);
      save_pins();
      showing = 0;
    }

    let new_floorplan = {
      id : 999,
      title : '',
      image : "assets/img/floorplan.svg",
      filter_by : 'none',
      filter_by_value : null,
      pins_title : "order", 
      pins : [
        {}
      ]
    };

    function add_new_floorplan() {
      if (
        new_floorplan['filter_by'] == 'none'
      ) {
        new_floorplan.pins = [];
        for (let i = 0; i < settings.scenes.length; i++) {
          
          new_floorplan.pins = [...new_floorplan.pins, {
            id : new_floorplan.pins.length,
            x: 10,
            y: 10,
            target: settings.scenes[i].id
          }];
        }
      }

      let tmp_id = 0;
      for (let index = 0; index < settings.components[9].data.length; index++) {
        if (
          settings.components[9].data[index].id > tmp_id
        ) {
           tmp_id = settings.components[9].data[index].id;
        }
        
      }

      new_floorplan.id = tmp_id + 1;
      console.log(new_floorplan);
      settings.components[9].data = [...settings.components[9].data, new_floorplan];
      settings.components[9]['active_floorplan'] = new_floorplan.id;
      showing = 0;
      save_settings();
    }

    function add_new_pin() {
      
      let max = -1;
      for (let index = 0; index < settings.components[9].data[settings.components[9]['active_floorplan']].pins.length; index++) {
        if (
          settings.components[9].data[settings.components[9]['active_floorplan']].pins[index].id > max
        ) { 
          max = settings.components[9].data[settings.components[9]['active_floorplan']].pins[index].id;
        }
        
      }
      settings.components[9].data[settings.components[9]['active_floorplan']].pins = [...settings.components[9].data[settings.components[9]['active_floorplan']].pins, {
        id: max + 1,
        x: 10,
        y : 10,
        target : null
      }];
      save_settings();
      active_pin = max + 1;      
      showing = 1;
    }

    function remove_floorplan() {
      console.log('a_f : ' +  settings.components[9]['active_floorplan']);
      settings.components[9].data = settings.components[9].data.filter(value => value.id !== settings.components[9]['active_floorplan']); 
      settings.components[9]['active_floorplan'] = 0;
      showing = 0;
      save_settings();
    }
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}
    
    <main in:fade>
      
      <h1>{$t("components")['floorplan']['admin-title']}</h1>
      <div class="fp-buttons">
        <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button> 
        {#if showing !== 2}
          <button class="add-fp" name="add_floorplan" on:click={() => showing = 2}><i class="fa-solid fa-plus"></i>{$t("components")['floorplan']['add-floorplan']}</button>    
          <button class="remove-fp" name="remove_floorplan" 
            on:click={() => remove_floorplan()}><i class="fa-solid fa-trash-can"></i>{$t("components")['floorplan']['remove-floorplan']}</button>    
        {/if}   
        
        {#if showing == 0}
          <button class="add-pin" name="add_pin" 
            on:click={() => add_new_pin()}
            
            ><i class="fa-solid fa-plus"></i>{$t("components")['floorplan']['add-pin']}</button>    
        {/if}  
      </div>
      
      
        <div id="floorplan-container">
          <div class="floorplan-image container" bind:this={container}>
            <div class="zoomable flexbox" bind:this={zoomable} use:panzoom on:zoomed={handleZoom}>
              {#if showing === 2 && settings.components[9].data[settings.components[9]['active_floorplan']] != undefined}
                <img src="{userdata['base_path'] + new_floorplan.image}" class="img" alt="logo" name="logo">

                {:else if settings.components[9].data[settings.components[9]['active_floorplan']] != undefined}
                  <img src="{userdata['base_path'] + settings.components[9].data[settings.components[9]['active_floorplan']].image}" class="img" alt="logo" name="logo">
                {/if}

              {#if showing !== 2 && settings.components[9].data[settings.components[9]['active_floorplan']] != undefined }
              {#each settings.components[9].data[settings.components[9]['active_floorplan']].pins as pin, i}
              <div id="pin_{pin.id}" class="pin" class:active="{active_pin === pin.id}" style="left: {pin.x}px; top: {pin.y}px;" 
                on:mouseenter="{() => dragElement(document.getElementById("pin_" + pin.id))}"
                on:click="{() => active_pin = pin.id}"
                on:click="{() => showing = 1}"
                on:click="{() => select_active_pin()}"
                
                >
                {#if pin.target != null}
                
                {#each settings.scenes as scene, i}
                   {#if scene.id === pin.target}
                     {scene.name}
                   {/if}
                {/each}

                {:else}
                  ?
                {/if}
                
                
              </div>   
              {/each}
              {/if}
              
              
            </div>
          </div>
          
          <div class="floorplan-data">
            <!-- buttony -->
            
            {#if showing === 0}  
            <div class="component">

              <!-- Voľba floorlanu -->
              <label for="floorplan-id">{$t("components")['floorplan']['floorplan-id']}</label>
              <select name="floorplan-id" id="floorplan-id" bind:value="{settings.components[9]['active_floorplan']}" on_change="{(e) => update_active_floorplan(e)}">
                {#each settings.components[9].data as floorplan}
                  <option value="{floorplan.id}">{floorplan.title}</option>
                {/each}
              </select>
            </div>

            {#if settings.components[9].data[settings.components[9]['active_floorplan']] != undefined}
              <div class="component">
                <label for="filter-by">{$t("components")['floorplan']['filter-by']}</label>
                  <select name="filter-by" bind:value="{settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by']}" on:change="{ () => update_filter_values()}">
                      <option value="none">{$t("components")['floorplan']['filter-none']}</option>
                      <option value="custom_field_1">{$t('scenes.settings.cf1')}</option>
                      <option value="custom_field_2">{$t('scenes.settings.cf2')}</option>
                      <option value="custom_field_3">{$t('scenes.settings.cf3')}</option>
                      <option value="custom_field_4">{$t('scenes.settings.cf4')}</option>
                      <option value="custom_field_5">{$t('scenes.settings.cf5')}</option>
                      <option value="custom_field_6">{$t('scenes.settings.cf6')}</option>
                      <option value="custom_field_7">{$t('scenes.settings.cf7')}</option>
                      <option value="custom_field_8">{$t('scenes.settings.cf8')}</option>
                      <option value="custom_field_9">{$t('scenes.settings.cf9')}</option>
                      <option value="custom_field_10">{$t('scenes.settings.cf10')}</option>
      
                  </select>
              </div>   
            {/if}
            

            {#if settings.components[9].data[settings.components[9]['active_floorplan']] != undefined && settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != 'none' }
              <!-- filtrovať podľa poľa -->
              <div class="component">
                <label for="filter_by_value">{$t("components")['floorplan']['filter-by-value']}</label>
                  <select name="filter_by_value" bind:value="{settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by_value']}">
                    {#each filter_values as value}
                    
                      <option value="{value}">{value}</option>
                    {/each}
                  </select>
              </div>

              <div class="component">
                <label for="pins-title">{$t("components")['floorplan']['pins-title']}</label>
                  <select name="pins-title" bind:value="{settings.components[9].data[settings.components[9]['active_floorplan']]['pins-title']}" on:change="{ () => update_filter_values()}">
                    <option value="number">{$t("components")['shortcuts']['title-number']}</option>
                    <option value="title">{$t("components")['shortcuts']['title-title']}</option>

                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_1"}
                      <option value="custom_field_1">{$t('scenes.settings.cf1')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_2"}
                      <option value="custom_field_2">{$t('scenes.settings.cf2')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_3"}
                      <option value="custom_field_3">{$t('scenes.settings.cf3')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_4"}
                      <option value="custom_field_4">{$t('scenes.settings.cf4')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_5"}
                      <option value="custom_field_5">{$t('scenes.settings.cf5')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_6"}
                      <option value="custom_field_6">{$t('scenes.settings.cf6')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_7"}
                      <option value="custom_field_7">{$t('scenes.settings.cf7')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_8"}
                      <option value="custom_field_8">{$t('scenes.settings.cf8')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_9"}
                      <option value="custom_field_9">{$t('scenes.settings.cf9')}</option>
                    {/if}
                    {#if settings.components[9].data[settings.components[9]['active_floorplan']]['filter_by'] != "custom_field_10"}
                      <option value="custom_field_10">{$t('scenes.settings.cf10')}</option>
                    {/if}
                  </select>
              </div>   
            {/if}
            
            <div class="upload">
              <label for="pins-title">{$t("components")['floorplan']['upload-image']}</label>  
              <input id="fileupload" type="file" name="fileupload" />
              <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
            </div>

            <div class="upload">
              <label for="pins-title">{$t("components")['floorplan']['import-file']}</label>  
              <input id="fileimport" type="file" name="fileimport" />
              <button id="import-button" on:click="{() => importFile()}"> Import </button>
            </div>
            {/if}  

            {#if showing === 1}
            <button class="pin-back" name="back" on:click={() => showing = 0}><i class="fa-solid fa-arrow-left"></i>{$t("back")}</button>    
            <button class="pin_remove" name="remove" 
              on:click={() => remove_pin()}
              on:click={() => showing = 0}
              
              ><i class="fa-solid fa-trash-can"></i>{$t("delete")}</button>    
              
              {#each settings.components[9].data[settings.components[9]['active_floorplan']].pins as pin}
              {#if pin.id === active_pin}
                <!-- Pozícia pinu na osi X -->
                <div class="component">
                   <label for="pinx">{$t("components")['floorplan']['pin-x']}:</label>
                   <input type="number" name="pinx" bind:value="{pin.x}">
                </div>  
                <!-- Pozícia pinu na osi Y -->
                <div class="component">
                  <label for="piny">{$t("components")['floorplan']['pin-y']}:</label>
                  <input type="number" name="piny" bind:value="{pin.y}">
                </div>

                <!-- Pozícia pinu na osi Y -->
                <div class="component">
                  <label for="title">{$t("components")['floorplan']['pin-title']}:</label>
                  <input type="text" name="text" bind:value="{pin.title}">
                </div>

                <!-- odkaz pinu na panorámu -->
                <div class="component">
                  <label for="pin-target">{$t("components")['floorplan']['pin-target']}:</label>
                  <select name="pin-target" bind:value="{pin.target}">
                    {#each settings.scenes as scene}
                        <option value="{scene.id}">{scene.id}</option>
                    {/each}
                  </select>
                </div>
                {/if}
              {/each}
                
              

              
              
            {/if}

            <!-- pridanie nového floorplanu -->
            {#if showing === 2}
            <button class="new-fp-back" name="back" 
              on:click={() => showing = 0}><i class="fa-solid fa-floppy-disk"></i>{$t("back")}</button>    
            <button class="new-fp-add" name="add_floorplan" 
              on:click={() => add_new_floorplan()}
              on:click={() => showing = 0}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['floorplan']['save-floorplan']}</button>   


              
              <div class="component">
                <label for="floorplan-title">{$t("components")['floorplan']['floorplan-title']}:</label>
                <input type="text" name="floorplan-title" bind:value="{new_floorplan.title}">
              </div>

              <div class="component">
                <label for="filter-by">{$t("components")['floorplan']['filter-by']}</label>
                  <select name="filter-by" bind:value="{new_floorplan['filter_by']}" on:change="{ () => update_filter_values_new_floorplan()}">
                      <option value="none">{$t("components")['floorplan']['filter-none']}</option>
                      <option value="custom_field_1">{$t('scenes.settings.cf1')}</option>
                      <option value="custom_field_2">{$t('scenes.settings.cf2')}</option>
                      <option value="custom_field_3">{$t('scenes.settings.cf3')}</option>
                      <option value="custom_field_4">{$t('scenes.settings.cf4')}</option>
                      <option value="custom_field_5">{$t('scenes.settings.cf5')}</option>
                      <option value="custom_field_6">{$t('scenes.settings.cf6')}</option>
                      <option value="custom_field_7">{$t('scenes.settings.cf7')}</option>
                      <option value="custom_field_8">{$t('scenes.settings.cf8')}</option>
                      <option value="custom_field_9">{$t('scenes.settings.cf9')}</option>
                      <option value="custom_field_10">{$t('scenes.settings.cf10')}</option>
      
                  </select>
              </div> 

              {#if new_floorplan['filter_by'] != 'none'}
                <!-- filtrovať podľa poľa -->
                <div class="component">
                  <label for="filter_by_value">{$t("components")['floorplan']['filter-by-value']}</label>
                    <select name="filter_by_value" bind:value="{new_floorplan['filter_by_value']}" on:change="{ () => update_filter_values_new_floorplan()}">
                      {#each filter_values as value}
                        <option value="{value}">{value}</option>
                      {/each}
                    </select>
                </div>

                <div class="component">
                  <label for="pins-title">{$t("components")['floorplan']['pins-title']}</label>
                    <select name="pins-title" bind:value="{new_floorplan['pins-title']}" on:change="{ () => update_filter_values_new_floorplan()}">
                      <option value="number">{$t("components")['shortcuts']['title-number']}</option>
                      <option value="title">{$t("components")['shortcuts']['title-title']}</option>

                      {#if new_floorplan['filter_by'] != "custom_field_1"}
                        <option value="custom_field_1">{$t('scenes.settings.cf1')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_2"}
                        <option value="custom_field_2">{$t('scenes.settings.cf2')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_3"}
                        <option value="custom_field_3">{$t('scenes.settings.cf3')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_4"}
                        <option value="custom_field_4">{$t('scenes.settings.cf4')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_5"}
                        <option value="custom_field_5">{$t('scenes.settings.cf5')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_6"}
                        <option value="custom_field_6">{$t('scenes.settings.cf6')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_7"}
                        <option value="custom_field_7">{$t('scenes.settings.cf7')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_8"}
                        <option value="custom_field_8">{$t('scenes.settings.cf8')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_9"}
                        <option value="custom_field_9">{$t('scenes.settings.cf9')}</option>
                      {/if}
                      {#if new_floorplan['filter_by'] != "custom_field_10"}
                        <option value="custom_field_10">{$t('scenes.settings.cf10')}</option>
                      {/if}
                    </select>
                </div>   
              {/if}
              <div class="upload">
                <label for="pins-title">{$t("components")['floorplan']['upload-image']}</label>  
                <input id="fileupload" type="file" name="fileupload" />
                <button id="upload-button" on:click="{() => uploadFile('new_floorplan')}"> Upload </button>
              </div>
            {/if}
          </div>  
          
        </div>
      

      
      

    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";
    @import "../../assets/scss/fonts.scss";

    .pin {
      position: absolute;
      bottom: 1px;
      left: 1px;
      z-index: 999999999;
      cursor: all-scroll;
      display: flex;
      justify-content: center;
      align-items: center;


      @include rubik-m10;
      color: var(--secondary-color);
      width: 22px;
      height: 22px;
      background-color: var(--primary-color);
      border-radius: 50%;
      border: 2px solid var(--secondary-color);
      box-shadow: 0 0 6px 1px var(--primary-color-light60);
      transition: box-shadow 0.25s ease;

      &.active {
        background-color: var(--main-color);
        box-shadow: 0 0 6px 1px var(--main-color-light60),
        0 0 0 8px var(--main-color-light20);
        -webkit-animation: pulse 2s infinite;
		        animation: pulse 2s infinite;

          @-webkit-keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 var(--main-color);
            }
          
            70% {
              box-shadow: 0 0 0 10px transparent;
            }
          
            100% {
              box-shadow: 0 0 0 0 transparent;
            }
          }


          &::before {
            content: '';
            position: absolute;
            background: transparent;
            width: 12PX;
            height: 0PX;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 40px solid transparent;
            border-top: 40px solid var(--main-color-light20);
            border-radius: 4px;
            transform: rotate(0deg);
            z-index: -1;
		}
      }

      &:hover:not(.active) {
			box-shadow: 0 0 6px 1px var(--primary-color-light60),
			  0 0 0 8px var(--primary-color-light20);
		  }

      
    }

    .fp-buttons{
      display: flex;
      justify-content: center;
      button {
        margin: 10px 5px;
        &.add-fp {
          background-color: $info;
        }
        &.remove-fp {
          background-color: $danger;
        }
        &.add-pin {
          background-color: $info;
        }
      }
    }

    #floorplan-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      min-height: 500px;


      .floorplan-image {
        width: 800px;
        height: 800px;
        position: relative;
        /* touch-action: none;  its set by the directive :) */

        img, .img {
          width: 100%;
        }

        .zoomable {
          border: 4px dashed blue;
          height: 800px !important;
          width: 800px !important;
          position: relative;
        }
        .flexbox {
          display: flex;
          flex-wrap: nowrap;
          align-content: stretch;
          justify-content: space-evenly;
          align-items: stretch;
        }

        
      }

      .floorplan-data {
        width: 420px;
          button {
            &.pin_remove {
              background-color: $danger;
            }
            &.pin-back {
              background-color: $primary-color;
            }
            &.new-fp-back {
              background-color: $primary-color;
            }
          
          }
          .upload {
            label{
              margin-right: 38px;
              width: 100px;
            }
          }
      }

    }
  </style>
  