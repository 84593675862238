<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia loga boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia loga neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);

        
        const res = await fetch(userdata['base_path'] + 'assets/php/saveLogo.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Logo bolo úspešne uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                
                if (json.logo_url) {
                    settings.components[0].img = 'assets/'+json.logo_url;
                    
                    save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Logo nebolo uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

</script>
  
  <div id="wrapper">
    <Aside />

    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("components")['logo-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      
        <div class="component">
          <label for="url">{$t("components")['logo-url']}</label>
          <input type="text" name="url" bind:value={settings.components[0].url}>
        </div>   
        <div class="component">
            <label for="target">{$t("components")['logo-target']}</label>
            <select bind:value="{settings.components[0].target}">
                <option value="_blank">{$t("components")['logo-target-blank']}</option>
                <option value="_self">{$t("components")['logo-target-self']}</option>
            </select>
        </div>   
        <div class="component">
            <label for="logo">{$t("components")['logo-title']}</label> 
            <img src="{userdata['base_path'] + settings.components[0].img}" alt="logo" name="logo">
        </div>
        <div class="upload">
          <input id="fileupload" type="file" name="fileupload" />
          <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
        </div>
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>