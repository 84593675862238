<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia Shortcuts boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia Shortcuts neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("components")["shortcuts"]['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
        <div class="component">
          <label for="scenes">{$t("components")['shortcuts']['scenes-short-description']}</label>
          <select name="scenes" bind:value="{settings.components[4].scenes}">
            <option class="opt-item" value="all">{$t("components")['shortcuts']['scenes-all']}</option>
            <option value="tag">{$t("components")['shortcuts']['scenes-tag']}</option>
          </select>
        </div>   
        <div class="component">
          <label for="titles">{$t("components")['shortcuts']['titles-short-description']}</label>
            <select name="titles" bind:value="{settings.components[4].titles}">
                <option value="number">{$t("components")['shortcuts']['title-number']}</option>
                <option value="title">{$t("components")['shortcuts']['title-title']}</option>
                <option value="custom_field_1">{$t("components")['shortcuts']['title-cf-1']}</option>
                <option value="custom_field_2">{$t("components")['shortcuts']['title-cf-2']}</option>
                <option value="custom_field_3">{$t("components")['shortcuts']['title-cf-3']}</option>
                <option value="custom_field_4">{$t("components")['shortcuts']['title-cf-4']}</option>
                <option value="custom_field_5">{$t("components")['shortcuts']['title-cf-5']}</option>
                <option value="custom_field_6">{$t("components")['shortcuts']['title-cf-6']}</option>
                <option value="custom_field_7">{$t("components")['shortcuts']['title-cf-7']}</option>
                <option value="custom_field_8">{$t("components")['shortcuts']['title-cf-8']}</option>
                <option value="custom_field_9">{$t("components")['shortcuts']['title-cf-9']}</option>
                <option value="custom_field_10">{$t("components")['shortcuts']['title-cf-10']}</option>

            </select>
        </div>   
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>