<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia loga boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia loga neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);

        
        
        const res = await fetch(userdata['base_path'] + 'assets/php/saveLogo.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Logo bolo úspešne uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  

                
                if (json.logo_url) {
                  settings.components[8].data.img = json.logo_url;
                  // settings.components[8].scenes[settings.components[8].admin_info].img = json.logo_url;
                    save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Logo nebolo uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

    function check_url($link) {
      if ($link.includes('src')) {
        console.log('áno');

        let checked_url = $link.slice(
          $link.indexOf('src="') + 5,
          $link.lastIndexOf('" width'),
        );

        settings.components[7].url = checked_url;
        console.log(checked_url);

      }

      else {
        console.log('nie');
      }
      
    }
    
    // zapnutie / vypnutie modalu
  import { modal } from '../../store.js';

let show_modal;
modal.subscribe(value => {
  show_modal = value;
});

let Modal;
let modal_type = 'tour-info';

import { onMount } from 'svelte';

const sleep = ms => new Promise(f => setTimeout(f, ms));

onMount(async () => {
  //await sleep(1000); // simulate network delay
  Modal = (await import('../../tour_components/Modal.svelte')).default
});

function save_global_info(){

}
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}

    <main in:fade>
      <h1>{$t("components")['tourinfo']['admin-title']}</h1>

      <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" position="relative"/>  

      <!-- <div class="component">
        <label for="scenes">{$t("components")['tourinfo']['all']}</label>
        <select bind:value="{settings.components[8].admin_info}">
            <option value="global">{$t("components")['tourinfo']["global"]}</option>
          {#each settings.scenes as scene} 
            <option value="{scene.id}">{scene.id}</option>
          {/each}
            
        </select>
    </div>   
      <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" position="relative" global="yes"/>   -->
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>

      <!-- Title tour info -->
      <div class="component">
        <label for="title">{$t("components")['tourinfo']['title']}</label>
        <input type="text" name="title" bind:value="{settings.components[8].data.title}">
        <!-- <input type="text" name="title" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].title}"> -->
      </div>  

      <!-- Title tour headline -->
      <div class="component">
        <label for="headline">{$t("components")['tourinfo']['headline']}</label>
        <textarea name="headline" bind:value="{settings.components[8].data.headline}"/>
        <!-- <textarea name="headline" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].headline}"/> -->
      </div>  

      <!-- upload obrázka -->
      <div class="component upload">
        <label for="fileupload">{$t("components")['tourinfo']['image']}</label><br>
        <input id="fileupload" type="file" name="fileupload" />
        <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
      </div>

      <!-- Title tour subtitle -->
      <div class="component">
        <label for="subtitle">{$t("components")['tourinfo']['subtitle']}</label>
        <input type="text" name="subtitle" bind:value="{settings.components[8].data.subtitle}"/>
        <!-- <input type="text" name="subtitle" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].subtitle}"/> -->
      </div>  

      <!-- Title tour subtitle -->
      <div class="component">
        <label for="subtitle">{$t("components")['tourinfo']['content']}</label>
        <textarea name="content" bind:value="{settings.components[8].data.content}"/>
        <!-- <textarea name="content" bind:value="{settings.components[8].scenes[settings.components[8].admin_info].content}"/> -->
      </div>        


    </main>
    <Header />
    
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>