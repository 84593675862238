<script>

import { shortcuts } from '../store.js';


    // These values are bound to properties of the video
	let time = 0;
	let duration;
	let paused = true;

	let showControls = true;
	let showControlsTimeout;

	// Used to track time of last mouse down event
	let lastMouseDown;

	function handleMove(e) {
		// Make the controls visible, but fade out after
		// 2.5 seconds of inactivity
		clearTimeout(showControlsTimeout);
		showControlsTimeout = setTimeout(() => showControls = false, 2500);
		showControls = true;

		if (!duration) return; // video not loaded yet
		if (e.type !== 'touchmove' && !(e.buttons & 1)) return; // mouse not down

		const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
		const { left, right } = this.getBoundingClientRect();
		time = duration * (clientX - left) / (right - left);
	}

	// we can't rely on the built-in click event, because it fires
	// after a drag — we have to listen for clicks ourselves
	function handleMousedown(e) {
		lastMouseDown = new Date();
	}

	function handleMouseup(e) {
		if (new Date() - lastMouseDown < 300) {
			if (paused) e.target.play();
			else e.target.pause();
		}
	}

	function format(seconds) {
		if (isNaN(seconds)) return '...';

		const minutes = Math.floor(seconds / 60);
		seconds = Math.floor(seconds % 60);
		if (seconds < 10) seconds = '0' + seconds;

		return `${minutes}:${seconds}`;
	}

    export let settings;

    //console.log(settings);
    export let position;

    export let prefix;


    import { modal } from '../store.js';
    import { active_scene } from '../store.js';
    import { blur } from '../store.js';
      
    let tmp = settings;

    // url pre iframe
    export let url;

    export let modal_type_tmp;

    //console.log('modal_type je : ' + modal_type_tmp);

    export let id;
    export let current_scene;
    //console.log('current : ' + current_scene);

    let is_modal = false;

    
    $: {
      is_modal;
      
      switch (is_modal) {
        case true : 
          shortcuts.update(n => false);
          blur.update(n => true);
        break;

        default : 
          blur.update(n => false);
        break;
      }
    }

    modal.subscribe(value => {
		  is_modal = value;
      
	  });

    export let global;

    // Názov modálneho okna
    export let title;
    //console.log('pozor ! : ' + title);

    // filter pre iframe google mapy
    export let filter;
    

    if (
      filter == undefined
    ) {
      filter = false;
    }




    import { fade, scale } from 'svelte/transition';

    const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);

    let a_scene;
  	active_scene.subscribe(value => {
		  a_scene = value;
      // //console.log('scéna : ' + a_scene);
      
	});


</script>


<div id="modal" 
    class:relative="{position === 'relative'}" 
    class:text="{modal_type_tmp === 'info-v3' || modal_type_tmp === 'infopanel-v3' || modal_type_tmp === 'tour-info' }"
    class:video="{modal_type_tmp === 'video-v1' || modal_type_tmp === 'video-v2' || modal_type_tmp === 'videopanel-v2' }"
    transition:animate>
    <div class="close" on:click="{() => modal.update(n => !n)}" />
            {#if modal_type_tmp == 'google-map'}
              <div class="title">{title}</div>
                <div class="content {modal_type_tmp}">
                  <iframe title="{title}" src="{url}" style="border:0;" allowfullscreen="" loading="lazy" class:filter="{filter === true}"/>  
                </div>
            {/if}
            {#if modal_type_tmp == 'tour-info'}
              <div class="title">{settings.components[8].data.title}</div>
              <div class="content {modal_type_tmp}">
                <h1 class="content-title">
                  {@html settings.components[8].data.headline}    
                </h1>
                <div class="grid-img-left">
                  <div class="image">
                    <img src="{settings.configuration.settings.base_path}assets/{settings.components[8].data.img}" alt="img">
                  </div>
                  <div class="subtitle">
                    {@html settings.components[8].data.subtitle}
                  </div>
                  <div class="description">
                    <p>
                      {@html settings.components[8].data.content}
                    </p>
                  </div>
                </div>
              </div>
            {/if}
            {#if modal_type_tmp == 'info-v654641'}
              <div class="title">{@html settings.title }</div>
              <div class="content {modal_type_tmp}">
                <h1 class="content-title">
                  {@html settings.data.headline}
                </h1>
                <div class="grid-img-left">
                  <div class="image">
                    <img src="{prefix}assets/{settings.data.img}" alt="img">
                  </div>
                  <!-- <div class="subtitle">
                    {@html settings.data.subtitle}
                  </div> -->
                  <div class="description">
                    <p>
                      {@html settings.data.content}
                    </p>
                  </div>
                </div>           
              </div>
            {/if}

            {#if modal_type_tmp == 'infopanel-v1'}
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                    {#if hotspot.name == id || 
                          hotspot.id == id
                          }
                      <div class="title">{@html hotspot.title}</div>
                    <div class="content {modal_type_tmp}">
                      <h1 class="content-title">
                        {@html hotspot.data.headline}    
                    </h1>
                    <div class="grid-img-left">
                      <div class="image">
                        <img src="{settings.configuration.settings.base_path}assets/{hotspot.data.img}" alt="img">
                      </div>
                      <!-- <div class="subtitle">
                        {@html hotspot.data.subtitle}
                      </div> -->
                      <div class="description">
                        <p>
                          {@html hotspot.data.content}
                        </p>
                      </div>
                    </div>
                </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}


            {#if modal_type_tmp == 'tour_ths' || modal_type_tmp == 'info-v1'}
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                    {#if hotspot.name == id || 
                          hotspot.id == id
                          }
                      <div class="title">{@html hotspot.title}</div>
                    <div class="content tour-info">
                      <h1 class="content-title">
                        {@html hotspot.data.headline}    
                    </h1>
                    <div class="grid-img-left">
                      <div class="image">
                        <img src="{settings.configuration.settings.base_path}assets/{hotspot.data.img}" alt="img">
                      </div>
                      <!-- <div class="subtitle">
                        {@html hotspot.data.subtitle}
                      </div> -->
                      <div class="description">
                        <p>
                          {@html hotspot.data.content}
                        </p>
                      </div>
                    </div>
                </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}

            {#if modal_type_tmp == 'info-v2' || modal_type_tmp == 'infopanel-v2'}
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                  {#if hotspot.name == id || 
                    hotspot.id == id
                    }
                      <div class="title">{@html hotspot.data.title}</div>
                    <div class="content {modal_type_tmp} text-only">
                      <!-- <h1 class="content-title text-only">
                        {@html hotspot.data.headline}    
                    </h1>
                    <div class="subtitle text-only">
                      {@html hotspot.data.subtitle}
                    </div> -->
                    <div class="description text-only">
                      <p>
                        {@html hotspot.data.content}
                      </p>
                    </div>
                </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}


            {#if modal_type_tmp == 'info-v3' || modal_type_tmp == 'infopanel-v3'}
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                  {#if hotspot.name == id || 
                    hotspot.id == id
                    }
                    <div class="title">{@html hotspot.title}</div>
                    <div class="content {modal_type_tmp} image-only">
                        <img src="{settings.configuration.settings.base_path}assets/{hotspot.data.img}" class="{hotspot.data.img.includes('svietnik.jpg') || hotspot.data.img.includes('kior.jpg')  ? 'full-image image-only height' : 'full-image image-only'}" alt="img">
                    </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}

            {#if modal_type_tmp == 'video-v1' || modal_type_tmp == 'videopanel-v1'}
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                  {#if hotspot.name == id || 
                    hotspot.id == id
                    }
                      <div class="title">{@html hotspot.data.title}</div>
                    <div class="content {modal_type_tmp} videopanel">
                      <div>
                        <iframe
                          width="560"
                          height="315"
                          src="{hotspot.data.video_url}"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        />
                      </div>
                    </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}

            {#if modal_type_tmp == 'video-v2' || modal_type_tmp == 'videopanel-v2'}
            
              {#each settings["scenes"] as scene}   
                {#if scene.id === current_scene.toLowerCase()}
                  {#each scene.hotspots as hotspot}
                  {#if hotspot.name == id || 
                    hotspot.id == id
                    }
                    <div class="title">{@html hotspot.data.title}</div>
                    <div class="content {modal_type_tmp} videopanel">
                      <!-- <VideoPlayer width="auto" height="auto" poster="./local_poster.jpg" source="{settings.configuration.settings.base_path}/assets/{hotspot.data.local_video_url}" loop /> -->
                      <div>
                        <video controls autoplay>
                          <source src="{settings.configuration.settings.base_path}assets/{hotspot.data.local_video_url}" type="video/mp4">
                        </video>
                        <!-- <video
                          src="{settings.configuration.settings.base_path}/assets/{hotspot.data.local_video_url}"
                          on:mousemove={handleMove}
                          on:touchmove|preventDefault={handleMove}
                          on:mousedown={handleMousedown}
                          on:mouseup={handleMouseup}
                          bind:currentTime={time}
                          bind:duration
                          bind:paused>
                        </video> -->

                       
                      </div>
                    </div>
                    {/if}
                  {/each}
                {/if}    
              {/each}            
            {/if}
</div>

<style lang="scss">
    //   variables
    @import "../assets/scss/mixins.scss";
    @import "../assets/scss/themes.scss";
    @import "../assets/scss/fonts.scss";
    @import "../assets/scss/variables.scss";

    #modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    max-height: 640px;
    max-width: 960px;
    padding: 16px;
    @include blur;
    border-radius: $radius-M;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.image-only {
      height: auto;
    }

    &.video {
      height: auto;
      max-height: unset;
      max-width: 800px;
    }

    &.relative {
      position: relative;
      transform: unset;
      top: unset;
      left: unset;
      margin: auto;

    }

    &.pano {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
    
    .title {
      @include rubik-m24;
      padding: 0 32px;
      margin-bottom: 8px;
      text-align: center;
    }

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;
      border-radius: $radius-S;
      background-color: var(--primary-color-light005);
      border: 1px solid var(--primary-color-light20);
        &.image-only {
          padding: 0;
        }
        &.text-only {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 32px 64px 0 64px;
          text-align: center;
        }

        .full-image {
           width: 100%;
           margin-bottom: -4px;
           &.height {
            width: auto;
            height: 100%;
      }
    }

    }

    .info-v1, .infopanel-v1, .info-v2, .infopanel-v2, .info-v3, .infopanel-v3, .tour-info {
      padding: 32px 64px 0 64px;

      &.image-only {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content-title {
        @include rubik-m16;
        text-align: center;
          &.text-only {
            &::after {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 128px);
            height: 1px;
            background-color: var(--primary-color-light10);
            margin: 15px 0
        }
          }
      }
      .grid-img-left {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
          "image subtitle"
          "image description";

        margin-top: 56px;
        align-items: start;

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: var(--primary-color-light10);
          top: -24px;
        }
      }

      .image {
        grid-area: image;

        img {
          width: calc(100% - 16px);
          border-radius: $radius-S;
        }
      }

      .subtitle {
        @include rubik-m16;
        padding-left: 16px;
        padding-bottom: 12px;
        grid-area: subtitle;
        text-align: left;
        &.text-only {
          padding: 25px 10px 10px 10px;
        }
      }

      .description {
        grid-area: description;
        @include rubik-r14;
        padding-left: 16px;
        justify-self: start;
        text-align: left;
        &.text-only {
          text-align: center;
        }
        p {
          margin-bottom: 21px;
        }
      }
    }

    .videopanel {
      
      div {
        position: relative;
        aspect-ratio: 16 / 9;
        video {
          width: 100%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .google-map {
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
        &.filter {
          filter: grayscale(1);
        }
      }

    }

    .model-3d {
      position: relative;
      // display: none;
      .navigation {
        @include blur;
        display: flex;
        width: max-content;
        height: 40px;
        border-radius: $radius-S;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        padding: 0 18px;
          .nav-item:not(:last-child){
            margin-right: 25px;
          }

          .nav-item {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-color: var(--primary-color);
            mask-repeat: no-repeat;
            &.left,
            &.up,
            &.down,
            &.right {
              mask-image: url("../assets/icons/submenu.svg");
              mask-position: center;
            }
            &.left{
              transform: rotate(180deg);
            }
            &.up{
              transform: rotate(270deg);
            }
            &.down{
              transform: rotate(90deg);
            }
            &.zoomin{
              mask-image: url("../assets/icons/zoom-in.svg");
            }
            &.zoomout{
              mask-image: url("../assets/icons/zoom-out.svg");
            }
          }
          .separator {
            width: 1px;
            height: 32px;
            background-color: var(--primary-color-light20);
          }
      }
    }
  }


  // ------------------- SCREEN --------------------------------------------------------------------------------

  @media (max-width: 700px) {
    #modal {
      .infopanel, .tour-info {
        padding: 16px;
        .grid-img-left {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr;
          grid-template-areas:
            "subtitle"
            "image"
            "description";

          .subtitle {
            text-align: center;
          }

          .description {
            text-align: justify;
            padding: 0;
          }

          .image {
            margin-bottom: 16px;
            img {
              width: 100%;
            }
          }
        }
      }
      .info-v1, .infopanel-v1, .info-v2, .infopanel-v2, .info-v3, .infopanel-v3, .tour-info {
        padding: 16px;
        .grid-img-left {
          grid-template-rows: auto auto;
          grid-template-columns: 1fr;
          grid-template-areas:
            "subtitle"
            "image"
            "description";

          .subtitle {
            text-align: center;
          }
          .description {
            text-align: justify;
            padding: 0;
          }
          .image {
            margin-bottom: 16px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>