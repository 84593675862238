<script>
    export let settings;
    export let userdata;
    let tmp = userdata;
    tmp = settings;

    // //console.log('spúšťam intro');
    import { t, locale, locales } from "../i18n.js";
    import { fade, scale } from 'svelte/transition';
    import {get, writable} from 'svelte/store';

    import { disabled_icons } from '../store.js';
    import { showing_intro } from '../store.js';
    import { shortcuts } from '../store.js';
    let show_intro = true;

    showing_intro.subscribe(value => {
		  show_intro = value;

      if (
        value == false
      ) {
        disabled_icons.update(n => false)
      }
    });


    import { loaded_krpano } from '../store.js';


    loaded_krpano.subscribe(value => {
    if (
      value == true && localStorage.getItem('show_intro') == 'true'
    ) {
      let krpano = document.getElementById("krpanoSWFObject");
      krpano.call("hide_hotspots");
    	krpano.set("show_hs", "false");
      disabled_icons.update(n => true);

      if (
        localStorage.getItem('show_intro') == 'true'
      ) {
        shortcuts.update(n => false);
        disabled_icons.update(n => true)
      }

      
    }

    if (
        localStorage.getItem('show_intro') == 'false'
      ) {
        disabled_icons.update(n => false);
      }
		
	});
    

    const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);
    
    let checked = false;

    function update_show_intro() {
        //console.log(checked);
        localStorage.setItem('show_intro', !checked);
        //console.log(localStorage.getItem('show_intro'));

    }

    function close_intro() {
      showing_intro.update(n => false);
    }

</script>

    {#if localStorage.getItem('show_intro') == 'true' && show_intro == true}
    <div class="navigation-vr" transition:animate>
        <div class="close" on:click="{() => close_intro()}"/>
        <div class="title">{$t("components")['intro']['title']}</div>
        <div class="left-ico">
            <div class="ico-item move" />
        </div>
        <div class="right-ico">
            <div class="ico-item scroll" />
        </div>
        <div class="description left-description">
            <h1 class="description desktop">{$t("components")['intro']['click-desktop']}</h1>
            <h1 class="description touch">{$t("components")['intro']['click-vr']}</h1>
        </div>
        <div class="description right-description">
          <h1 class="description desktop">{$t("components")['intro']['scroll-desktop']}</h1>
            <h1 class="description touch">{$t("components")['intro']['scroll-vr']}</h1>
        </div>
        <div class="button">
            <button on:click="{() => close_intro()}">{$t("components")['intro']['continue']}</button>
        </div>
        <div class="checkbox">
            <input type="checkbox" id="nezobrazovat" class="check" bind:checked={checked} on:change="{() => update_show_intro()}" />
            <label for="nezobrazovat">{$t("components")['intro']['dont_show']}</label>
        </div>
    </div>
    {/if}



<style lang="scss">
@import "../assets/scss/themes.scss";
  @import "../assets/scss/fonts.scss";
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/variables.scss";

  .navigation-vr {
    width: calc(100% - 32px);
    max-width: 600px;
    height: calc(100% - 32px);
    max-height: 470px;
    @include blur;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $radius-M;
    padding: 16px 16px 20px;
    overflow: auto;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr auto 40px;
    gap: 0px 0px;
    grid-template-areas:
      "title title"
      "left-ico right-ico"
      "left-description right-description"
      "button button"
      "check check";
  }


  .title {
    grid-area: title;
    // background-color: tomato;
    @include rubik-m20;
    text-align: center;
    width: calc(100% - 55px);
    margin: auto;
  }
  .left-ico {
    grid-area: left-ico;
    // background-color: gold;
    justify-self: end;
  }
  .right-ico {
    grid-area: right-ico;
    // background-color: greenyellow;
  }
  .left-description {
    grid-area: left-description;
    // background-color: hotpink;
    justify-self: end;
    margin-right: 40px;

    .touch {
      display: none;
    }
  }
  .right-description {
    grid-area: right-description;
    // background-color: indigo;
    margin-left: 40px;

    .touch {
      display: none;
    }
  }
  .button {
    grid-area: button;
    // background-color: lightcoral;
    justify-self: center;

    button {
      width: 200px;
      height: 40px;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      @include rubik-m14;
      border: none;
      border-radius: $radius-S;
      margin: 0 0 19px 0;
      align-self: end;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
  .checkbox {
    grid-area: check;
    // background-color: lightgreen;
    justify-self: center;
    @include rubik-r14;
    align-self: center;
    // cursor: pointer;

    #nezobrazovat {
      appearance: none;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 0 2px var(--primary-color-light10);
      border-radius: 4px;
      position: relative;
      top: 3px;
      cursor: pointer;
      margin: 0;

      &:checked {
        box-shadow: 0 0 0 2px var(--primary-color);
      }

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: var(--primary-color);
        position: relative;
        top: 3px;
        left: 3px;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked::before {
        transform: scale(1);
      }
    }
    label {
      cursor: pointer;
      margin-left: 2px;
    }
  }

  .ico-item {
    width: 140px;
    height: 170px;
    // background-color: lightseagreen;
    margin: 32px 0 8px;
    background-position: center;
    background-repeat: no-repeat;

    &.move {
      background-image: url("../assets/icons/click-and-move.svg");
      margin-right: 40px;
    }

    &.scroll {
      background-image: url("../assets/icons/scroll-to-zoom.svg");
      margin-left: 40px;
    }
  }

  .description {
    @include rubik-r16;
    width: calc(100% - 16px);
    max-width: 140px;
    text-align: center;
    font-weight: 100;
  }

  //  ----------------HOVER-------------------------------------------------------------------------------------------------------

  @media (hover: hover) and (pointer: fine) {
    .navigation-vr {
      .button {
        button {
          &:hover {
            background-color: var(--primary-hover);
          }
        }
      }
    }
  }

  //  ----------------POINTER-------------------------------------------------------------------------------------------------------

  @media (pointer: coarse) {
    .ico-item {
      &.move {
        background-image: url("../assets/icons/tap-and-drag.svg");
      }
      &.scroll {
        background-image: url("../assets/icons/pinch-to-zoom.svg");
      }
    }

    .navigation-vr {
      .left-description {
        .desktop {
          display: none;
        }
        .touch {
          display: block;
        }
      }
      .right-description {
        .desktop {
          display: none;
        }
        .touch {
          display: block;
        }
      }
    }
  }

  //  ----------------SCREEN-------------------------------------------------------------------------------------------------------

  @media (max-width: 430px) {
    .navigation-vr {
      .left-ico {
        .ico-item {
          &.move {
            margin-right: 16px;
          }
        }
      }
      .right-ico {
        .ico-item {
          &.scroll {
            margin-left: 16px;
          }
        }
      }
      .left-description {
        margin-right: 16px;
      }
      .right-description {
        margin-left: 16px;
      }
    }
    .ico-item {
      &.scroll {
        width: calc(100% - 16px);
      }
    }
  }

  @media (max-width: 335px) {
    .navigation-vr {
      grid-template-rows: auto auto auto auto auto;
    }
    .ico-item {
      width: 100px;
      height: 120px;
      background-size: contain;
      &.scroll {
        width: 90px;
      }
    }
  }
</style>