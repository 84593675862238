<script>
  import { onMount } from "svelte";
  import { t, locale, locales } from "../i18n.js";

  export let settings;
  let tmp = settings;
  let loading_data = true;
  let shorctuts_scenes = [];
  import { fade, fly, slide } from "svelte/transition";
  export let userdata;

  import { get, writable } from "svelte/store";
  import { active_scene } from "../store.js";

  import {
    disabled_icons,
    showing_intro,
    picker,
    loaded_krpano,
  } from "../store.js";
  let _picker;

  let start_index = 0;

  picker.subscribe((value) => {
    // //console.log('disabled icons ' + value);
    _picker = value;
  });

  let krpano = document.getElementById("krpanoSWFObject");

  let searched_tags = null;
  let filter_by = settings.components[4].scenes;
  let name_by = settings.components[4].titles;

  let current_scene = "";
  let actual_index = 1;
  let splide;
  // console.log(splide);



  active_scene.subscribe((value) => {
    if (current_scene.toLowerCase() != value.toLowerCase()) {
      current_scene = value;

      read_scenes_from_settings();

      if (splide != undefined && splide.index != undefined) {
        

        for (let index = 0; index < shorctuts_scenes.length; index++) {
          if (shorctuts_scenes[index].id.toLowerCase() == value.toLowerCase()) {
            splide.go(index);
            actual_index = index;
          }
        }
      }
    }
  });

  let _laoded_krpano = false;
  loaded_krpano.subscribe((value) => {
    _laoded_krpano = value;
  });

  let dis_icon = get(disabled_icons);

  disabled_icons.subscribe((value) => {
    // //console.log('disabled icons ' + value);
    dis_icon = value;
  });

  let show_intro = true;

  showing_intro.subscribe((value) => {
    show_intro = value;
  });

  let blur_enabled = false;

  import { blur } from "../store.js";
  blur.subscribe((value) => {
    blur_enabled = value;
  });

  import { loaded_components } from "../store.js";
  import { shortcuts } from "../store.js";
  import { sidebar } from "../store.js";

  let _shortcuts = false;

  shortcuts.subscribe((value) => {
    _shortcuts = value;

    if (value == true) {
      sidebar.update((n) => false);
    }
  });

  $loaded_components = [...$loaded_components, "shortcuts"];

  // nájdenie, aké scény sa majú zobraziť v shortcuts

  

  function read_scenes_from_settings() {
    shorctuts_scenes = [];
    switch (filter_by) {
      case "all":
        settings.scenes.forEach(({ id, enabled, order, name, title, thumb }) => {
          if (enabled == true) {
            shorctuts_scenes = [
              ...shorctuts_scenes,
              {
                id: id,
                order: order,
                title: title,
                thumbnail : thumb
              },
            ];
          }
         
        });

        break;
      case "tag":
        settings.scenes.forEach(({ id, tags }) => {
          if (id.toLowerCase() == current_scene.toLowerCase()) {
            searched_tags = tags;
            shorctuts_scenes = [];
            settings.scenes.forEach(({ id, tags, order, name, title, thumb }, index) => {
              if (
                JSON.stringify(tags.sort()) ==
                JSON.stringify(searched_tags.sort())
              ) {

                shorctuts_scenes = [
                  ...shorctuts_scenes,
                  {
                    id: id,
                    tags: tags,
                    order: order,
                    title: title,
                    thumbnail : thumb
                  },
                ];
              }
            });
          }
        });
        //console.log(shorctuts_scenes);
        break;
    }

    if (splide != undefined && splide.index != undefined) {
      for (let index = 0; index < shorctuts_scenes.length; index++) {
        if (shorctuts_scenes[index].id.toLowerCase() == current_scene.toLowerCase()) {
          splide.go(index);
        }
      }
    }

    for (let index = 0; index < shorctuts_scenes.length; index++) {
      console.log(shorctuts_scenes[index].id + ' | ' + settings.configuration.start_scene );
    if (
      shorctuts_scenes[index].id == settings.configuration.start_scene
    ) {
      console.log(shorctuts_scenes[index]);
      start_index = index;
      console.log(start_index);
    }
    
  }

    loading_data = false;
  }
  read_scenes_from_settings();

  let width = 0;
  let inverval_timer;
  let auto_play = false;
  let active_slide_index = 0;
  let auto_play_timeout = 2000;

  function swiper_next() {
    swiper_autoplay_stop();
  }

  function swiper_prev() {
    swiper_autoplay_stop();
  }

  function swiper_autoplay_stop() {
    auto_play = false;
  }
  function swiper_autoplay_start() {
    auto_play = true;
  }

  let sortBy = { col: "order", ascending: true };

  function order_scenes() {
    loading_data = true;
    // zmena poradia podĺa order !
    let sortModifier = sortBy.ascending ? 1 : -1;

    let sort = (a, b) =>
      a["order"] < b["order"]
        ? -1 * sortModifier
        : a["order"] > b["order"]
        ? 1 * sortModifier
        : 0;

    settings["scenes"] = settings["scenes"].sort(sort);
    loading_data = false;
  }

  order_scenes();

  import { active_components } from "../stores.js";
  import { last_used_component } from "../stores.js";

  import { Splide, SplideSlide, SplideTrack } from "@splidejs/svelte-splide";
  // import { Splide, SplideSlide } from "svelte-splide";
  import "@splidejs/svelte-splide/css";

  let showing;
  let last_component;

  active_components.subscribe((value) => {
    showing = value;
  });

  last_used_component.subscribe((value) => {
    last_component = value;
  });

  // odobratie komponentu z poľa aktívnych prvkov
  function change_active_component($component) {
    if (showing.includes($component)) {
      active_components.update((n) => n.filter((item) => item !== $component));
      last_used_component.update((n) => "");
    } else {
      active_components.update((n) => [...n, $component]);
      last_used_component.update((n) => $component);
    }

    //
  }

  active_scene.subscribe((value) => {
    if (value != undefined && value != "undefined") {
      current_scene = value;

      console.log(current_scene  +' !');
      let element = document.getElementsByClassName("splide__slide");

      for (let index = 0; index < element.length; index++) {
        if (
          element[index].children[0].id.toLowerCase() ==
          current_scene.toLowerCase()
        ) {
          
          // console.log(element[index].children[0].id);
          // element[index].classList.add("active");
        } else {
          // element[index].classList.remove("active");
        }
      }


      // if ( splide.state.is( Splide.STATES.IDLE ) ) {
      //   // do something
      // }
    } else {
      current_scene = "VRext_03";
    }

    
  });

  let new_titles = [];
  
  

  const options = {
    rewind: true,
    gap: "4",
    autoplay: false,
    fixedWidth: 240,
    arrows: true,
    height: "120px",
    pagination: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    updateOnMove : false,
    slideFocus : false,
    wheel: true,
    start: 0,
    // omitEnd: true,
    focus  : 'center',
    // trimSpace: false,
  };

  let count = 0;

  $: {
    splide;

    if (splide != null && count == 0) {
        // splide = new Splide( '#splide01' );

        splide.on("active", () => {
          
          actual_index = splide.index + 1;
          if (
            shorctuts_scenes[splide.index].id.toLowerCase() != current_scene
          ) {
            krpano.call(
              "loadscene(" +
                shorctuts_scenes[splide.index].id +
                ", null, KEEPVIEW, BLEND(1))"
            );
          }
        });

        count++
      }

    if (splide != undefined && Splide.STATES != undefined) {
      // console.log(splide);
      if (splide.state.is(Splide.STATES.IDLE)) {
        // console.log(splide.state);
      }
    }
  }

  function autoplay($value) {
    const { Autoplay } = splide.Components;

    switch ($value) {
      case "play":
        Autoplay.play();
        break;

      case "stop":
        Autoplay.stop();
        break;

      case "pause":
        Autoplay.pause();
        break;
    }

    // splide.go( '>' );
    // console.log(splide.index);
  }

  function go_to($id) {
    krpano.call("loadscene(" + $id + ", null, KEEPVIEW, BLEND(1))");
    // splide.go( '>' );
  }

  function prev() {
    splide.go( '-1' );
  }

  function next() {
    splide.go( '+1' );
  }
</script>

{#if loading_data == false && _laoded_krpano == true}
  <div
    class="shortcuts-icon"
    class:active={_shortcuts === true}
    class:hide_blur={blur_enabled === true || dis_icon == true}
    on:click={() => shortcuts.update((n) => !n)}
    on:click={() => change_active_component("shortcuts")}
  />

  <div
    id="shortcuts"
    class="scene-thumbnails-tour"
    class:active={_shortcuts === true}
  >
    <img
      id="woow"
      src="{userdata['base_path']}assets/images/woow-logo.svg"
      alt="logo"
      on:click={() => window.open("https://woowstudio.com/")}
    />

    <!-- <svelte:component this={Splide}>

    </svelte> -->
    <Splide bind:splide {options} hasTrack={false} aria-labelledby="heading">
      <div style="position: relative">
        <SplideTrack>
          {#each shorctuts_scenes as slide}
            <SplideSlide>
              <img
                id={slide.id}
                src="{userdata[
                  'base_path'
                ]}assets/krpano/scenes/{slide.thumbnail}"
                alt={slide.id}
                on:click={() => go_to(slide.id)}
              />
              <span>{slide.title}</span>
            </SplideSlide>
          {/each}
        </SplideTrack>
      </div>

      <div class="splide__progress">
        <div class="splide__progress__bar" />
      </div>

      <button class="splide__toggle">
        <span class="splide__toggle__play" on:click={() => autoplay("play")} />
        <span
          class="splide__toggle__pause"
          on:click={() => autoplay("pause")}
        />
      </button>
    </Splide>

    <!-- <div class="progress-bar">
      <div class="progress-bar-0" />
      <div id="progress" class="progress-bar-100" style="width: {width}%;" />
    </div> -->
    <div class="control-bar">
      <p class="title">Automatischer Rundgang</p>
      <div class="controls-items">
        <div class="control-option previous" on:click={() => prev()} />
        {#if auto_play == true}
          <div
            class="control-option stop"
            on:click={() => swiper_autoplay_stop()}
          />
        {:else}
          <div
            class="control-option play"
            on:click={() => swiper_autoplay_start()}
          />
        {/if}
        <div class="control-option next" on:click={() => next()} />
      </div>
      <p class="pagination">
        <span class="current">{actual_index}</span>

        /
        <span class="all">{shorctuts_scenes.length}</span>
      </p>
    </div>
  </div>
{/if}

<style lang="scss">
  // -------------- SHORTCUTS je nastylovany v global.scss -------------------------------
  #woow {
    cursor: pointer;
    right: 0;
    position: absolute;
    z-index: 9;
    top: -48px;
  }

  .splide__toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    span.splide__toggle__play {
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-color: var(--primary-color);
        mask-image: url("../assets/icons/play.svg");
        -webkit-mask-image: url("../assets/icons/play.svg");
      }
    }
    span.splide__toggle__pause {
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-color: var(--primary-color);
        mask-image: url("../assets/icons/pause.svg");
        -webkit-mask-image: url("../assets/icons/pause.svg");
      }
    }
  }
</style>
