
import { writable } from 'svelte/store';

export const active_scene = writable('VRext_00');

export const active_components = writable(0);
export const loaded_components = writable(['core']);
export const loaded_krpano = writable(false);
export const show_intro = writable(localStorage.getItem('show_intro'));
export const showing_intro = writable(true);
export const intro = writable(true);

export const vr_mode = writable(false);
export const device_type = writable();
export const gyro_mode = writable(false);
export const blur = writable(false);

export const show_floorplan = writable(false);
export const mobile_submenu = writable(false);
export const mobile_settings_submenu = writable(false);

export const sidebar = writable(false);
export const shortcuts = writable(false);
export const vr_question = writable(false);

export const modal = writable(false);
export const modal_type = writable();

export const preloader = writable(true);
export const play_sound = writable(false);


export const disabled_icons = writable(true);

// Skypark
export const picker = writable(false);
export const floor = writable("1");
export const tour_select = writable(false);

export const infopanel = writable(false);
export const videopanel = writable(false);

export const infopanelData = writable({});
export const videopanelData = writable({});

export const opravVR = writable(false);
