<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();

    export let userdata;
    let loading_data = true;
    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        console.log(settings);
        loading_data = false;
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia tour boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia tour neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  
</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("components")['tour_settings']['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      
      {#each settings.components as component}
        {#if component.id == 'Settings'}
        {#each component.values as item}
          <div class="component item">
            <label for="enabled">{$t("components")['tour_settings'][item.id]}</label>
            <input type="checkbox" name={item.id} bind:checked={item.enabled}>
          </div>   
        {/each}
          
        {/if}
      {/each}
    </main>
    {/if}
    <Header />
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>
  