<script>
    export let settings;
    export let userdata;
    let tmp = userdata;
    tmp = settings;

    let blur_enabled = false;

    import { blur } from '../store.js';
    import { sidebar } from '../store.js';

    blur.subscribe(value => {
		  blur_enabled = value;
	  });

    let hide_elements_during_blur = ['sidebar-icon', 'shortcuts-icon', 'logo', 'appartments-icon'];

    $: {
      blur_enabled;

      switch (blur_enabled) {
        case true : 
          sidebar.update(n => false);
          for (let index = 0; index < hide_elements_during_blur.length; index++) {
            let element = document.getElementsByClassName(hide_elements_during_blur[index]);
            if (
              element != null && element.classList != undefined
            ) {
              element.classList.add("hide-blur");
            }
            
            
          }
        break;

        default : 
        for (let index = 0; index < hide_elements_during_blur.length; index++) {
            let element = document.getElementsByClassName(hide_elements_during_blur[index]);
            if (
              element != null && element.classList != undefined
            ) {
              element.classList.remove("hide-blur");
            }
            
            
          }
        break;
      }
    }

</script>

   {#if blur_enabled}
     <div id="blur"/>
   {/if}


<style lang="scss">
  @import "../assets/scss/themes.scss";
  @import "../assets/scss/fonts.scss";
  @import "../assets/scss/mixins.scss";
  @import "../assets/scss/variables.scss";

  #blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 63, 70, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 0;
  }
  
</style>