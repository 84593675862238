<script>
    
    import { t, locale, locales } from "../i18n.js";
    import { loaded_components } from '../store.js';
    
    import { fade, scale } from 'svelte/transition';
    import {get, writable} from 'svelte/store';
    import {preloader} from "../store.js";
    import {loaded_krpano} from "../store.js";

    const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);
    
</script>

    <div id="blur" transition:animate>
        <div class="preloader">
        </div>
        <div class="data"></div>
        <p>{$t("components")['preloader']['loading_components']} : {$t("components")['preloader']['loaded']}
        
        <!-- {#each $loaded_components as item,index}
        <span>{item}, </span>
        {/each} -->
      </p>
      <p>{$t("components")['preloader']['loading_scenes']} 
        {#if $loaded_krpano == true}
          {$t("components")['preloader']['scenes_loaded']}
        {/if}
      </p>
    </div>



<style lang="scss">
  #blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 254, 254, 0.5) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  -moz-filter: blur(40px);
  cursor: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}
#blur .preloader {
  width: 64px;
  height: 64px;
  background-image: url("../assets/icons/preloader.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in-out;
}
#blur p {
  color: #292930;
}
</style>