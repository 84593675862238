<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia loga boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia loga neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload loga na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);

        
        const res = await fetch(userdata['base_path'] + 'assets/php/saveLogo.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Logo bolo úspešne uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  
                
                if (json.logo_url) {
                    settings.components[0].img = 'assets/'+json.logo_url;
                    
                    save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Logo nebolo uploadnuté!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

    function check_url($link) {
      if ($link.includes('src')) {
        console.log('áno');

        let checked_url = $link.slice(
          $link.indexOf('src="') + 5,
          $link.lastIndexOf('" width'),
        );

        settings.components[7].url = checked_url;
        console.log(checked_url);

      }

      else {
        console.log('nie');
      }
      
    }
    
import { modal } from '../../store.js';
let show_modal;
modal.subscribe(value => {
  show_modal = value;
});

let Modal;
let modal_type = 'google-map';

import { onMount } from 'svelte';

const sleep = ms => new Promise(f => setTimeout(f, ms));

onMount(async () => {
  //await sleep(1000); // simulate network delay
  Modal = (await import('../../tour_components/Modal.svelte')).default
});

</script>
  
  <div id="wrapper">
    <Aside />

    {#if loading_data == false}
    <main in:fade>
      <h1>{$t("components")['maps']['admin-title']}</h1>
      <svelte:component this={Modal} modal_type_tmp="{modal_type}" title="{$t("components")['maps']['title']}" filter="{settings.components[7].filter}" settings="{settings}" position="relative" url={settings.components[7].url}/>  
      
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      
        <div class="component">
          <label for="url">{$t("components")['maps']['url']}</label>
          <input type="text" name="url" bind:value={settings.components[7].url} on:change="{check_url(settings.components[7].url)}">
        </div>  
        
        <div class="component">
          <label for="url">{$t("components")['maps']['filter']}</label>
          <input type="checkbox" name="filter" bind:checked="{settings.components[7].filter}">
        </div>  
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>