<script>
    
	import { t, locale, locales } from "./i18n.js";
    import { Router, Route } from 'svelte-routing';
    import ProtectedRoute from './ProtectedRoute.svelte';
    import Notifications from 'svelte-notifications';

    import Home from './routes/Home.svelte';
    import Dashboard from './routes/admin_components/Dashboard.svelte';
    import Admin from './routes/admin_components/Admin.svelte';
    import Components from './routes/admin_components/Components.svelte';
    import Logo from './routes/admin_components/Logo.svelte';
    import Shortcuts from './routes/admin_components/Shortcuts.svelte';
    import TourSettings from './routes/admin_components/TourSettings.svelte';
    import Share from './routes/admin_components/Share.svelte';
    import Maps from './routes/admin_components/Maps.svelte';
    import Tourinfo from './routes/admin_components/Tourinfo.svelte';
    import Floorplan from './routes/admin_components/Floorplan.svelte';
    import Users from './routes/admin_components/Users.svelte';
    import AddUser from './routes/admin_components/Add_user.svelte';
    import Login from './routes/Login.svelte';
    import MergeScenes from './routes/admin_components/Merge_scenes.svelte';
    import Sounds from './routes/admin_components/Sounds.svelte';
    import Tour from './routes/Tour.svelte';
    import Meta from './routes/admin_components/Meta.svelte';
    import Scenes from './routes/admin_components/Scenes.svelte';
    import NotFound from './routes/NotFound.svelte';
    import AudioPlayer from "./routes/admin_components/AudioPlayer.svelte";
    import ClearData from './routes/admin_components/Clear_data.svelte';
    import ImportData from './routes/admin_components/Import_data.svelte';

    let userdata = {
    	"username" : null,
	    "password" : null,
        "base_path" : "https://stratenemesto.sk/"
        //"base_path" : ""
        
    }

</script>

<Notifications>
    <Router>
        <ProtectedRoute path="/dashboard" component={Dashboard} {userdata} />
        <ProtectedRoute path="/admin" component={Admin} {userdata}/>
        <ProtectedRoute path="/scenes" component={Scenes} {userdata} />
        <ProtectedRoute path="/users" component={Users} {userdata}/>
        <ProtectedRoute path="/add-user" component={AddUser} {userdata}/>
        <ProtectedRoute path="/meta" component={Meta} {userdata} />
        <ProtectedRoute path="/components" component={Components} {userdata} />
        <ProtectedRoute path="/components/logo" component={Logo} {userdata} />
        <ProtectedRoute path="/components/shortcuts" component={Shortcuts} {userdata} />
        <ProtectedRoute path="/components/tour-settings" component={TourSettings} {userdata} />
        <ProtectedRoute path="/components/share" component={Share} {userdata} />
        <ProtectedRoute path="/components/maps" component={Maps} {userdata} />
        <ProtectedRoute path="/components/tour-info" component={Tourinfo} {userdata} />
        <ProtectedRoute path="/components/floorplan" component={Floorplan} {userdata} />
        <ProtectedRoute path="/components/merge-scenes" component={MergeScenes} {userdata} />
        <ProtectedRoute path="/components/sounds" component={Sounds} {userdata} />
        <ProtectedRoute path="/components/player" component={AudioPlayer} {userdata} />
        <ProtectedRoute path="/clear-data" component={ClearData} {userdata} />
        <ProtectedRoute path="/import-data" component={ImportData} {userdata} />
        <!-- <Route path="/" component={Tour} {userdata} /> -->
        <Route path="/login" component={Login} {userdata}/>
        <Route path="/tour" component={Tour} {userdata}/> 
        <Route path="/praha/" component={Tour} {userdata}/> 
        <Route path="" component={Tour} {userdata}/> 
        
        <!-- <Route path="*" component={NotFound} {userdata} /> -->
        <Route path="*" component={Tour} {userdata} />
    </Router>
</Notifications>


<!-- <main>
	<h1>Hello {name}!</h1>
	<p>{$t("homepage.title")} <a href="https://svelte.dev/tutorial">Svelte tutorial</a> to learn how to build Svelte apps.</p>
</main> -->

<style lang="scss">
//@import '../assets/scss/themes.scss';

</style>