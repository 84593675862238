<script>
    import { Link } from "svelte-routing";
    import { navigate } from "svelte-routing";
    import { token } from "../../stores.js";
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;
        
        
        loading_data = false;
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/clearData.php",
        {
          method: "POST",
          body: JSON.stringify(settings),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  

    console.log(userdata['base_path']);

    function import_data() {
      console.log(settings);

      for (let index = 0; index < imported_data.count; index++) {
        if (
          index < 10
        ) {
          settings.scenes = [...settings.scenes, {
            id : imported_data.default_name + '_0' + index,
            name : '0' + index,
            tags : [{
                0 : imported_data.default_name
              }
            ],
            enabled : true,
            hlookat : 0,
            vlookat : 0,
            fov : 120,
            title : null,
            description : null,
            north : 0,
            floor_enabled : imported_data.floors,
            custom_field_2 : null,
            custom_field_3 : null,
            custom_field_4 : null,
            custom_field_5 : null,
            custom_field_6 : null,
            custom_field_7 : null,
            custom_field_8 : null,
            custom_field_9 : null,
            custom_field_10 : imported_data.default_name,
            thumb : imported_data.default_name + '_0' + index + '_thumb.jpg',
            hotspots : []
          }];  
        }

        else {
          settings.scenes = [...settings.scenes, {
            id : imported_data.default_name + '_' + index,
            name : '' + parseInt(index),
            tags : [{
                0 : imported_data.default_name
              }
            ],
            enabled : true,
            hlookat : 0,
            vlookat : 0,
            fov : 120,
            title : null,
            description : null,
            north : 0,
            floor_enabled : imported_data.floors,
            custom_field_2 : null,
            custom_field_3 : null,
            custom_field_4 : null,
            custom_field_5 : null,
            custom_field_6 : null,
            custom_field_7 : null,
            custom_field_8 : null,
            custom_field_9 : null,
            custom_field_10 : imported_data.default_name,
            thumb : imported_data.default_name + '_' + index + '_thumb.jpg',
            hotspots : []
          }];  
        }
      }
    
      console.log(settings);
    }

    let imported_data = [
      {
        default_name : null,
        count : 1,
        floors : false,
        slider_tag : null
      }
    ];

</script>
  
  <div id="wrapper">
    <Aside />
    {#if loading_data == false}
    <main in:fade>
      
      <h1>{$t("admin_components")["clear_data"]["admin_title"]}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>
      <div class="component">
        <div>
          <label for="default_name">{$t("components")['import']['default_name']}:</label>
          <input type="text" name="default_name" bind:value="{imported_data["default_name"]}">
        </div> 
      </div>

      <div class="component">
        <div>
          <label for="scenes_count">{$t("components")['import']['scenes_count']}:</label>
          <input type="number" name="scenes_count" bind:value="{imported_data.count}">
        </div> 
      </div>

      <div class="component">
        <div>
          <label for="cf10">{$t("components")['import']['cf10']}:</label>
          <input type="text" name="cf10" bind:value="{imported_data["slider_tag"]}">
        </div> 
      </div>

      <div class="component">
        <div>
          <label for="enable_floors">{$t("components")['import']['enable_floor']}</label>
          <input type="checkbox" name="enable_floors" bind:checked="{imported_data.floors}">
        </div>
      </div>

      <button class="import_data" name="import_data" on:click={() => import_data()}><i class="fa-solid fa-file-import"></i>{$t("components")['import']['import_data']}</button>
 
    </main>
    <Header />
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

    .import_data {
      background-color: red;
    }

  </style>