<script>
    import { t, locale, locales } from "../../i18n.js";
    import { fade } from "svelte/transition";
    import Aside from "../../layouts/_aside.svelte";
    import Header from "../../layouts/_header.svelte";

    
  
    import AudioPlayer, { stopAll}  from './AudioPlayer.svelte';
  
    import { getNotificationsContext } from "svelte-notifications";
    const { addNotification } = getNotificationsContext();
  
    let loading_data = true;

    export let userdata;

    let settings;
    let test = [];

    let audioTracks = [];
  
    async function fetchData() {
      const res = await fetch(
        userdata['base_path'] + "/assets/json/settings.json",
        {}
      );
  
      if (res.ok) {
        const json = await res.json();
        settings = json;   
        loading_data = false;
        for (let index = 0; index < settings.components[11].bg_sounds.length; index++) {
          audioTracks.push(userdata['base_path'] + 'assets/sounds/'+settings.components[11].bg_sounds[index]);
          
        }
        
        
        
        return json;
      } else {
        throw new Error(data);
      }
    }
  
    fetchData();

    async function save_settings() {
      const res = await fetch(
        userdata['base_path'] + "/assets/php/saveComponents.php",
        {
          method: "POST",
          body: JSON.stringify(settings.components),
        }
      );

      if (res.ok) {
        const json = await res.json();
        let result = JSON.stringify(json);

        if (json.status == true) {
          addNotification({
                text: "Nastavenia zvuku boli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "success",
              });  
        }

        else {
          addNotification({
                text: "Chyba : Nastavenia zvuku neboli uložené!",
                position: "top-center",
                removeAfter: 4000,
                type: "danger",
              });  
        }
      }  
    };  


    // upload zvuku na server
    async function uploadFile() {
        let formData = new FormData();           
        formData.append("file", fileupload.files[0]);
        
        const res = await fetch(userdata['base_path'] + 'assets/php/saveSound.php', {
        method: "POST", 
        body: formData
        });    

        if (res.ok) {
            const json = await res.json();
            let result = JSON.stringify(json);

            if (json.status == true) {
            addNotification({
                    text: "Zvuk bol úspešne uploadnutý!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "success",
                });  

                
                if (json.sound_url) {
                  settings.components[11].bg_sounds.push(userdata['base_path'] + 'assets/sounds/' + json.sound_url);
                  settings.components[11].active_sound = userdata['base_path'] + 'assets/sounds/' + json.sound_url;
                  console.log(audioTracks);
                  save_settings();
                }
            }

            else {
            addNotification({
                    text: "Chyba : Zvuk nebol uploadnutý!",
                    position: "top-center",
                    removeAfter: 4000,
                    type: "danger",
                });  
            }
        }  
    }

    function update_active_sound(e) {
      
      settings.components[11].active_sound = e.target.value;
    }

    function remove_sound($src) {
      console.log($src);
      
      settings.components[11].bg_sounds = settings.components[11].bg_sounds.filter(m => m !== $src);
      save_settings();
    }

</script>
  
  <div id="wrapper">
    <Aside />
    
    {#if loading_data == false}

    <main in:fade>
      <h1>{$t("components")['sounds']['admin-title']}</h1>
      <button class="save_settings" name="save_settings" on:click={() => save_settings()}><i class="fa-solid fa-floppy-disk"></i>{$t("components")['save']}</button>

      <!-- Zvuk enable/disable -->
      <div class="component">
        <label for="enable">{$t("components")['sounds']['enable_bg_sound']}</label>
        <input type="checkbox" name="enable" bind:checked="{settings.components[11].autoplay}"/>
      </div>  

      <!-- Zvuk  -->
      <div class="component">
        <label for="subtitle">{$t("components")['sounds']['background_sound']}</label>
        
        <select name="sounds" id="sounds" bind:value="{settings.components[11].active_sound}"  on_change="{(e) => update_active_sound(e)}">
          {#each settings.components[11].bg_sounds as sound}
            <option value="{sound}">{sound.replace(userdata['base_path'] + 'assets/sounds/', '')}</option>   
          {/each}
          
        </select>

      </div>        

      <!-- upload zvuku -->
      <div class="component upload">
        <label for="fileupload">{$t("components")['sounds']['sound']}</label><br>
        <input id="fileupload" type="file" name="fileupload" />
        <button id="upload-button" on:click="{() => uploadFile()}"> Upload </button>
      </div>

      {#if settings.components[11].bg_sounds.length > 0}
      {#each settings.components[11].bg_sounds as src,index }
        <!-- Prehrať zvuk -->
        <div class="component upload">
          <p>{src.replace(userdata['base_path'] + 'assets/sounds/', '')}</p>
          
          <AudioPlayer {src} />
          <button id="{index}" class="remove_sound" on:click="{() => remove_sound(src)}">{$t("components")['sounds']['remove_sound']}</button>
        </div>   
        {/each}
      {/if}

      

      


    </main>
    <Header />
    
    {/if}
    
  </div>
  
  <style lang="scss">
    @import "../../assets/scss/themes.scss";

  </style>