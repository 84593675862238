<script>
    import { onMount } from 'svelte';
	import { t, locale, locales } from "../i18n.js";
    import { fade } from "svelte/transition";
	import { each } from 'svelte/internal';
	import {get, writable} from 'svelte/store';

	import {infopanelData, videopanelData, loaded_krpano, tour_select} from "../store.js";
	import { vr_mode, device_type, gyro_mode } from '../store.js';
	import { floor, infopanel, videopanel, active_scene, opravVR} from '../store.js';

	import Basket from '../tour_components/Basket.svelte';
	import Languages from '../tour_components/Languages.svelte';
	import Logo from '../tour_components/Logo.svelte';
	import Sidebar from '../tour_components/Sidebar.svelte';
	import Shortcuts from '../tour_components/Shortcuts.svelte';
	import Floorplan from '../tour_components/Floorplan.svelte';
	import Blur from '../tour_components/Blur.svelte';
	import Vr_tools from '../tour_components/Vr_tools.svelte';

	export let userdata;
	export let location;

	let tmp = location;
    
	let showTour = false;
	
	let vr_type = '';
	let _floor = "1";

	floor.subscribe((value) => {
		_floor = value;
	});

	function webvr_state() {
		let krpano = document.getElementById("krpanoSWFObject")
		//vr_mode = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
		// 0=off, 1=fake, 2=mobile, 3=true webvr (oculus go, rift, etc)	
		vr_type = krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14;
		// device_type.update(n => krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? krpano.webvr.isvrbrowser ? 3 : 1 : 2 : 0 : 14);
		// ////////////console.log('TYPE : ' + vr_type);	
		// return krpano.webvr && krpano.webvr.isenabled ? krpano.webvr.isfake ? krpano.webvr.iswebvr ? 3 : 1 : 2 : 0;
	}

	const initializeRemarkable = () => {
		fetchData();
	}

	let settings, start_scene;
	let loading_data = true;
	let start_scene_number = 0;
	let krpano_full_loaded = false;
	let enable_sound = false;
	let current_scene, hts_id, show_modal, show_preloader;

	// Načítanie scén
	async function fetchData() {
		const res = await fetch(
			userdata['base_path'] + "assets/json/settings.json",
			{}
		);

		if (res.ok) {
		const json = await res.json();
		settings = json;
		start_scene = settings.configuration.start_scene;

			// ////////////console.log(settings);
		loading_data = false;
		showTour = true;

		function check_pano() {
			var krpano = document.getElementById("pano");
			if(typeof(krpano) != 'undefined' && krpano != null){
				load_krpano();
			} else{
			setTimeout(check_pano, 100);
			}
		}

      	check_pano();

		

		



		return json;
		} else {
		throw new Error(data);
		}
  	}

	const components = [Logo, Shortcuts,Sidebar,Sounds,Floorplan];
	const components_array = ["Logo", "Shortcuts","Sidebar","Sounds","Floorplan"];

	// Načítanie krpana
  	function load_krpano () {
		embedpano({
			xml: userdata['base_path'] + "assets/krpano/tour.xml",
			target:"pano",
			html5:"only",
			mobilescale:1.0,
			initvars : {
				scene_name : "tmp",
				show_hs : true,
				floor : _floor
			},
			hooks: {
            loadSceneData(scene) {
            },
            sceneChanged(scene) {
            }
        },
			onready: function (krpano) {
				let last_visited_scene = [krpano.get("xml.scene")];
				let loaded_times = 0;
				var krpano = krpano.get("global");
				//////////////console.log(settings.configuration.start_scene);
				krpano.call("loadscene(" + settings.configuration.start_scene + ")");
				krpano.call("createsound(bgsnd, 'assets/videos/Squere&horses_mix.mp3', 0.5)"); 
				krpano.call("createsound(bgsnd_int, 'assets/videos/int_sound.mp3', 0.2)"); 
				webvr_state();

				function prekopaj_infopanely_desktop() {
					////////console.log("skúšam");
					var hts_count = krpano.get("hotspot.count");
					////////console.log(hts_count);
					for (let index = 0; index < hts_count; index++) {
						////////console.log(krpano.get("hotspot[" + index +"].name"));
					}
					
						for (let index = 0; index < hts_count; index++) {
							
							if (
								krpano.get("hotspot[" + index +"].hstype") != null &&
								(krpano.get("hotspot[" + index +"].name").includes("info") ||
								krpano.get("hotspot[" + index +"].name").includes("video")	)
							) {
								////////console.log(krpano.get("hotspot[" + index +"].name") + " : " + krpano.get("hotspot[" + index +"].hstype"));	
								krpano.set("hotspot[" + index +"].alpha", "1.0");
								krpano.set("hotspot[" + index +"].visible", "true");
								////////console.log(krpano.get("hotspot[" + index +"].hstype"));
								// ////////////console.log(krpano.get("hotspot[" + index +"].name"));
								//////////////console.log(krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));

								var hs = krpano.get("hotspot[" + index +"]");
								hs.onclick = function() {

									hts_id = krpano.get("hotspot[" + index +"].id");
									////////console.log(hts_id + " : " + index);
									
									if (
										krpano.get("hotspot[" + index +"].hstype").includes('info') != null
									) {
										////////console.log(krpano.get("hotspot[" + index +"].name"));
										modal_type.set('info-v'+ krpano.get("hotspot[" + index +"].hstype").split('-').pop().replace('v',''));
									}

									if (
										
										krpano.get("hotspot[" + index +"].hstype").includes('video')
									) {
										////////console.log(krpano.get("hotspot[" + index +"].name"));
										modal_type.set('video-v2');
									}

									modal.update(n => !n);

									
								}
							}

						}
				}

				function prekopaj_infopanely_vr() {
					//console.log("lúskam infopanely");
					setTimeout(() => {
						var hts_count = krpano.get("hotspot.count");
						for (let index = 0; index < hts_count; index++) {
							if (krpano.get("hotspot[" + index +"].name").includes("video") || krpano.get("hotspot[" + index +"].name").includes("info")) {
								
								var hs = krpano.get("hotspot[" + index +"]");
									hs.onclick = function() {
										infopanel.update( n => false);
										videopanel.update( n => false);
										settings.scenes.forEach(element => {
											if (element.id.toLowerCase() == krpano.get("xml.scene").toLowerCase()) {
												element.hotspots.forEach(hotspot => {
													if (hotspot.name == krpano.get("hotspot[" + index +"].name")) {
														
														if (krpano.get("hotspot[" + index +"].name").includes("video")) {
															//////////console.log("ide o videopanel");
															videopanelData.update( n => hotspot);
															videopanel.update( n => true);
														} else {
															//////console.log("ide o infopanel");
															infopanelData.update( n => hotspot);
															infopanel.update( n => true);
														}
														
													}
												});
											}
										});
									};

							}
						}
					}, 1000);
					//var hs = krpano.get("hotspot[info]");
						
				}

				// zobrazenie menu ikony
				function move_menu_icon() {
					let menu_icon = true;
					var krpano = document.getElementById("krpanoSWFObject");
					let actual_ath = krpano.get("view.hlookat");
					let actual_atv = krpano.get("view.vlookat");

					let new_ath = 0;
					let hotspotID = "menu";

					// otáčanie hotspotu po osi X
					krpano.set("hotspot["+ hotspotID + "].ath", actual_ath);

					if (
						menu_icon = true
					) {
						let position_hotspot_button = 30;
						
						if (
							(actual_atv - position_hotspot_button) / (position_hotspot_button) < 1 && 
							(actual_atv - position_hotspot_button) > 0 
						) {
							
							krpano.set("hotspot["+ hotspotID +"].visible", "true");   
							new_ath = (((actual_atv / position_hotspot_button)*100)-100)/100;
							

							if (
								new_ath > 0 && 
								new_ath < 100
							) {
								krpano.set("hotspot["+ hotspotID + "].alpha", new_ath );
							}

							else if (
								new_ath > 100
							) {
								krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
							}
							
						}

						else if(
							
							(actual_atv - position_hotspot_button) / (position_hotspot_button) >= 1
						) {
							
							krpano.set("hotspot["+ hotspotID + "].alpha", "1.0");
						}

						else {
							
							krpano.set("hotspot["+ hotspotID + "].alpha", "0.0");
							krpano.set("hotspot["+ hotspotID +"].visible", "false"); 
						}
					}
				}

				krpano.events.onnewpano = function() {
					infopanel.update(n => false);
					videopanel.update(n => false);
					last_visited_scene.push(krpano.get("xml.scene"));

					if (krpano.get("xml.scene") == "synagoga_15" || krpano.get("xml.scene") == "synagoga_16") {
						let hlp = last_visited_scene.length - 2
						//////////console.log(last_visited_scene[hlp]);
						
						krpano.set("hotspot[next-node_0_empty].linkedscene",last_visited_scene[hlp]); 
					}
					//////////console.log(last_visited_scene);
					current_scene = krpano.get("xml.scene").replace('scene_','').toUpperCase();
					active_scene.update(n => krpano.get("xml.scene").replace('scene_','').toUpperCase());

					// zvuk koníkov pre exteríerové scény
					let sound_scenes = ["synagoga_00","synagoga_01","synagoga_02","synagoga_03","synagoga_04","synagoga_05","synagoga_06","synagoga_07","synagoga_08","synagoga_13","synagoga_14","synagoga_17","synagoga_18"];
					// zvuk pre interiérové scény
					let sound_scenes_int = ["synagoga_10","synagoga_11","synagoga_12","synagoga_19","synagoga_20"];
					let enable_sound = false;
					let enable_sound_int = false;

					sound_scenes.forEach(element => {

						if (element == krpano.get("xml.scene")) {							
							enable_sound = true;
							return
						}
					});

					sound_scenes_int.forEach(element => {

						if (element == krpano.get("xml.scene")) {
							console.log("asgasg");							
							enable_sound_int = true;
							return
						}
					});

					if (enable_sound) {

						//////////console.log("mám spustiť zvuk : " + krpano.get("xml.scene"));
						
						if (settings.components[5].values[5].enabled == true) {
							krpano.call("sound[bgsnd].play()");
							krpano.set("sound[bgsnd].loop", true);
						}
						
					} else {
						//////////console.log("nemám spustiť zvuk : " + krpano.get("xml.scene"));
						krpano.call("sound[bgsnd].pause()");
					}


					if (enable_sound_int) {
						
						//////////console.log("mám spustiť zvuk : " + krpano.get("xml.scene"));
						
						if (settings.components[5].values[5].enabled == true) {
							krpano.call("sound[bgsnd_int].play()");
							krpano.set("sound[bgsnd_int].loop", true);
						}
						
					} else {
						//////////console.log("nemám spustiť zvuk : " + krpano.get("xml.scene"));
						krpano.call("sound[bgsnd_int].pause()");
					}
			
					if (get(vr_mode) == true) {
						prekopaj_infopanely_vr();			
					}

					else {
						prekopaj_infopanely_desktop();
					}
				}

				krpano.events.onloadcomplete = function() {

					if (
						loaded_times < 1
					)
					{
						
						krpano_full_loaded = true;
						loaded_krpano.update(n => !n);

						setTimeout(function() {
							preloader.update(n => !n);
							enable_sound = true;
							tour_select.update(n => true);


						}, 1500);
					}
					
					loaded_times++;

				};

				krpano.events.onunknowndevice = function () {
					device_type.update(n=> 555);
				}

				krpano.events.webvr_onexitvr = function() {
					vr_mode.update(n => false);
					prekopaj_infopanely_desktop();
				}
				
				krpano.events.webvr_onentervr = function() {
					
					vr_mode.update(n => true);
					prekopaj_infopanely_vr();
													
				};

				krpano.events.gyro_onenable = function() {
					gyro_mode.update(n => true);
				};

				krpano.events.gyro_ondisable = function() {
					gyro_mode.update(n => false);
				};

				krpano.events.webvr_onavailable = function() {

					if (
						krpano.device.mobilevr == true && krpano.webvr.isvrbrowser
					) 
					device_type.update(n => 'vr');

				};

				krpano.events.onviewchange = function() {
					if (get(vr_mode) == true) {
						move_menu_icon();
					}
				};

				// opravVR.subscribe((value) => {
				// 	prekopaj_infopanely_vr();
				// });
			}
		});
	}
  	
	// zapnutie / vypnutie modalu
	import { modal } from '../store.js';
	import { modal_type } from '../store.js';
	import { preloader } from '../store.js';
	import Sounds from '../tour_components/Sounds.svelte';
  	import VrTools from '../tour_components/Vr_tools.svelte';
	
	
  	modal.subscribe(value => {
		show_modal = value;
	});

	
  	preloader.subscribe(value => {
		show_preloader = value;
		if (
			document.getElementById("intro_div")
		) {
			document.getElementById("intro_div").remove();
		}
	});

  	let modal_type_tmp;

	modal_type.subscribe(value => {
		modal_type_tmp = value;
	});

  	let Intro, Modal, Preloader, EnterVR;

	onMount(async () => {
		Intro = (await import('../tour_components/Intro.svelte')).default
		Modal = (await import('../tour_components/Modal.svelte')).default
		Preloader = (await import('../tour_components/Preloader.svelte')).default
		EnterVR = (await import('../tour_components/Enter_VR.svelte')).default
	});

</script>

<svelte:head>
	<script src="{userdata['base_path']}assets/krpano/pano.js" on:load={initializeRemarkable}></script>
</svelte:head>

<div id="intro_div"></div>

<!-- inicializácia krpano tour -->
<div id="pano" style="width:100%;height:100%;" >
	<noscript><table style="width:100%;height:100%;"><tr style="vertical-align:middle;"><td><div style="text-align:center;">ERROR:<br/><br/>Javascript not activated<br/><br/></div></td></tr></table></noscript>
</div>

<!-- ak je načítanie JSON súboru kompletné  -->
{#if loading_data == false}
	<svelte:component this={Blur} {settings} {userdata}/>
	{#if Preloader && show_preloader}
		<svelte:component this={Preloader}/>
	{/if}

	{#if Intro}
		<svelte:component this={Intro}/>
	{/if}
	<!-- zobrazenie povolených komponentov -->
	{#each settings.components as {id, enabled}, index}
		{#if enabled == true }
			{#each components_array as data, i}
				 {#if data == id}
					 <svelte:component this={components[i]} {settings} {userdata}/>
				 {/if}
			{/each}
		{/if}
		
	{/each}

	{#if Modal && show_modal}
		{#if modal_type_tmp === 'google-map'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['maps']['title']}" settings="{settings}" url="{settings.components[7].url}" filter="{settings.components[7].filter}" position="" id="" current_scene="{current_scene}"/>
		{/if}
		{#if modal_type_tmp === 'tour-info'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{$t("components")['tourinfo']['title']}" settings="{settings}" url="" filter="" position="" id="" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'info-v1' || modal_type_tmp === 'info-v2' || modal_type_tmp === 'info-v3' }
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'video-v1'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}

		{#if modal_type_tmp === 'video-v2'}
			<svelte:component this={Modal} modal_type_tmp="{modal_type_tmp}" title="{hts_id}" settings="{settings}" url="" filter=""  position="" id="{hts_id}" current_scene="{current_scene}"/>
		{/if}
	{/if}

	<svelte:component this={EnterVR}/>
	<svelte:component this={VrTools} {settings} {userdata}/>
{/if}

<style lang="scss">
	  @import "../assets/scss/mixins.scss";

	#pano {
		position: absolute;
		top: 0;
		left: 0;
	}
	#intro_div {
		position: absolute;
		top: 50%;
		left: 50%;
    	transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		background: rgba(255, 254, 254, 0.5) 0% 0% no-repeat padding-box;
  		backdrop-filter: blur(40px);
  		-webkit-backdrop-filter: blur(40px);
  		-moz-filter: blur(40px);
  		z-index: 9;
	}
</style>