export default {
  sk: {
    "lang_name" : "Slovenský",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.floor" : "Poschodie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.settings.tiles" : "Názov CUBE obrázka",
    "scenes.settings.floor_enabled" : "Povoliť poschodia ?",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Späť",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "clear" : "Vymazať dáta",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      "import" : "Importovať",
      settings : {
        "title" : "Nastavenia",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound"  : "Zvuky",
        "color" : "Farba"
      },
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatická tour"
      },
      tour_settings : {
        "admin-title" : "Nastavenia Tour",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "admin-title" : "Zdieľať",
        "sidebar-title" : "Zdieľať"
      },
      maps : {
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Mapa",
        "url" : "Url link",
        "title" : "Google Mapa",
        "filter" : "Použiť filter ? (čiernobiela)"
      },
      tourinfo : {
        "admin-title" : "Tour info",
        "sidebar-title" : "Tour info",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázok",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použiť filter ? (čiernobiela)",
        "global" : "Globálne info",
        "all" : "Vyberte globálne info"
      },
      floorplan : {
        "admin-title" : "Floorplan",
        "sidebar-title" : "Pôdorys",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "načítavam komponenty",
        "loading_scenes" : "načítavam scény ...",
        "scenes_loaded" : "scény načítané",
        "loaded" : "načítané"
      },
      intro : {
        "title" : "Navigácia vo VR",
        "click-desktop" : "Kliknutím a pohybom sa rozhliadnite",
        "click-vr" : "Kliknutím a posunutím sa rozhliadnite",
        "scroll-desktop" : "Scrolujte pre priblíženie/oddialenie",
        "scroll-vr" : "Stlačením priblížte/oddiaľte",
        "continue" : "Pokračovať",
        "dont_show" : "viackrát nezobrazovať"
      },
      picker : {
        "tour_title" : "Späť na VR",
        "floorplan_title" : "Späť na výber poschodia",
        "level" : "poschodie",
        "room_count" : "počet izieb",
        "orientation" : "orientácia",
        "last_visited" : "naposledy navštívené",
        "reset" : "resetovať výber",
        "empty_value" : "nezvolené",
        "back" : "Späť na výber poschodia",
        "all_floors_title" : "Vybrať poschodie"
      },
      import : {
        "scenes_count" : "Počet scén",
        "default_name" : "Default názov scény",
        "cf10" : "Tag pre slider",
        "enable_floor" : "Povoliť poschodia ? ",
        "import_data" : "Importovať dáta"
      }

    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      },
      clear_data : {
        "admin_title" : "Vymazať dáta"
      },
      import_data : {
        "admin_title" : "Importovať dáta"
      }
    }
  },
  cz: {
    "lang_name" : "Slovenský",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    "back" : "Späť",
    "delete" : "Odstrániť pin",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      settings : {
        "title" : "Nastavenie",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Automatické otáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound"  : "Zvuky",
        "color" : "Barva"
      },
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatická prohlídka"
      },
      tour_settings : {
        "admin-title" : "Nastavenia Tour",
        "show_hotspots" : "Zobraziť hotspoty",
        "autorotate" : "Autootáčanie",
        "advertisement" : "Reklamy",
        "gyro" : "Gyroskop",
        "vr_mode" : "VR mód",
        "sound" : "Zvuky"
      },
      share : {
        "admin-title" : "Zdieľať",
        "sidebar-title" : "Sdílet"
      },
      maps : {
        "admin-title" : "Google Mapa",
        "sidebar-title" : "Google Mapa",
        "url" : "Url link",
        "title" : "Google Mapa",
        "filter" : "Použiť filter ? (čiernobiela)"
      },
      tourinfo : {
        "admin-title" : "Tour info",
        "sidebar-title" : "Tour info",
        "url" : "Url link",
        "title" : "Tour info",
        "headline" : "Headline",
        "image" : "Obrázok",
        "subtitle" : "Subtitle",
        "content"  : "Content",
        "filter" : "Použiť filter ? (čiernobiela)",
        "global" : "Globálne info",
        "all" : "Vyberte globálne info"
      },
      floorplan : {
        "admin-title" : "Floorplan",
        "sidebar-title" : "Půdorys",
        "floorplan-id" : "Zvoľ floorplan",
        "filter-by" : "Filtrovať podľa",
        "filter-by-value" : "Hodnota",
        "filter-none" : "Nefiltrovať",
        "pins-title" : "Názvy pinov",
        "upload-image" : "Obrázok",
        "import-file" : "Import pinov",
        "pin-x" : "pozícia X",
        "pin-y" : "pozícia Y",
        "pin-title" : "názov",
        "pin-target" : "Scéna",
        "add-floorplan" : "Pridať floorplan",
        "save-floorplan" : "Uloziť floorplan",
        "remove-floorplan" : "Ostrániť floorplan",
        "floorplan-title" : "Názov floorplanu",
        "add-pin" : "Pridať pin"
      },
      merge_scenes : {
        "admin-title" : "Spájanie scén",
        "base_path" : "Adresa priečinka so scénami",
        "base_suffix" : "Suffix názvu"
      },
      sounds : {
        "admin-title" : "Zvuky",
        "background_sound" : "Zvuk na pozadí",
        "enable_bg_sound" : "Zapnúť zvuk na pozadí",
        "sound" : "Zvuk",
        "play_sound" : "Prehrať zvuk",
        "remove_sound" : "Odstrániť zvuk"
      },
      preloader : {
        "loading_components" :  "načítám komponenty",
        "loading_scenes" : "načítám scény ...",
        "scenes_loaded" : "scény načtené"
      },
      intro : {
        "title" : "Navigace pro VR",
        "click-desktop" : "Kliknutím a pohybem se rozhlédněte",
        "click-vr" : "Kliknutím a tažením se rozhlédněte",
        "scroll-desktop" : "Scrolujte pro přiblížení/oddálení",
        "scroll-vr" : "Sevřením přiblížíte/oddálíte",
        "continue" : "Pokračovat",
        "dont_show" : "Nezobrazovat vícekrát"
      }

    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      }
    }
  },
  en: {
    "lang_name" : "English",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatic tour"
      }
    }
  },
  de: {
    "lang_name" : "Deutsch",
    "login" : "Prihlásenie",
    "login.page" : "sa prihláste",
    "username" : "prihlasovacie meno",
    "password" : "heslo",
    "password.check" : "zopakujte heslo",
    "or" : "alebo",
    "login.button" : "Prihlásiť",
    "visit.tour" : "prejsť do VR" ,
    "aside.dashboard" : "Nástenka",
    "aside.dashboard.description" : "prehľad nastavenia aplikácie",
    "aside.statistic" : "Štatistiky",
    "aside.show.tour" : "Zobraziť tour",
    "aside.scenes" : "Scény / panorámy",
    "aside.scenes.description" : "všetky scény",
    "aside.settings" : "Nastavenia",
    "aside.settings.description" : "globálne nastavenia prehliadky",
    "aside.meta" : "Meta tagy",
    "aside.scene" : "Prehľad scén",
    "aside.scene.add" : "Upraviť scénu",
    "aside.users" : "Používatelia",
    "aside.users.description" : "správa používateľov",
    "aside.user" : "Všetci používatelia",
    "aside.user.add" : "Pridať používateľa",
    "user.add.button" : "Vytvoriť užívateľa",
    "user.role" : "Rola",
    "user.name" : "Login",
    "user.first.name" : "Meno",
    "user.last.name" : "Priezvisko",
    "user.password" : "Heslo",
    "modify" : "Upraviť",
    "remove" : "Vymazať",
    "aside.components" : "Správa komponentov",
    "aside.components.description" : "zapnutie/vypnutie komponentov",
    "scenes.core.type.modify" : "Upravujete", 
    "scenes.core.type.settings" : "Nastavenia scény",
    "scenes.core.type.hotspots" : "Hotspoty",
    "scenes.core.type.patches" : "Patche/atribúty",
    "scenes.settings.name" : "Označenie",
    "scenes.settings.tags" : "Tagy",
    "scenes.settings.enabled" : "Zapnutá?",
    "scenes.settings.hlookat" : "Natočenie os X",
    "scenes.settings.vlookat" : "Natočenie os Y",
    "scenes.settings.fov" : "Zoom",
    "scenes.settings.title" : "Natočenie os Y",
    "scenes.settings.title" : "Názov",
    "scenes.settings.description" : "Popis",
    "scenes.settings.north" : "Sever",
    "scenes.settings.cf1" : "Vlastné pole #1",
    "scenes.settings.cf2" : "Vlastné pole #2",
    "scenes.settings.cf3" : "Vlastné pole #3",
    "scenes.settings.cf4" : "Vlastné pole #4",
    "scenes.settings.cf5" : "Vlastné pole #5",
    "scenes.settings.cf6" : "Vlastné pole #6",
    "scenes.settings.cf7" : "Vlastné pole #7",
    "scenes.settings.cf8" : "Vlastné pole #8",
    "scenes.settings.cf9" : "Vlastné pole #9",
    "scenes.settings.cf10" : "Vlastné pole #10",
    "scenes.settings.order" : "Poradie",
    "scenes.settings.thumbnail" : "Obrázok",
    "scenes.load.default.view" : "Načítať základný pohľad",
    "scenes.load.default.view.button" : "Načítať",
    "scenes.set.default.view" : "Nastaviť základný pohľad",
    "scenes.set.default.view.button" : "Nastaviť",
    "scenes.set.start.scene" : "Nastaviť ako úvodnú scénu",
    "scenes.set.start.scene.button" : "Nastaviť",
    "scenes.save.settings.label" : "Uloziť nastavenia",
    "scenes.save.settings.button" : "Uloziť",
    components: {
      "admin-title" : "Komponenty",
      "admin-side-title" : "Správa komponentov",
      "admin-title" : "Všetky komponenty",
      "admin-description" : "zapnutie/vypnutie komponentov",
      "save" : "Uloziť nastavenie",
      "logo-title" : "Logo",
      "logo-url" : "Odkaz",
      "logo-target" : "Otvoriť odkaz",
      "logo-target-blank" : "v novom tabe",
      "logo-target-self" : "v aktuálnom tabe",
      "logo-upload" : "Vloziť logo", 
      shortcuts : {
        "admin-title" : "Shortcuts",
        "scenes-short-description" : "Definícia scén",
        "scenes-description" : "Definícia scén, ktoré majú byť zobrazené v shortcuts",
        "scenes-all" : "Všetky povolené scény",
        "scenes-tag" : "Scény, ktoré majú presne rovnaké tagy ako aktívna scéna",
        "titles-short-description" : "Definícia názvov scén",
        "title-title" : "Názvy scén z poľa title",
        "title-number" : "Číslovanie scén podľa poľa order",
        "title-cf-1" : "Názvy scén podľa poľa custom field #1",
        "title-cf-2" : "Názvy scén podľa poľa custom field #2",
        "title-cf-3" : "Názvy scén podľa poľa custom field #3",
        "title-cf-4" : "Názvy scén podľa poľa custom field #4",
        "title-cf-5" : "Názvy scén podľa poľa custom field #5",
        "title-cf-6" : "Názvy scén podľa poľa custom field #6",
        "title-cf-7" : "Názvy scén podľa poľa custom field #7",
        "title-cf-8" : "Názvy scén podľa poľa custom field #8",
        "title-cf-9" : "Názvy scén podľa poľa custom field #9",
        "title-cf-10" : "Názvy scén podľa poľa custom field #10",
        "auto-tour" : "Automatická tour DE"
      }
    },
    admin_components : {
      settings : {
        title : "Nastavenia",
        save_button : "Uloziť nastavenia"
      }
    }
  },
};
  